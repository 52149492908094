import React from 'react'
import { useSurveyContext } from '../Context/SurveyCustomHooks/useSurveyContext';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export const SendSurveyBtn = () => {
    const {
        answers,
        setAnswers,
        surveyStyles,
        handleNext,
        saveAnswersMutation,
        updateUserMutation,
        sending,
        surveyInfo,
        userId,
        customer
    } = useSurveyContext();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        //Esto es para que se valide la ultima pregunta
        const lastValidation = handleNext();
        if (lastValidation === null) {
            return;
        }
        const values = {
            answers,
            from: "survey" //Esto no me acuerdo para que es
        }
        try {
            const saveAnswers = await saveAnswersMutation.mutateAsync(values);
            if (saveAnswers === 200) {
                const updateUser = {
                    status: 4
                }
                await updateUserMutation.mutateAsync(updateUser);
                setAnswers([]);
                navigate(
                    `/postsurvey/${customer}/${userId}`,
                    {
                        state: {
                            surveyInfo,
                            surveyStyles
                        }
                    }
                );
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <button
            type="submit"
            onClick={handleSubmit}
            disabled={sending}
            style={{
                backgroundColor: surveyStyles.btnSubmit.bg,
                color: surveyStyles.btnSubmit.color
            }}
            className="btn btn-lg shadow mysurveybtn"
        >
            {sending ?
                <FormattedMessage
                    id="sending.button"
                />
                :
                <FormattedMessage
                    id="send.button"
                />}
        </button>
    )
}

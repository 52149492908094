import React, { useEffect } from 'react'

export const WaitSurvey = ({ myTime, setMyTime, waitTime = 20 }) => {
    const compareTime = new Date().getTime()
    //get time from local storage
    const time = localStorage.getItem("time");
    //convert to seconds
    const seconds = (compareTime - time) / 1000;

    //setinterval to update the time every second
    useEffect(() => {
        const interval = setInterval(() => {
            if (myTime <= 0) {
                clearInterval(interval);
                return;
            } else {
                setMyTime(Math.floor(waitTime - seconds));
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [myTime, seconds, setMyTime, waitTime]);

    if (myTime === undefined && Math.floor(waitTime - seconds) > 0) {
        return (
            <h3>
                Por favor espere {Math.floor(waitTime - seconds)} segundos para volver a crear una encuesta
            </h3>
        )
    }
    else if (myTime > 0) {
        return (
            <h3>
                Por favor espere {myTime} segundos para volver a crear una encuesta
            </h3>
        )
    } else {
        return (
            <h3>
                Redirigiendo...
            </h3>
        )
    }

}

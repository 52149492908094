import React from 'react'
import { QuestionsMarksInfo } from './QuestionsMarksInfo'

export const TextAreaQuestion = ({ index, question, itemLabel, maxCharsTextArea }) => {
    return (
        <>
            <label className="form-label mb-3" htmlFor={`textArea${(index + 1)}${(question.id)}`}>
                {`${(index + 1)}. ${question[itemLabel]}`}
                <QuestionsMarksInfo required={question.required} type={question.question_type} />
            </label>
            <br />
            <textarea className="form-control"
                data-hj-allow
                maxLength={question?.max_length && question?.max_length > 0 && question?.max_length < 100 ? question.max_length : maxCharsTextArea}
                name={`textArea${(index + 1)}${(question.id)}`}
                id={`textArea${(index + 1)}${(question.id)}`}
                rows="3"
            />
        </>
    )
}

import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import React from 'react'
import Logout from '@mui/icons-material/Logout';
import useLogout from '../../CustomHooks/useLogout';
import useAuth from '../../CustomHooks/useAuth';
import { FormattedMessage } from 'react-intl';

export const MenuComponent = () => {
    // const navigate = useNavigate();
    const logout = useLogout();
    const { defaultCustomer, availableCustomers, setDefaultCustomer } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const signOut = async () => {
        handleClose();
        await logout();
    }

    return (
        <>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{ width: 32, height: 32 }} className='text-uppercase'>
                        {defaultCustomer.charAt(0)}
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    availableCustomers?.map((customer, index) => {
                        return (
                            <MenuItem
                                selected={customer === defaultCustomer}
                                key={index}
                                onClick={(e) => {
                                    setDefaultCustomer(customer);
                                }}
                                className='text-uppercase'>
                                <Avatar /> {customer}
                            </MenuItem>
                        )
                    })
                }
                {/* <MenuItem
                    onClick={(e) => {
                        handleClick(e);
                        navigate('/dashboard/settings');
                    }}
                    className='text-uppercase'>
                    <Avatar /> {UserInfo?.email.split("@")[0]}
                </MenuItem> */}
                {/* <MenuItem onClick={handleClose}>
                    <Avatar /> My account
                </MenuItem> */}
                <Divider />
                <MenuItem onClick={signOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <FormattedMessage
                        id="navbar.logout"
                        defaultMessage="Logout"
                    />
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Add another account
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem> */}
            </Menu>
        </>
    )
}

import { Footer } from "../ReuseComponents/Footer";
import { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { AnonymousSurvey } from "./UtilsComponents/AnonymousSurvey";
import surveyValidation from "../../Validations/surveyValidation";
import { DependentQuestions } from "./UtilsComponents/DependentsQuestions";
import template from "../../Domains/customers.json";
import { FormattedMessage } from "react-intl";
import MetaDecorator from "../UtilsComponents/MetaDecorator";
import { SelectLanguage } from "../ReuseComponents/SelectLanguage";
import { languageContext } from "../../context/languageContext";
import { Autocomplete, TextField } from "@mui/material";

export function Survey() {
    window.recaptchaOptions = {
        useRecaptchaNet: true,
    };
    const language = useContext(languageContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { id, customer } = useParams();
    const [timeStamp, setTimeStamp] = useState();

    const { survey, REACT_APP_SERVER, customerStyles } = location.state || {};
    const initialValues = {
        display_name: "",
        phone: "",
        email: "",
        survey_source: survey && survey.survey_source,
        identification_number: "",
        status: ""
    };
    const [checkCaptcha, setCheckCaptcha] = useState(false);
    const [ipCity, setIpCity] = useState(null);
    const [isAnonymousCheck, setIsAnonymousCheck] = useState(false);
    const [capToken, setCapToken] = useState(null);
    // const squareImgs = ["20MissionCerveza", "montespp", "society_voltage" ];
    const { url, logo, headerBackground, bodyBackground, fontColor, btnSubmit, title, subtitle, subtitleColor } = customerStyles ? customerStyles[customer] : template["7bytes"];
    document.body.style.backgroundColor = bodyBackground;
    const [fields, setFields] = useState(survey);
    const [formErrors, setFormErrors] = useState({});
    const [isSending, setIsSending] = useState(false);
    const [formValues, setFormValues] = useState(initialValues);
    const forOtherOptions = process.env.REACT_APP_FOR_OTHERS_OPTIONS.replace(/\s/g, "").replace("'", "").split(",");
    const [isMounted, setIsMounted] = useState(true);
    const maxCharsTextArea = 150;
    const maxCharsTextBox = 70;
    //on change of language redirect to the same page with the new language
    useEffect(() => {
        if (!isMounted) {
            navigate(`/mainsurvey/${customer}/${id}`);
        } else {
            setIsMounted(r => !isMounted)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const getIp = async () => {
        const date = new Date();
        const utcTime = new Date(date.toUTCString());
        utcTime.setMinutes(utcTime.getMinutes() + -5 * 60);
        const utcTimeString = utcTime.toISOString().slice(0, 19).replace('T', ' ');
        await fetch("https://geolocation-db.com/json/d802faa0-10bd-11ec-b2fe-47a0872c6708")
            .then(res => res.json())
            .then(async data => {
                const logIp = {
                    user_id: id,
                    log_detail: "opened",
                    log_date: utcTimeString,
                    country_name: data.country_name,
                    city: data.city,
                    postal: data.postal,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    ip: data.IPv4,
                    state: data.state
                };
                if (data.city != null && !data.city.toLowerCase().includes("not found")) {
                    setIpCity(data.city);
                }
                await saveLog(logIp);
            }).catch(async err => {
                const logIp = {
                    user_id: id,
                    log_detail: "opened",
                    log_date: utcTimeString,
                    country_name: "",
                    city: "",
                    postal: "",
                    latitude: "",
                    longitude: "",
                    ip: "",
                    state: ""
                };
                await saveLog(logIp);
                // console.error(err);
            });
    }
    useEffect(() => {
        survey && getIp();
    }, []);

    useEffect(() => {
    }, [formErrors]);

    const saveLog = async (logIp) => {
        await fetch(`${REACT_APP_SERVER}log?customer=${customer}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(logIp)
        })
            .then(res => res.json())
            .catch(err => {
                console.error(err);
            });
    }

    const changeTitle = () => {
        const capitalize = (string) => {
            let first = string.substring(0, 1).toUpperCase()
            if (string.includes("20Mission")) {
                return "20Mission Cerveza";
            }
            return first + string.substring(1).toLowerCase()
        }

        if (customer === document.title.toLowerCase()) {
            return document.title
        } else if (customer === 'hermeco') {
            return `Offcorss | 7Bytes`
        }
        return `${capitalize(customer)} | 7Bytes`
    }

    const beginningAndEnd = (options) => {
        let values = [];
        options.forEach((e) => {
            !isNaN(e) && values.push(parseInt(e))
        });
        return [Math.min(...values), Math.max(...values)]
    }

    const validate = (options) => {
        let maxAndMin = beginningAndEnd(options);
        return maxAndMin[0] === 1 && maxAndMin[maxAndMin.length - 1] === 4 && options.length === 4;
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleRadioTextChange = async (event) => {
        const { name, value } = event.target;
        const newName = name.substring(name.search(/\d/), name.length);
        //Search the letter in the name
        const middleIndex = newName.search(/\D/);

        let i = parseInt(newName.substring(0, middleIndex)); // La posicion de la pregunta desde 0 en adelante
        let j = parseInt(newName.substring(middleIndex + 1, newName.length)); // La posicion de la respuesta con respecto a la pregunta

        //add new value to the options
        let newOptions = [...fields.questions[i].values];
        newOptions[j + 1] = value;

        //update the values
        setFields({ ...fields, questions: [...fields.questions.slice(0, i), { ...fields.questions[i], values: newOptions }, ...fields.questions.slice(i + 1)] });
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        const index = name.search(/\d/);
        let i = parseInt(name.substring(index));
        fields.questions[i].value = value;
        setFields({ ...fields });
    };

    const handleRadioChange = (event) => {
        const { id, name } = event.target;
        //Get the position where is the number of the question
        const index = id.search(/\d/);
        const indexLength = name.length - index;
        let i = parseInt(id.substring(index, (indexLength + index))); // La posicion de la pregunta desde 0 en adelante
        let j = parseInt(id.substring(indexLength + index)); // La posicion de la respuesta con respecto a la pregunta
        //!Aqui puede haber un problema si la opcion de otros no es la ultima
        let indexOfTrue = fields.questions[i].values.lastIndexOf(true);
        let indexOfFalse = fields.questions[i].values.lastIndexOf(false);
        let maxIndex = Math.max(indexOfTrue, indexOfFalse);
        fields.questions[i].values.fill(false, 0, maxIndex + 1); // reset all values to false
        fields.questions[i].values[j] = true; // set the selected value to true
        setFields({ ...fields });

        if (forOtherOptions.includes(fields.questions[i].options[j].toLowerCase()) && fields.questions[i].values[j]) {
            let dropdownDiv = document.querySelector(`#IndepentRadio${i}${j}`);
            dropdownDiv.classList.remove("d-none")
            dropdownDiv.querySelector(`#option${i}${j}`).focus()
        } else {
            //Search the index of the option equal to otros
            const indexOtros = fields.questions[i].options.findIndex(e => forOtherOptions.includes(e.toLowerCase()));
            if (indexOtros !== -1) {
                let dropdownDiv = document.querySelector(`#IndepentRadio${i}${indexOtros}`);
                dropdownDiv.classList.add("d-none")
            }
        }
    };
    useEffect(() => {
        setTimeStamp(new Date().getTime());
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }
            if (isScriptExist && callback) callback();
        }
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_CAPTCHA_SITE_KEY_SURVEY}`, function () {
            // console.log("Script loaded!");
            window?.grecaptcha?.enterprise?.ready(function () {
                window?.grecaptcha?.enterprise?.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY_SURVEY, { action: `${customer}Survey` }).then(function (token) {
                    // console.log(token);
                    setCapToken(t => {
                        return token;
                    });
                });
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckChange = (event) => {
        const { id, checked, name } = event.target;
        const index = id.search(/\d/);
        const indexLength = name.length - index;
        let i = parseInt(id.substring(index, (indexLength + index)));
        let j = parseInt(id.substring(indexLength + index));
        fields.questions[i].values[j] = checked; // set the selected value to true
        setFields({ ...fields });
        if (forOtherOptions.includes(fields.questions[i].options[j].toLowerCase())) {
            //Se Cambio el nombre a divOpti porque tambien esta trayendo las dependientes y generaba error
            let dropdownDiv = document.querySelector(`#divOpti${i}${j}`)
            if (checked) {
                dropdownDiv.classList.remove("d-none")
                dropdownDiv.querySelector(`#checkOpt${i}${j}`).focus()
            } else {
                dropdownDiv.classList.add("d-none")
            }
        }
    };
    const handleAnonymousCheckChange = (event) => {
        const { checked } = event.target;
        setIsAnonymousCheck(checked);
    };

    const handleRefreshCaptcha = (event) => {
        setCheckCaptcha(!checkCaptcha);
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }
            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_CAPTCHA_SITE_KEY_SURVEY}`, function () {
            // console.log(" ACA");
            window?.grecaptcha?.enterprise?.ready(function () {
                window?.grecaptcha?.enterprise.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY_SURVEY, { action: `${customer}Survey` }).then(function (token) {
                    // console.log(token);
                    setCapToken(t => {
                        return token;
                    });
                });
            });
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        //#region checkbox independientes
        //Esta parte solo afecta a las preguntas de tipo checkbox que son independientes
        let checkboxs = [];
        let dropdownDivs = [];
        let answersForOtros = [];
        document.querySelectorAll('input[type=checkbox]').forEach(checkbox => {
            checkbox.value === "Otros" | checkbox.value === "Otro" | checkbox.value === "Otr@s" &&
                // console.log(checkbox.id.search(/\d/), checkbox.id)
                checkboxs.push(checkbox.id.substring(checkbox.id.search(/\d/)))
        });
        document.querySelectorAll('div').forEach(div => {
            div.hasAttribute("id") & div.id.includes("divOpti") && dropdownDivs.push(div)
        });
        checkboxs.forEach(checkboxIndex => dropdownDivs.forEach(div => {
            // console.log( div["id"].search(/\d/))
            checkboxIndex === div.id.substring(div["id"].search(/\d/)) &&
                answersForOtros.push({
                    "indices": checkboxIndex,
                    "value": div.querySelector(`#checkOpt${checkboxIndex}`).value
                })
        }));
        let quesNumber = fields.questions.length;
        const indexValue = quesNumber < 10 ? quesNumber.toString().length : quesNumber.toString().length - 1;
        for (let i = 0; i < answersForOtros.length; i++) {
            // console.log(answersForOtros[i].indices.substring(0, 1),quesNumber.length)
            let e = parseInt(answersForOtros[i].indices.substring(0, indexValue));
            let j = parseInt(answersForOtros[i].indices.substring(indexValue));
            //Validates if check button "Otros" is selected.
            if (fields.questions[e].values[j]) {
                let finalValues = answersForOtros[i].value.split(",")
                for (let x = 0; x < finalValues.length; x++) {
                    finalValues[x] = finalValues[x].trim();
                    fields.questions[e].values[j] = finalValues
                }
            } else {
                fields.questions[e].values[j] = false;
            }
        }
        //#endregion

        const [formErrors, answers] = await surveyValidation(fields, formValues);
        setFormErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            const newTimeStamp = new Date().getTime();
            if (newTimeStamp - timeStamp < ((2 * 60 * 1000) - 2000) || checkCaptcha) {
                setIsSending(true);
                fetch(`${REACT_APP_SERVER}answer?customer=${customer}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        answers,
                        token: capToken,
                        from: "survey"
                    })
                }).then(async (res) => {
                    // const data = await res.json();
                    // if (data?.text === "Captcha no validado") {
                    //     setIsSending(false);
                    //     return setFormErrors({
                    //         captcha: <FormattedMessage id="error.captcha.invalid" />
                    //     });
                    // }
                    if (res.status === 409) {
                        setIsSending(false);
                        setFormErrors({
                            server: "Ya has respondido esta encuesta, gracias por tu participación."
                        });
                        //await 3 seconds
                        await new Promise(r => setTimeout(r, 3000));
                        return navigate(`/mainsurvey/${customer}/${fields.userId}`);
                    }
                    if ((isAnonymousCheck || fields.requiredPersonalInfo) && (formValues.display_name !== "" || formValues.phone !== "" || formValues.email !== "" || formValues.identification_number !== "")) {
                        formValues.status = 4;
                        formValues.display_name = formValues.display_name === "" ? null : formValues.display_name;
                        formValues.phone = formValues.phone === "" ? null : formValues.phone;
                        formValues.email = formValues.email === "" ? null : formValues.email;
                        formValues.identification_number = formValues.identification_number === "" ? null : formValues.identification_number;
                        setFormValues(formValues);
                        fetch(`${REACT_APP_SERVER}users/${fields.userId}?customer=${customer}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(formValues)
                        }).then(async () => {
                            //refresh page
                            navigate(`/mainsurvey/${customer}/${fields.userId}`);
                        }).catch(err => {
                            setFormErrors({
                                server: <FormattedMessage id="settings.server.error.msg" />
                            });
                            console.error(err);
                            Sentry.captureMessage("Error al guardar el usuario");
                            // Sentry.captureException(err);
                        });
                    } else {
                        var updateUser;
                        if (fields.store === null) {
                            updateUser = {
                                status: 4,
                                city: ipCity
                            }
                        } else {
                            updateUser = {
                                status: 4
                            }
                        }
                        fetch(`${REACT_APP_SERVER}users/${fields.userId}?customer=${customer}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(updateUser)
                        }).then(async () => {
                            //refresh page
                            navigate(`/mainsurvey/${customer}/${fields.userId}`);
                        }).catch(err => {
                            console.error(err);
                            setFormErrors({
                                server: <FormattedMessage id="settings.server.error.msg" />
                            });
                            Sentry.captureMessage("Error al guardar el usuario");
                            // Sentry.captureException(err);
                        })
                    }
                }).catch(err => {
                    console.error(err);
                    setFormErrors({
                        server: <FormattedMessage id="settings.server.error.msg" />
                    });
                    Sentry.captureMessage("Error al enviar las respuestas");
                    // Sentry.captureException(err);
                }).finally(() => {
                    setIsSending(false);
                })
            } else {
                setFormErrors({
                    captcha: <FormattedMessage id="error.captcha.invalid" />
                });
                // Sentry.captureMessage("Error en el captcha: No se pudo obtener el token");
                // setFormErrors({
                //     captcha: <FormattedMessage
                //         id="error.captcha"
                //         defaultMessage={`Error en el captcha`}
                //     />
                // });
            }
        }
    }

    return (
        fields ?
            <table border="0" cellPadding="0" cellSpacing="0" width="100%">
                <MetaDecorator
                    title={changeTitle()}
                    description={"Para nosotros es muy importante conocer tu opinión sobre nuestros productos y servicios. Por favor, ayúdanos a mejorar respondiendo esta encuesta."}
                    imageUrl={"/images/7Bytes.png"}
                    imageAlt={"7Bytes"}
                    path={window.location.pathname}
                />
                <tbody>
                    <tr>
                        <td bgcolor={headerBackground} align="center">
                            <table className="tableWith">
                                <tbody>
                                    <tr>
                                        <td className="td1" align="center" valign="top">
                                            <a target="_blank" rel="noreferrer" href={url}>
                                                <img className="img1" alt="Logo" src={logo} border="0" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="td2" bgcolor={headerBackground}  >
                            <table className="tableWith" align="center">
                                <tbody>
                                    <tr>
                                        <td className="tdTitle" align="center">
                                            {survey?.multiLang && <SelectLanguage />}
                                            <h1 className={title?.length < 25 ? "Title" : "reduceTitle"} style={{ display: fields && fields.status === "4" ? "none" : "", color: fontColor }}>{title} {fields && fields.name === null ? isAnonymousCheck && formValues.display_name : fields.name}</h1>
                                            <h1 className={title?.length < 25 ? "Title" : "reduceTitle"} style={{ display: fields && fields.status !== "4" ? "none" : "", color: fontColor }}> {fields && fields.name}</h1>
                                            {subtitle && <h5 className="reduceTitle mt-3" style={{ color: subtitleColor ?? "gray" }}>{subtitle}</h5>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="tdBody" style={{ backgroundColor: bodyBackground }} align="center" >
                            <table className="tableWith mb-5 shadow2">
                                <tbody className="tdBody">
                                    {fields && (fields.status === "2" || fields.status === "3") ?
                                        (
                                            <tr>
                                                <td className="tdBody2" align="left">
                                                    <form id={fields.store ? `Survey-${customer}-${fields.store}` : `Survey-${customer}`} onSubmit={handleSubmit}>
                                                        {fields.questions.map((item, index) => (
                                                            <div key={"Main" + item.itemId}>
                                                                {item.itemType === "Radio" && (<>
                                                                    <div className="row mt-4" key={`radioRow${item.itemId}`}>
                                                                        <div className="col-sm-12" key={`radioCol${item.itemId}`}>
                                                                            <div className="card shadow" key={`radioCard${item.itemId}`}>
                                                                                <div className="card-body" key={`radioBody${item.itemId}`}>
                                                                                    <div className="form-group" key={`radioGroup${item.itemId}`}>
                                                                                        <label className="form-label mb-3" htmlFor="exampleRadioButton">{item.textLabel}{item?.required && <b style={{ color: "#e87b8f" }}> *</b>}</label>
                                                                                        {fields.numericHand && (item.emoji_pack === "" && !isNaN(parseInt(beginningAndEnd(item.options)[1])) && !isNaN(parseInt(beginningAndEnd(item.options)[0])) && !validate(item.options)) &&
                                                                                            // Anter estaba el id del small id={`NumericOptions${beginningAndEnd(item.options)[0]}${beginningAndEnd(item.options)[1]}`}
                                                                                            <small style={{ visibility: "" }} >
                                                                                                {/* <li style={{ listStyle: "none", color: "gray" }}>
                                                                                                    <cite>
                                                                                                        <FormattedMessage
                                                                                                            id="survey.msg.forThumbs"
                                                                                                            defaultMessage="Siendo"
                                                                                                        />
                                                                                                    </cite>
                                                                                                </li> */}
                                                                                                <br />
                                                                                                {
                                                                                                    (fields.numberOrder === "desc" || fields.numberOrder === null) ?
                                                                                                        <>
                                                                                                            <li className="mb-3" style={{ listStyle: "none", display: "inline-flex", color: "gray" }}>
                                                                                                                <cite>
                                                                                                                    {beginningAndEnd(item.options)[1]} {" "}
                                                                                                                    <FormattedMessage
                                                                                                                        id="survey.msg.for.thumb.up"
                                                                                                                        defaultMessage="Siendo bueno"
                                                                                                                    /> {" "}
                                                                                                                    <i className="fa fa-thumbs-up" style={{ color: "gray" }} aria-hidden="true" />&nbsp;-&nbsp;
                                                                                                                </cite>
                                                                                                            </li>
                                                                                                            <li className="mb-3" style={{ listStyle: "none", display: "inline-flex", color: "gray" }}>
                                                                                                                <cite>
                                                                                                                    {beginningAndEnd(item.options)[0]}{" "}
                                                                                                                    <FormattedMessage
                                                                                                                        id="survey.msg.for.thumb.down"
                                                                                                                        defaultMessage="Siendo malo"
                                                                                                                    /> {" "}
                                                                                                                    <i className="fa fa-thumbs-down" style={{ color: "gray" }} aria-hidden="true" />{" "}
                                                                                                                </cite>
                                                                                                            </li>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <li className="mb-3" style={{ listStyle: "none", display: "inline-flex", color: "gray" }}>
                                                                                                                <cite>
                                                                                                                    {beginningAndEnd(item.options)[0]}&nbsp;
                                                                                                                    <FormattedMessage
                                                                                                                        id="survey.msg.for.thumb.down"
                                                                                                                        defaultMessage="Siendo malo"
                                                                                                                    /> &nbsp;
                                                                                                                    <i className="fa fa-thumbs-down" style={{ color: "gray" }} aria-hidden="true" />&nbsp;-&nbsp;
                                                                                                                </cite>
                                                                                                            </li>
                                                                                                            <li className="mb-3" style={{ listStyle: "none", display: "inline-flex", color: "gray" }}>
                                                                                                                <cite>
                                                                                                                    {beginningAndEnd(item.options)[1]}&nbsp;
                                                                                                                    <FormattedMessage
                                                                                                                        id="survey.msg.for.thumb.up"
                                                                                                                        defaultMessage="Siendo bueno"
                                                                                                                    /> &nbsp;
                                                                                                                    <i className="fa fa-thumbs-up" style={{ color: "gray" }} aria-hidden="true" />&nbsp;
                                                                                                                </cite>
                                                                                                            </li>
                                                                                                        </>
                                                                                                }
                                                                                                <li style={{ listStyle: "none", display: "inline-flex" }}>
                                                                                                    <cite>
                                                                                                        {(item.options.includes("N/A") || item.options.includes("N/A.")) && <small>- <FormattedMessage id="survey.msg.forN/A" defaultMessage="N/A si no tienes información." /></small>}
                                                                                                    </cite>
                                                                                                </li>
                                                                                            </small>
                                                                                        }
                                                                                        <div className="" key={`radioContainer${item.itemId}`}>
                                                                                            <div className="col text-center" key={`radioCenter${item.itemId}`}>
                                                                                                {item.options.map((option, optionIndex) => (
                                                                                                    //! Por culpa de este fragmento aparece el warning del key
                                                                                                    <>
                                                                                                        <div className="form-check-inline text-center align-top" key={`radio${index}${optionIndex}`}>
                                                                                                            <input className="btn-check" type="radio" checked={item.values[optionIndex]} name={`option${index}`} onChange={handleRadioChange} id={`option${index}${optionIndex}`} value={option} />
                                                                                                            {item.emoji_pack !== "" ?
                                                                                                                <>
                                                                                                                    {!isNaN(option) && (parseInt(option) <= 5 && parseInt(option) >= 1) ?
                                                                                                                        <label className="btn btn-emoji" style={{ padding: "0px" }} htmlFor={`option${index}${optionIndex}`} id={`Labeloption${index}${optionIndex}`}>  <img alt={option} className="emoji" src={`https://storage.googleapis.com/server-7bytes.appspot.com/Emoji%20Packs/Pack%20${item.emoji_pack.match(/\d+/)[0]}/Pack%20${item.emoji_pack.match(/\d+/)[0]}.${option}.png`}></img></label>
                                                                                                                        :
                                                                                                                        <label className="btn btn-outline-dark mt-1" htmlFor={`option${index}${optionIndex}`} id={`Labeloption${index}${optionIndex}`}>{option}</label>
                                                                                                                    }
                                                                                                                </>
                                                                                                                :
                                                                                                                <label className="btn btn-outline-dark mt-1" htmlFor={`option${index}${optionIndex}`} id={`Labeloption${index}${optionIndex}`}>  {option}</label>
                                                                                                            }
                                                                                                            {item.options !== undefined && !validate(item.options) && fields.numericHand && item.emoji_pack === "" &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        beginningAndEnd(item.options)[0] === parseInt(option) ?
                                                                                                                            <div id={`thumbsDown${beginningAndEnd(item.options)[0]}${beginningAndEnd(item.options)[1]}`} className="mt-1">
                                                                                                                                <i className="fa fa-thumbs-down fa-2x" style={{ color: btnSubmit }} aria-hidden="true" />
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            beginningAndEnd(item.options)[1] === parseInt(option) &&
                                                                                                                            <div id={`thumbsUp${beginningAndEnd(item.options)[1]}`} className="mt-1">
                                                                                                                                <i className="fa fa-thumbs-up fa-2x" style={{ color: btnSubmit }} aria-hidden="true" />
                                                                                                                            </div>
                                                                                                                    }
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                        {forOtherOptions.includes(option.toLowerCase()) &&
                                                                                                            <div className="form-group mt-2 small d-none" style={{ display: "inline-block" }} id={`IndepentRadio${index}${optionIndex}`}>
                                                                                                                <label className="form-label small mb-1" style={{ float: "left" }} htmlFor={`option${index}`}>
                                                                                                                    <FormattedMessage
                                                                                                                        id="survey.msg.radioForOthersField"
                                                                                                                        defaultMessage="Por favor especifique su respuesta en el siguiente campo de texto."
                                                                                                                    />
                                                                                                                </label>
                                                                                                                <input type="text" className="form-control" maxLength="50" data-hj-allow name={`option${index}q${optionIndex}`} id={`option${index}${optionIndex}`} onChange={handleRadioTextChange} placeholder="" autoComplete="off" />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                        <p className="mt-2" style={{ color: "#FF0000" }}>{formErrors[item.itemId]}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {item.depentId.length > 0 && (item.depentId.some((e) => e.optionvalue === item.options[item.values.indexOf(true)])) && (
                                                                        <DependentQuestions key={`first${item.options[item.values.indexOf(true)]}`} fields={fields} depentQues={item.depentId} openItem={item.itemId} activationValue={item.options[item.values.indexOf(true)]} />
                                                                    )}
                                                                </>
                                                                )}
                                                                {item.itemType === "Check" && (
                                                                    <div className="row mt-4" key={`checkRow${item.itemId}`}>
                                                                        <div className="col-sm-12" key={`checkCol${item.itemId}`}>
                                                                            <div className="card shadow" key={`checkCard${item.itemId}`}>
                                                                                <div className="card-body" key={`checkBody${item.itemId}`}>
                                                                                    <div className="form-group" key={`checkGroup${item.itemId}`}>
                                                                                        <label className="form-label mb-3" htmlFor="exampleCheckButton">{item.textLabel}{item?.required && <b style={{ color: "#e87b8f" }}> *</b>}</label>
                                                                                        <br />
                                                                                        {item.options.map((option, optionIndex) => (
                                                                                            !forOtherOptions.includes(option.toLowerCase()) ?
                                                                                                <div className="form-check form-check-inline" key={`check${index}${optionIndex}`}>
                                                                                                    <input className="form-check-input" type="checkbox" checked={item.values[optionIndex]} name={`option${index}`} onChange={handleCheckChange} id={`option${index}${optionIndex}`} value={option} />
                                                                                                    <label className="form-check-label" htmlFor={`option${index}${optionIndex}`}>{option}</label>
                                                                                                </div>
                                                                                                : <div key={`divcheck${index}${optionIndex}`} style={{ display: "inline" }}>
                                                                                                    <div className="form-check form-check-inline" key={`check${index}${optionIndex}`}>
                                                                                                        <input className="form-check-input" type="checkbox" checked={item.values[optionIndex]} name={`option${index}`} onChange={handleCheckChange} id={`option${index}${optionIndex}`} value={option} />
                                                                                                        <label className="form-check-label" htmlFor={`option${index}${optionIndex}`}>{option}</label>
                                                                                                    </div>
                                                                                                    <div className="form-group mt-1 small d-none" name="hola" id={`divOpti${index}${optionIndex}`}>
                                                                                                        <label className="form-label small mb-1" htmlFor={`checkOpt${index}`}>
                                                                                                            <FormattedMessage
                                                                                                                id="survey.msg.checkForOthersField"
                                                                                                                defaultMessage="Por favor especifique (si son multiples valores debe separar cada uno por coma)"
                                                                                                            />
                                                                                                        </label>
                                                                                                        <input type="text" className="form-control" data-hj-allow name={`checkOpt${index}`} id={`checkOpt${index}${optionIndex}`} placeholder="Ejemplo: amigos/amigas, ahijado/ahijada, etc." autoComplete="off" />
                                                                                                    </div>
                                                                                                </div>
                                                                                        ))}
                                                                                        <p className="mt-2" style={{ color: "#FF0000" }}>{formErrors[item.itemId]}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.itemType === "Select Radio" && (
                                                                    <div className="row mt-4" key={`checkRow${item.itemId}`}>
                                                                        <div className="col-sm-12" key={`checkCol${item.itemId}`}>
                                                                            <div className="card shadow" key={`checkCard${item.itemId}`}>
                                                                                <div className="card-body" key={`checkBody${item.itemId}`}>
                                                                                    <div className="form-group" key={`checkGroup${item.itemId}`}>
                                                                                        <label className="form-label mb-3" htmlFor={`selectradio${(index + 1)}q${(item.itemId)}`}>{item.textLabel} {item?.required && <b style={{ color: "#e87b8f" }}> *</b>}</label>
                                                                                        <br />
                                                                                        <Autocomplete
                                                                                            disablePortal
                                                                                            id={`selectradio${(index + 1)}q${(item.itemId)}`}
                                                                                            options={item.options}
                                                                                            sx={{ width: 300 }}
                                                                                            onChange={
                                                                                                (event, newValue) => {
                                                                                                    fields.questions[index].values = newValue // set the selected value to true
                                                                                                    setFields({ ...fields });
                                                                                                }
                                                                                            }
                                                                                            renderInput={(params) => <TextField  {...params} label="Seleccione una opcion" />}
                                                                                        />
                                                                                        <p className="mt-2" style={{ color: "#FF0000" }}>{formErrors[item.itemId]}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.itemType === "Select Check" && (
                                                                    <div className="row mt-4" key={`checkRow${item.itemId}`}>
                                                                        <div className="col-sm-12" key={`checkCol${item.itemId}`}>
                                                                            <div className="card shadow" key={`checkCard${item.itemId}`}>
                                                                                <div className="card-body" key={`checkBody${item.itemId}`}>
                                                                                    <div className="form-group" key={`checkGroup${item.itemId}`}>
                                                                                        <label className="form-label mb-3" htmlFor={`selectcheck${(index + 1)}q${(item.itemId)}`}>{item.textLabel} {item?.required && <b style={{ color: "#e87b8f" }}> *</b>}</label>
                                                                                        <br />
                                                                                        <Autocomplete
                                                                                            className='mt-2'
                                                                                            multiple
                                                                                            limitTags={2}
                                                                                            onChange={
                                                                                                (event, newValue) => {
                                                                                                    fields.questions[index].values = newValue
                                                                                                    setFields({ ...fields });
                                                                                                }
                                                                                            }
                                                                                            options={item.options}
                                                                                            getOptionLabel={opcion => opcion}
                                                                                            id={`selectcheck${(index + 1)}q${(item.itemId)}`}
                                                                                            // defaultValue={}
                                                                                            filterSelectedOptions
                                                                                            renderInput={(params) => (
                                                                                                <TextField
                                                                                                    {...params}
                                                                                                    label=""
                                                                                                    id={`selectcheck${(index + 1)}q${(item.itemId)}`}
                                                                                                    placeholder="Seleccione una o varias opciones"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <p className="mt-2" style={{ color: "#FF0000" }}>{formErrors[item.itemId]}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.itemType === "Text Box" && (
                                                                    <div className="row mt-4" key={item.itemId}>
                                                                        <div className="col-sm-12">
                                                                            <div className="card shadow">
                                                                                <div className="card-body">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label mb-3" htmlFor={`text${index}`}>{item.textLabel.replace("@display_name", fields.name)} {item?.required && <b style={{ color: "#e87b8f" }}> *</b>}</label>
                                                                                        <br />
                                                                                        <input className="form-control"
                                                                                            style={{ width: (item?.valueFormat === "number" || item?.valueFormat === "date") ? "250px" : "" }}
                                                                                            data-hj-allow
                                                                                            type={item?.valueFormat ? item?.valueFormat : `text`}
                                                                                            maxLength={item?.maxLength && item?.maxLength > 0 && item?.maxLength < maxCharsTextBox ? item?.maxLength : maxCharsTextBox}
                                                                                            value={item.value}
                                                                                            min={item?.minNumber ? item?.minNumber : ""}
                                                                                            max={item?.maxNumber ? item?.maxNumber : ""}
                                                                                            name={`text${index}`}
                                                                                            onChange={handleTextChange}
                                                                                            id={`text${index}`}
                                                                                        />
                                                                                        <p className="mt-2" style={{ color: "#FF0000" }}>{formErrors[item.itemId]}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.itemType === "Text Area" && (
                                                                    <div className="row mt-4" key={item.itemId}>
                                                                        <div className="col-sm-12 ">
                                                                            <div className="card shadow">
                                                                                <div className="card-body">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label mb-3" htmlFor={`text${index}`}>{item.textLabel} {item?.required && <b style={{ color: "#e87b8f" }}> *</b>}</label>
                                                                                        <br />
                                                                                        <textarea className="form-control"
                                                                                            data-hj-allow
                                                                                            value={item.value}
                                                                                            name={`text${index}`}
                                                                                            onChange={handleTextChange}
                                                                                            id={`text${index}`}
                                                                                            maxLength={item?.maxLength && item?.maxLength > 0 && item?.maxLength < maxCharsTextArea ? item?.maxLength : maxCharsTextArea}
                                                                                            rows="3"
                                                                                        />
                                                                                        <p className="mt-2" style={{ color: "#FF0000" }}>{formErrors[item.itemId]}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                        {
                                                            (fields.requiredPersonalInfo && fields?.name === null) ?
                                                                <AnonymousSurvey initialValues={initialValues} required={fields.requiredPersonalInfo} formErrors={formErrors} formValues={formValues} handleChange={handleChange} />
                                                                :
                                                                fields.name === null && fields.personalInfo &&
                                                                <>
                                                                    <div className="form-check mt-4" key={`checkAnonymousSurvey`}>
                                                                        <input className="form-check-input" type="checkbox" checked={isAnonymousCheck} name={`checkAnonymousSurvey`} onChange={handleAnonymousCheckChange} id={`checkAnonymousSurvey`} value={isAnonymousCheck} />
                                                                        <label className="form-check-label" htmlFor={`checkAnonymousSurvey`}>
                                                                            <FormattedMessage
                                                                                id="survey.anonymousCheckbox"
                                                                                defaultMessage="¿Le gustaría compartir sus datos de contacto?"
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                    {isAnonymousCheck && <AnonymousSurvey initialValues={initialValues} formValues={formValues} handleChange={handleChange} />}
                                                                </>
                                                        }
                                                        {Object.keys(formErrors).length > 0 ?
                                                            <div className="row mt-3">
                                                                <div className="d-flex justify-content-center">
                                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                                                        {!formErrors?.captcha && !formErrors?.server &&
                                                                            <label style={{ color: "#FF0000", textAlign: "center" }}>
                                                                                <FormattedMessage
                                                                                    id="survey.numberOfErrors"
                                                                                    defaultMessage="Número de errores:"
                                                                                /> {Object.keys(formErrors).length}
                                                                                <p style={{ color: "#FF0000", textAlign: "center" }}>
                                                                                    <strong>
                                                                                        <FormattedMessage
                                                                                            id="survey.check.errors"
                                                                                        />
                                                                                    </strong>
                                                                                </p>
                                                                            </label>
                                                                        }
                                                                        {formErrors?.captcha &&
                                                                            <>
                                                                                <p style={{ color: "#FF6600", textAlign: "center", margin: "0px" }}>
                                                                                    <strong>
                                                                                        {formErrors.captcha}
                                                                                    </strong>
                                                                                </p>
                                                                                {/* <p style={{ color: "#FF0000", textAlign: "center", margin: "0px" }}>
                                                                                    <FormattedMessage
                                                                                        id="error.captcha.info"
                                                                                    />
                                                                                </p> */}
                                                                                <div className="form-check mt-2 container text-center" key={`checkCaptcha`}>
                                                                                    <input className="form-check-input me-2" style={{ float: "initial" }} type="checkbox" name={`checkCaptcha`} onChange={handleRefreshCaptcha} checked={checkCaptcha} id={`checkCaptcha`} />
                                                                                    <label className="form-check-label" htmlFor={`checkCaptcha`}>
                                                                                        <strong>
                                                                                            <FormattedMessage
                                                                                                id="captcha.msg"
                                                                                            />
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {
                                                                            formErrors?.server &&
                                                                            <p style={{ color: "#FF0000", textAlign: "center", margin: "0px" }}>
                                                                                <strong>
                                                                                    {formErrors.server}
                                                                                </strong>
                                                                            </p>
                                                                        }
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            : <div></div>}
                                                        <div className="row mt-3">
                                                            <div className="col text-center">
                                                                {!isSending && <button id={`submit-${customer}`} type="submit" style={{ backgroundColor: btnSubmit.bg }} className="btn btn-lg shadow"> <strong style={{ color: btnSubmit.color }}><FormattedMessage id="send.button" defaultMessage="Enviar" /></strong> </button>}
                                                                {isSending && <button type="submit" style={{ backgroundColor: btnSubmit.bg }} disabled className="btn btn-lg shadow"><strong style={{ color: btnSubmit.color }}><FormattedMessage id="sending.button" defaultMessage="Enviando" /></strong> </button>}
                                                            </div>
                                                        </div>
                                                    </form>
                                                </td>
                                            </tr>
                                        ) : <></>
                                    }
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <Footer bodyBackground={bodyBackground} fontColor={fontColor} />
                    </tr>
                </tbody>
            </table > : window.location.href = `/mainsurvey/${customer}/${id}`
    );
}

import React, { useContext } from 'react'
import { useSurveyContext } from '../../Context/SurveyCustomHooks/useSurveyContext';
import { FormattedMessage } from 'react-intl';
import { languageContext } from '../../../../../context/languageContext';
import { useSurveyQuestions } from '../../Context/SurveyCustomHooks/useSurveyQuestions';

export const CheckQuestion = () => {
    const {
        activeStep,
        surveyQuestions,
        updateAnswers,
        answers,
        setAnswers,
        getLanguageOptions
    } = useSurveyContext();
    const language = useContext(languageContext);
    const question = surveyQuestions[activeStep];
    const options = getLanguageOptions(question, language.locale);

    const handleCheckChange = (e) => {
        const { value: optionValue, checked } = e.target;
        if (checked) {
            return updateAnswers(question.itemId, optionValue, "multiple");
        } else {
            //delete the value from the answers
            let answer = answers.find(answer => answer.answer_value === optionValue);
            if (answer) {
                //delete just the value
                setAnswers(answers.filter(answer => answer.answer_value !== optionValue));
            }
        }
    }

    return (
        options.length > 0 ?
            options.map((option, questionIndex) => {
                return (
                    <div className="form-check form-check-inline" key={`check${activeStep}${questionIndex}`}>
                        <input
                            className="form-check-input"
                            style={{ fontSize: "1.1rem" }}
                            type="checkbox"
                            id={`check${activeStep}${questionIndex}`}
                            onChange={handleCheckChange}
                            //Solo Compara los valores de las opciones en español y las guarda
                            value={question.options[questionIndex]}
                            checked={answers.find(answer => answer.answer_value === question.options[questionIndex]) ? true : false}
                        />
                        <label
                            className="form-check-label RegularMadeTommy"
                            htmlFor={`check${activeStep}${questionIndex}`}
                            style={{ fontSize: "1.2rem" }}
                        >
                            {option ? option : question.options[questionIndex]}
                        </label>
                    </div>
                )
            })
            :
            <h6 style={{ color: "red" }}>
                <FormattedMessage id="survey.view.answers.error" />
            </h6>
    )
}

import React, { useContext } from 'react'
import { useSurveyContext } from '../../Context/SurveyCustomHooks/useSurveyContext';
import { languageContext } from '../../../../../context/languageContext';

export const RadioQuestion = () => {
    const language = useContext(languageContext);
    const {
        activeStep,
        surveyQuestions,
        answers,
        updateAnswers,
        getLanguageOptions
    } = useSurveyContext();
    const question = surveyQuestions[activeStep];
    const acceptedEmojiAnswers = ["1", "2", "3", "4", "5"];
    const emojiPack = question?.emoji_pack;
    const options = getLanguageOptions(question, language.locale);

    const handleRadioChange = (e) => {
        //TODO: Hacer la opcion de otros
        updateAnswers(question.itemId, e.target.value);
    }

    return (
        options.length > 0 ?
            options.map((option, questionIndex) => {
                return (
                    <div className="form-check-inline text-center align-top" key={`radio${activeStep}${questionIndex}`}>
                        <input
                            className="btn-check RegularMadeTommy"
                            style={{ backgroundColor: "red" }}
                            type="radio"
                            name={`radio${activeStep}`}
                            id={`radio${activeStep}${questionIndex}`}
                            //Solo Compara los valores de las opciones en español y las guarda
                            value={question.options[questionIndex]}
                            checked={answers.find(answer => answer.survey_item_id === question.itemId)?.answer_value === question.options[questionIndex]}
                            onChange={handleRadioChange}
                        />
                        {!(emojiPack === '') && acceptedEmojiAnswers.includes(option) ?
                            <label
                                className="btn btn-outline-dark mt-1 RegularMadeTommy"
                                htmlFor={`radio${activeStep}${questionIndex}`}
                            >
                                <br />
                                <img
                                    alt={option}
                                    className="mySurveyEmoji"
                                    src={`https://storage.googleapis.com/server-7bytes.appspot.com/Emoji%20Packs/Pack%20${emojiPack.match(/\d+/)[0]}/Pack%20${emojiPack.match(/\d+/)[0]}.${option}.png`}
                                />
                            </label>
                            :
                            <label
                                className="btn btn-outline-dark mt-1"
                                htmlFor={`radio${activeStep}${questionIndex}`}
                            >
                                {/* Si algunas de las opciones es nula coloca la opcion en español */}
                                {option ? option : question.options[questionIndex]}
                            </label>
                        }
                    </div>
                )
            })
            :
            <h6 style={{ color: "red" }}>Error</h6>
    )
}

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useDashboardContext } from '../../CustomHooks/useDashboardContext'
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import { DatesModal } from '../DashboardComponents.js/DatesModal';
import { FormattedMessage } from 'react-intl';

export const FilterDateRange = () => {
    const { rangeDate, setRangeData, availableRanges } = useDashboardContext();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleChange = (event) => {
        setRangeData(event.target.value);
    };

    const handleClick = () => {
        setOpen(true);
    };

    return (
        <>
            <FormControl className='filterRange' size="small">
                <InputLabel id="rangeDate-label">
                    <FilterAltTwoToneIcon />
                    <FormattedMessage id="dashboard.date.range" />
                </InputLabel>
                <Select
                    labelId="rangeDate-label"
                    id="rangeDate"
                    value={rangeDate}
                    label="Rango de fechassdsd"
                    onChange={handleChange}
                    sx={{ minWidth: 140 }}
                >
                    {availableRanges.map((range, index) => (
                        <MenuItem key={index} value={range}>
                            <FormattedMessage id="dashboard.date.range.options"
                                values={
                                    { range }
                                } />
                            {/* Ultimos {range} dias  */}
                        </MenuItem>
                    ))}
                    <MenuItem onClick={handleClick} value={0}>
                        <FormattedMessage id="dashboard.date.range.custom" />
                    </MenuItem>
                </Select>
            </FormControl>
            <DatesModal open={open} handleClose={handleClose} />
        </>
    )
}

import { useState, useEffect } from "react";
import { MaterialReactTable } from 'material-react-table';
import useAxiosPrivate from "../../CustomHooks/useAxiosPrivate";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { styled } from '@mui/material/styles';
import { topCenterPosition, TransitionDown } from '../UtilsComponents/SnackBarTransitions';
import useAuth from "../../CustomHooks/useAuth";
import { Badge, Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, Tooltip, useMediaQuery } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { TableLoadingSkeleton } from "../ReuseComponents/TableLoadingSkeleton";
import { Delete, Edit } from '@mui/icons-material';
import { SnackBarComponent } from "../UtilsComponents/SnackBarComponent";
import { EditUser } from "./EditUser";
import { TableLanguages } from "../../Languages/TableLanguages";
import { CapitializeFirstLetters } from "../SurveyComponents/UtilsComponents/UtilsFunctions";
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { ROLES_NAMES, ROLES_LIST } from "../../Domains/roleList";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -1,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

export const Users = () => {
    const navigate = useNavigate();
    const { UserInfo } = useAuth();
    const intl = useIntl();
    const queryClient = useQueryClient();
    // const navigate = useNavigate();
    // const location = useLocation();
    const actionLanguage = TableLanguages();
    const axiosPrivate = useAxiosPrivate();
    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [color, setColor] = useState("success");
    const [msg, setMsg] = useState("");
    const [change, setChange] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const handleCloseEdit = () => setOpenEdit(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { data: users, isLoading: isLoadingUsers, error: errorUsers, isError: isErrorUsers } = useQuery({
        queryKey: ["Users"],
        queryFn: async () => {
            const response = await axiosPrivate.get(`userinfo?email=${UserInfo.email}&roles=${UserInfo.roles}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    const columnsName = {
        "email": {
            header: intl.formatMessage({ id: "login.email" }),
            Header: <FormattedMessage id="login.email" />
        },
        "date_created": {
            header: intl.formatMessage({ id: "user.table.date.created" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "user.table.date.created.descrip" })}>
                <span>
                    <FormattedMessage id="user.table.date.created" />
                </span>
            </Tooltip>
        },
        "last_login": {
            header: intl.formatMessage({ id: "user.table.date.last.login" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "user.table.date.last.login.descrip" })}>
                <span>
                    <FormattedMessage id="user.table.date.last.login" />
                </span>
            </Tooltip>
        },
        "user_status_id": {
            header: intl.formatMessage({ id: "user.table.date.user.status" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "user.table.date.user.status.descrip" })}>
                <span>
                    <FormattedMessage id="user.table.date.user.status" />
                </span>
            </Tooltip>
        },
        "customer_name": {
            header: intl.formatMessage({ id: "user.table.date.customers" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "user.table.date.customers.descrip" })}>
                <span>
                    <FormattedMessage id="user.table.date.customers" />
                </span>
            </Tooltip>
        },
        "creation_date": {
            header: intl.formatMessage({ id: "user.table.date.created" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "user.table.date.created.descrip" })}>
                <span>
                    <FormattedMessage id="user.table.date.created" />
                </span>
            </Tooltip>
        },
        "role_name": {
            header: intl.formatMessage({ id: "user.table.date.roles" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "user.table.date.roles.descrip" })}>
                <span>
                    <FormattedMessage id="user.table.date.roles" />
                </span>
            </Tooltip>
        },
        "plan_name": {
            header: "Plan",
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "user.table.date.roles.descrip" })}>
                <span>
                    Plan
                </span>
            </Tooltip>
        },
    };

    useEffect(() => {
        let columns = [];
        if (!isLoadingUsers && !isErrorUsers) {
            columns = Object.entries(users[0]).map(([key, value]) => {
                return {
                    header: columnsName[key]?.header,
                    Header: ({ column }) => (
                        columnsName[key]?.Header
                    ),
                    accessorKey: key,
                    Cell: ({ cell }) => {
                        if (key === "role_name") {
                            return (
                                <StyledBadge badgeContent={cell.getValue()?.length} color="primary">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel id="Customers">
                                            <FormattedMessage
                                                id="user.table.date.customers"
                                                defaultMessage={`Clientes`}
                                            />
                                        </InputLabel>
                                        <Select
                                            labelId="Customers"
                                            id="demo-select-small"
                                            label={<FormattedMessage
                                                id="user.table.date.customers"
                                                defaultMessage={`Clientes`}
                                            />}
                                            value={cell.getValue()[0]}
                                        >
                                            {
                                                cell.getValue().map((item) => {
                                                    return <MenuItem key={item} value={item}>{ROLES_NAMES[ROLES_LIST[item]][`name`]}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </StyledBadge>
                            )
                        } else if (key === "user_status_id") {
                            const status = {
                                1: <Chip label={intl.formatMessage({ id: "edit.user.status.active" })} color="success" />,
                                2: <Chip label={intl.formatMessage({ id: "edit.user.status.end.trial" })} color="warning" />,
                                3: <Chip label={intl.formatMessage({ id: "edit.user.status.blocked" })} color="error" />,
                            }
                            return (
                                status[cell.getValue()] ?? <Chip label="N/A" color="error" />
                            )
                        } else if (key === "date_created" || key === "last_login") {
                            const date = new Date(cell.getValue());
                            if (cell.getValue() === null) {
                                return "N/A";
                            }
                            return date.toLocaleDateString("en-GB");
                        } else if (key === "plan_name") {
                            return (
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel htmlFor="grouped-native-select">
                                        <FormattedMessage
                                            id="plans"
                                            defaultMessage={`Planes`}
                                        />
                                    </InputLabel>
                                    <Select native defaultValue="" id="grouped-native-select" label={
                                        <FormattedMessage
                                            id="plans"
                                            defaultMessage={`Planes`}
                                        />
                                    }>
                                        {
                                            cell.row.original.customer_name.map((item, index) => {
                                                return (
                                                    <optgroup key={`plan ${index}`} label={CapitializeFirstLetters(item)}>
                                                        <option defaultValue value={index}>{CapitializeFirstLetters(cell.getValue()[index])} </option>
                                                    </optgroup>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            )
                        }
                        return cell.getValue();
                    }
                }
            });
            //remove customer_name 
            columns = columns.filter((item) => item.accessorKey !== "customer_name");
            setColumns(columns);
            setTableData(users);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, isLoadingUsers, intl]);

    const deleteSurveyMutation = useMutation({
        mutationFn: async (row) => {
            setChange(true);
            const response = await axiosPrivate.delete(
                `/userinfo/${row.original.email}`
            )
            return response.data;
        },
        onSuccess: (data, row) => {
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            setChange(false);
            setMsg(intl.formatMessage({ id: "item.deleted.msg" }));
            setColor("success");
            setOpenSnackBar(true);
            queryClient.invalidateQueries(["table", "Users"], { exact: true });
        },
        onError: (error) => {
            setChange(false);
            setColor("error");
            setMsg(intl.formatMessage({ id: "delete.item.error.msg" }));
            setOpenSnackBar(true);
            console.error(error);
        }
    });

    const deleteRow = async (row) => {
        try {
            if (window.confirm(intl.formatMessage({ id: "delete.item.msg" }))) {
                deleteSurveyMutation.mutate(row);
            }
        } catch (err) {
            console.error(err);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    if (isLoadingUsers) {
        return <div className='mb-3'>
            <TableLoadingSkeleton isLoading={true} errorMsg={""} />
        </div>
    }
    if (errorUsers) {
        return <div className='mb-3'>
            <TableLoadingSkeleton isLoading={false} errorMsg={errorUsers.message} />
        </div>
    }
    else {
        return (
            <div className='mb-3'>
                <SnackBarComponent
                    time={3000}
                    position={topCenterPosition}
                    transition={TransitionDown}
                    handleClose={handleClose}
                    openSnackBar={openSnackBar}
                    color={color}
                    msg={msg}
                />
                <EditUser
                    open={openEdit}
                    handleClose={handleCloseEdit}
                    selectedRow={selectedRow}
                    setChange={setChange}
                    setColor={setColor}
                    setMsg={setMsg}
                    setOpenSnackBar={setOpenSnackBar}
                />
                <MaterialReactTable
                    columns={columns}
                    data={tableData}
                    muiTableBodyProps={{
                        sx: {
                            //stripe the rows, make odd rows a darker color
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: '#f5f5f5',
                            },
                        },
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            borderRight: '2px solid #e0e0e0', //add a border between columns
                        },
                    }}
                    localization={actionLanguage}
                    positionActionsColumn="last" //no funciona
                    enableRowActions
                    renderRowActionMenuItems={({ row, table, closeMenu }) => [
                        <MenuItem disabled={change}
                            key={3}
                            onClick={() => {
                                setSelectedRow(row.original);
                                setOpenEdit(true);
                                closeMenu();
                            }}
                            sx={{ m: 0 }}
                        >
                            <span>
                                <Edit color="warning" />&nbsp;
                                <FormattedMessage
                                    id="edit"
                                    defaultMessage={`Editar`}
                                />
                            </span>
                        </MenuItem>,
                        <MenuItem
                            disabled={change}
                            key={4}
                            onClick={() => {
                                deleteRow(row);
                                closeMenu();
                            }}
                            sx={{ m: 0 }}
                        >
                            <span>
                                <Delete color="error" />&nbsp;
                                <FormattedMessage
                                    id="delete"
                                    defaultMessage={`Eliminar`}
                                />
                            </span>
                        </MenuItem>,
                    ]}
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem', p: '0.1rem', flexWrap: 'wrap' }}>
                            <Button color="primary"
                                onClick={() => {
                                    navigate("/dashboard/createuser");
                                }}
                                variant="contained" >
                                <AddIcon />
                                {!fullScreen &&
                                    <FormattedMessage
                                        id="navbar.create.user"
                                        defaultMessage={`Crear Usuario`}
                                    />
                                }
                            </Button>
                        </Box>
                    )}
                />
            </div>
        );
    }
};
import React, { useContext, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import MetaDecorator from '../UtilsComponents/MetaDecorator';
import { Particle } from '../ReuseComponents/Particle';
import axios from "../../api/axios";
import useLocalStorage from '../../CustomHooks/useLocalStorage';
import { SnackBarComponent } from '../UtilsComponents/SnackBarComponent';
import { TransitionDown, topCenterPosition } from '../UtilsComponents/SnackBarTransitions';
import ReCAPTCHA from 'react-google-recaptcha';
import { languageContext } from '../../context/languageContext';
import { Link } from 'react-router-dom';

export const ForgotPassword = () => {
    const { REACT_APP_CAPTCHA_SITE_KEY_FORGOT_PASSWORD } = process.env;
    const intl = useIntl();
    const recaptchaRef = useRef();
    const [captchaToken, setCaptchaToken] = useState(null);
    const [email, setEmail] = useLocalStorage("email", "");
    const [isSending, setIsSending] = useState(false);
    const [color, setColor] = useState("success");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [msg, setMsg] = useState('');
    const language = useContext(languageContext);

    const Validations = () => {
        //regex email
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (email === "") {
            setColor("error");
            setMsg("Email requerido");
            setOpenSnackBar(true);
            return false;
        } else if (!emailRegex.test(email)) {
            setColor("error");
            setMsg("Email no válido");
            setOpenSnackBar(true);
            return false;
        } else if (captchaToken === null) {
            setColor("error");
            setMsg(intl.formatMessage({ id: "register.check.captcha" }));
            setOpenSnackBar(true);
            return false;
        }
        return true;
    }

    const onCaptchaChange = (value) => {
        setCaptchaToken(value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!Validations()) return;
            setIsSending(true);
            await axios.post("/auth/forgot-password", { email, captchaToken, language: language.locale });
            setColor("success");
            setMsg(intl.formatMessage({ id: "forgot.password.mail.success" }));
            //reset captcha
            recaptchaRef.current.reset();
        } catch (error) {
            setColor("error");
            if (error.response?.status === 400) {
                setMsg(intl.formatMessage({ id: "error.captcha" }) + " " + intl.formatMessage({ id: "error.captcha.info" }));
            } else {
                setMsg(intl.formatMessage({ id: "forgot.password.mail.error" }));
            }
        }
        finally {
            setOpenSnackBar(true);
            setIsSending(false);
        }
    };


    return (
        <section className=" py-5"
            style={{
                background: "#f3f2f8",
                minHeight: "100vh"
            }}>
            <Particle />
            <MetaDecorator
                title={"Olvido contraseña"}
                description={"Olvido contraseña 7Bytes"}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"olvido contraseña 7Bytes"}
                path={window.location.pathname}
            />
            <SnackBarComponent
                position={topCenterPosition}
                transition={TransitionDown}
                handleClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setOpenSnackBar(false);
                }}
                time={10000}
                openSnackBar={openSnackBar}
                color={color}
                msg={msg}
            />
            <div className="container py-4 Login" style={{ width: "700px", marginTop: "5%" }}>
                <div className="row d-flex justify-content-center align-items-center ">
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-3 signup-content" id="" style={{ margin: "" }}>
                            <div className="text-center" >
                                <h2 className="fw-normal RegularMadeTommy"
                                    style={{
                                        color: "#2b364a",
                                        letterSpacing: 1,
                                        fontSize: 40
                                    }}>
                                    <strong>
                                        <FormattedMessage
                                            id="forgot.password.title"
                                        />
                                    </strong>
                                </h2>
                            </div>
                            <hr style={{ color: "#2b364a" }} />
                            {/* ----------------------- */}
                            <div className="col-md-12 align-items-center" >
                                <div style={{ color: "#2b364a" }} className='mb-3 RegularMadeTommy'>
                                    <h5>
                                        <FormattedMessage
                                            id="forgot.password.description"
                                        />
                                    </h5>
                                </div>
                                <div className="form-floating mb-3 RegularMadeTommy">
                                    <input
                                        type="email"
                                        className="form-control"
                                        style={{ borderRadius: "10px" }}
                                        id="inputemail"
                                        autoComplete="off"
                                        placeholder="Email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <label htmlFor="floatingInputEmail ">
                                        <i className="fa fa-envelope" style={{ color: "gray" }} /> &nbsp;
                                        <FormattedMessage
                                            id="login.email"
                                            defaultMessage={`Email`}
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <ReCAPTCHA
                                        className="centerRecaptcha"
                                        ref={recaptchaRef}
                                        size="normal"
                                        sitekey={REACT_APP_CAPTCHA_SITE_KEY_FORGOT_PASSWORD}
                                        onChange={onCaptchaChange}
                                    />
                                    <div className='row RegularMadeTommy'>
                                        <div className='col-md-8 mt-3'>
                                            <button type="submit" name="submit" disabled={isSending} id="submit" className="form-submit btn-danger" style={{ backgroundColor: "#0e8d87" }}>
                                                {isSending ?
                                                    <FormattedMessage
                                                        id="sending.button"
                                                        defaultMessage={`Enviando...`}
                                                    />
                                                    :
                                                    <FormattedMessage
                                                        id="send.button"
                                                        defaultMessage={`Enviar`}
                                                    />
                                                }
                                            </button>
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                            <Link to={'/login'} type="submit" name="submit" disabled={isSending} id="submit" className="form-submit-small text-center btn-danger">
                                                <FormattedMessage
                                                    id="settings.cancel.pass.btn"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

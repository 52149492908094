import html2canvas from 'html2canvas';

const handleCapture = (captureRef) => {
    if (captureRef.current) {
        const Title = document.querySelector('.Title');
        const children = Title.children;
        children[0].style.display = "none";
        children[1].style.display = "block";
        try {
            html2canvas(captureRef.current, {
                useCORS: true,
                logging: true
            }).then(canvas => {
                const originalHeight = canvas.height;
                const originalWidth = canvas.width;

                // Create a new canvas for the cropped image
                const croppedCanvas = document.createElement('canvas');
                const croppedCtx = croppedCanvas.getContext('2d');
                const maxHeight = 1080;

                if (originalHeight > maxHeight) {
                    // Set the size of the cropped canvas
                    croppedCanvas.width = originalWidth;
                    croppedCanvas.height = maxHeight;

                    // Draw the cropped portion onto the new canvas
                    croppedCtx.drawImage(canvas, 0, 0, originalWidth, maxHeight, 0, 0, originalWidth, maxHeight);
                } else {
                    // No cropping needed, just use the original canvas
                    croppedCanvas.width = originalWidth;
                    croppedCanvas.height = originalHeight;
                    croppedCtx.drawImage(canvas, 0, 0);
                }

                // Create a link to download the image with reduced quality
                const link = document.createElement('a');
                link.href = croppedCanvas.toDataURL('image/jpeg', 0.2); // Adjust quality here (0.0 to 1.0)
                // link.download = 'capture.jpg'; // Change extension to .jpg
                // link.click();
                // console.log(link.href);
                children[0].style.display = "block";
                children[1].style.display = "none";
            });
        } catch (e) {
            console.error(e);
        }


    }
};

export default handleCapture;
import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
    const { setIsAuth, setIsLogin } = useAuth();
    const logout = async () => {
        try {
            const response = await axios.get('logout', {
                withCredentials: true
            });
            if (await response.status === 204) {
                localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
                setIsLogin(false);
                setIsAuth({});
                // navigate('/login');
            }
        } catch (err) {
            console.error(err);
        }
    }
    return logout;
}

export default useLogout
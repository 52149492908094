import { Box, Tab, Tabs } from '@mui/material'
import { useState } from 'react';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import BarChartIcon from '@mui/icons-material/BarChart';
import { ResultsSurvey } from '../ResultsSurveysComponents/ResultsSurvey';
import { InsightsSurvey } from '../ResultsSurveysComponents/InsightsSurvey';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { FormattedMessage } from 'react-intl';
// import useAuth from '../../../CustomHooks/useAuth';

export const TabsViewSurvey = ({ children }) => {
    // const { isViewRole } = useAuth();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box className="mb-3" sx={{ borderBottom: 1, borderColor: 'divider', borderRadius: "7px" }}>
                <Tabs value={value} onChange={handleChange} aria-label="Survey Tabs" centered >
                    <Tab
                        className='minHeightTab'
                        icon={<FormatListNumberedIcon style={{ fontSize: "20px" }} />}
                        label={<FormattedMessage id="survey" />}
                        iconPosition='start'
                    />
                    {/* {!isViewRole &&
                        <Tab className='minHeightTab'
                            icon={<AccountTreeIcon style={{ fontSize: "20px" }} />}
                            value={2}
                            label={<FormattedMessage id="post.survey" />}
                            iconPosition='start'
                        />
                    } */}
                    <Tab className='minHeightTab'
                        icon={<BarChartIcon style={{ fontSize: "20px" }} />}
                        value={1}
                        label={<FormattedMessage id="results" />}
                        iconPosition='start'
                    />
                    <Tab className='minHeightTab'
                        icon={<AutoAwesomeIcon style={{ fontSize: "20px" }} />}
                        value={2}
                        label={"Insights"}
                        iconPosition='start'
                    />
                </Tabs>
            </Box>
            {
                value === 0 && children
            }
            {
                value === 1 && <ResultsSurvey />
            }
            {
                value === 2 && <InsightsSurvey />
            }
        </>
    )
}

import { createContext, useEffect, useState } from "react";
import { useSurveyStyles } from "./SurveyCustomHooks/useSurveyStyles";
import { useSurveyQuestions } from "./SurveyCustomHooks/useSurveyQuestions";
import { useMediaQuery } from "@mui/material";
import useLocalStorage from "../../../../CustomHooks/useLocalStorage";
import { useSessionStorage } from "../../../../CustomHooks/useSessionStorage";
import { useParams } from "react-router-dom";
import { useSurveyValidations } from "./SurveyCustomHooks/useSurveyValidations";

const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
    let { userId, customer } = useParams();
    const [themeMode, setThemeMode] = useLocalStorage("themeMode", window.matchMedia("(prefers-color-scheme: dark)").matches ? "light" : "light");
    const darkBackground = '#2b364a';
    const lightBackground = '#f3f2f8';
    const darkColor = '#7375a5';
    const lightColor = '#13c8b5';
    const surveyBackgroundColor = themeMode === "dark" ? darkBackground : lightBackground;
    const [activeStep, setActiveStep] = useState(0);
    const [animation, setAnimation] = useState("slide-in-right");
    const [enableScrollMove, setEnableScrollMove] = useState(true);
    const isSmallScreen = useMediaQuery('(max-width:480px)');
    const [answers, setAnswers] = useLocalStorage(userId, []);
    const [errorMsg, setErrorMsg] = useState("");
    const [ipTimer, setIpTimer] = useSessionStorage("ipTimer", 0);
    
    const {
        surveyStyles,
        isLoadingStyles,
        isErrorStyles,
    } = useSurveyStyles();

    const {
        surveyInfo,
        questionsNumber,
        surveyQuestions,
        isLoadingQuestions,
        isErrorQuestions,
        isFetchingQuestions,
        saveAnswersMutation,
        updateUserMutation,
        sending,
        ipInfo,
        isLoadingIpInfo,
        saveipInfo,
        getLanguageOptions
    } = useSurveyQuestions();

    const {
        handleValidation
    } = useSurveyValidations();

    //#region Save IP info when opening the survey
    useEffect(() => {
        if (!!isLoadingIpInfo) {
            return;
        }
        const getCurrentTime = new Date().getTime();
        if (ipTimer > getCurrentTime) {
            return;
        }
        const logIp = {
            user_id: userId,
            country_name: ipInfo?.country_name,
            city: ipInfo?.city,
            postal: ipInfo?.postal,
            latitude: ipInfo?.latitude,
            longitude: ipInfo?.longitude,
            ip: ipInfo?.IPv4,
            state: ipInfo?.state
        };
        saveipInfo.mutate(logIp);
        setIpTimer(getCurrentTime + 600000); // 10 minutes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ipInfo, isLoadingIpInfo]);
    //#endregion

    //#region Handle next and back buttons
    const handleNext = () => {
        const validationMsg = handleValidation(surveyQuestions[activeStep], answers, updateAnswers);
        setErrorMsg(validationMsg);
        if (validationMsg !== '') return null;
        if (!enableScrollMove) return;
        if (activeStep >= questionsNumber - 1) return;
        setAnimation("slide-out-left");
        setTimeout(() => {
            setActiveStep(activeStep + 1);
            setAnimation("slide-in-right");
        }, 100);
    };

    const handleBack = () => {
        if (!enableScrollMove) return;
        if (activeStep <= 0) return;
        setAnimation("slide-out-right");
        setTimeout(() => {
            setActiveStep(activeStep - 1);
            setAnimation("slide-in-left");
            setErrorMsg("");
        }, 100);
    };
    //#endregion

    const updateAnswers = (itemId, value, type = "unique") => {
        const existingAnswer = answers.find(answer => answer.survey_item_id === itemId);
        if (existingAnswer) {
            if (type === "multiple") {
                //append new value above the existing one
                setAnswers([
                    ...answers,
                    {
                        id: userId,
                        survey_item_id: itemId,
                        answer_value: value
                    }
                ]);
            } else {
                // Actualiza el valor de una respuesta existente
                setAnswers(
                    answers.map(answer =>
                        answer.survey_item_id === itemId
                            ? { ...answer, answer_value: value }
                            : answer
                    )
                );
            }
        } else {
            // Agrega una nueva respuesta
            setAnswers([
                ...answers,
                {
                    id: userId,
                    survey_item_id: itemId,
                    answer_value: value
                }
            ]);
        }
    }

    return (
        <SurveyContext.Provider value={{
            userId,
            customer,
            surveyBackgroundColor,
            activeStep,
            setActiveStep,
            handleNext,
            handleBack,
            setEnableScrollMove,
            isSmallScreen,
            animation,
            setAnimation,
            questionsNumber,
            surveyStyles,
            isLoadingStyles,
            isErrorStyles,
            surveyInfo,
            surveyQuestions,
            isLoadingQuestions,
            isErrorQuestions,
            isFetchingQuestions,
            themeMode,
            setThemeMode,
            darkBackground,
            darkColor,
            lightBackground,
            lightColor,
            answers,
            setAnswers,
            updateAnswers,
            errorMsg,
            saveAnswersMutation,
            updateUserMutation,
            sending,
            getLanguageOptions
        }}>
            {children}
        </SurveyContext.Provider>
    );
}

export default SurveyContext;

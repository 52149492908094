import React, { useEffect, useState } from 'react'
import { SurveyCards } from './SurveyCards'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useMediaQuery } from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HotelIcon from '@mui/icons-material/Hotel';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PollIcon from '@mui/icons-material/Poll';
import { useMutation } from '@tanstack/react-query';
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate';
import useAuth from '../../CustomHooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

export const LoginHome = () => {
    const axiosPrivate = useAxiosPrivate();
    const intl = useIntl();
    const { defaultCustomer } = useAuth();
    const [checkClickState, setCheckClickState] = useState({
        [intl.formatMessage({ id: "dashboard.create.new.sruvey" })]: false,
        [intl.formatMessage({ id: "dashboard.restaurant.survey" })]: false,
        [intl.formatMessage({ id: "dashboard.hotel.survey" })]: false,
        [intl.formatMessage({ id: "dashboard.ecco.survey" })]: false,
        [intl.formatMessage({ id: "dashboard.general.survey" })]: false
    });
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const isXXLargeScreen = useMediaQuery('(min-width:1920px)');
    const isXLargeScreen = useMediaQuery('(min-width:1440px)');
    const isLargeScreen = useMediaQuery('(min-width:1130px)');
    const isMediumScreen = useMediaQuery('(min-width:960px)');
    const isSmallScreen = useMediaQuery('(min-width:768px)');

    useEffect(() => {
        //change language
        const newLang = {
            [intl.formatMessage({ id: "dashboard.create.new.sruvey" })]: false,
            [intl.formatMessage({ id: "dashboard.restaurant.survey" })]: false,
            [intl.formatMessage({ id: "dashboard.hotel.survey" })]: false,
            [intl.formatMessage({ id: "dashboard.ecco.survey" })]: false,
            [intl.formatMessage({ id: "dashboard.general.survey" })]: false
        }
        setCheckClickState(newLang);
    }, [intl]);

    const createSurveyMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axiosPrivate.post(`survey?customer=${defaultCustomer}`, values);
            return response.data;
        },
        onSuccess: (data, values) => {
            setCheckClickState(
                prev => ({
                    ...prev,
                    [values.cardName]: false
                })
            );
            navigate(`/viewsurvey?survey=${data.id}`);
        },
        onError: (error) => {
            //set all clicked to false
            Object.keys(checkClickState).forEach((key) => {
                setCheckClickState(
                    prev => ({
                        ...prev,
                        [key]: false
                    })
                );
            });
            console.error(error);
        }
    });
    // const showItems = 4;
    // Mapeo de valores según el tamaño de pantalla
    const screenSizes = [
        { query: isXXLargeScreen, items: 7 },
        { query: isXLargeScreen, items: 5 },
        { query: isLargeScreen, items: 4 },
        { query: isMediumScreen, items: 3 },
        { query: isSmallScreen, items: 2 },
    ];

    // Encontrar el primer tamaño que coincida
    const showItems = screenSizes.find(size => size.query)?.items || 1;

    let cards = [
        {
            image: "/images/plus.svg",
            name: intl.formatMessage({ id: "dashboard.create.new.sruvey" }),
            styles: {
                objectFit: 'contain',
                padding: '10px'
            },
            click: () => {
                setCheckClickState(
                    prev => ({
                        ...prev,
                        [intl.formatMessage({ id: "dashboard.create.new.sruvey" })]: true
                    })
                );
                createSurveyMutation.mutate({ new: true, cardName: intl.formatMessage({ id: "dashboard.create.new.sruvey" }) });
            }
        },
        {
            image: "/images/SurveyDemo.jpg",
            name: intl.formatMessage({ id: "dashboard.restaurant.survey" }),
            icon: <RestaurantIcon />,
            styles: {
                objectFit: 'cover'
            },
            click: () => {
                setCheckClickState(
                    prev => ({
                        ...prev,
                        [intl.formatMessage({ id: "dashboard.restaurant.survey" })]: true
                    })
                );
                createSurveyMutation.mutate({
                    new: true,
                    plan_id: 7,
                    cardName: intl.formatMessage({ id: "dashboard.restaurant.survey" })
                });
            }
        },
        {
            image: "/images/SurveyDemo.jpg",
            name: intl.formatMessage({ id: "dashboard.hotel.survey" }),
            icon: <HotelIcon />,
            click: () => {
                setCheckClickState(
                    prev => ({
                        ...prev,
                        [intl.formatMessage({ id: "dashboard.hotel.survey" })]: true
                    })
                );
                createSurveyMutation.mutate({
                    new: true,
                    plan_id: 11,
                    cardName: intl.formatMessage({ id: "dashboard.hotel.survey" })
                });
            }
        },
        {
            image: "/images/SurveyDemo.jpg",
            name: [intl.formatMessage({ id: "dashboard.ecco.survey" })],
            icon: <ShoppingCartIcon />,
            click: () => {
                setCheckClickState(
                    prev => ({
                        ...prev,
                        [[intl.formatMessage({ id: "dashboard.ecco.survey" })]]: true
                    })
                );
                createSurveyMutation.mutate({
                    new: true,
                    plan_id: 9,
                    cardName: [intl.formatMessage({ id: "dashboard.ecco.survey" })]
                });
            }
        },
        {
            image: "/images/SurveyDemo.jpg",
            name: intl.formatMessage({ id: "dashboard.general.survey" }),
            icon: <PollIcon />,
            click: () => {
                setCheckClickState(
                    prev => ({
                        ...prev,
                        [intl.formatMessage({ id: "dashboard.general.survey" })]: true
                    })
                );
                createSurveyMutation.mutate({
                    new: true,
                    plan_id: 10,
                    cardName: intl.formatMessage({ id: "dashboard.general.survey" })
                });
            }
        }
    ];

    const handleNext = () => {
        if (index + showItems < cards.length) {
            setIndex(index + 1);
        }
    };

    const handlePrev = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    };

    return (
        <div className='d-flex align-items-center mb-3'>
            <ArrowCircleLeftIcon
                onClick={handlePrev}
                disabled={index === 0}
                style={{
                    display: index === 0 ? 'none' : 'flex',
                    color: index === 0 ? 'gray' : "whitesmoke",
                    cursor: 'pointer',
                    marginRight: "10px",
                    fontSize: "40px"
                }}
            />
            {cards.slice(index, index + showItems).map((card, i) => (
                <SurveyCards
                    key={i}
                    image={card.image}
                    name={card.name}
                    icon={card.icon}
                    styles={card?.styles}
                    click={card.click}
                    checkClickState={checkClickState}
                />
            ))}
            <ArrowCircleRightIcon
                onClick={handleNext}
                disabled={index + showItems >= cards.length}
                style={{
                    display: index + showItems >= cards.length ? 'none' : 'flex',
                    cursor: 'pointer',
                    color: index + showItems >= cards.length ? 'gray' : "whitesmoke",
                    marginRight: "10px",
                    fontSize: "40px"
                }}
            />
        </div>
    );
}

import React from 'react'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#6A5ACD', '#32CD32', '#F08080', '#9370DB', '#00A423', '#FFB222', '#FF8941'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    if (percent < 0.079) return null;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};



export const PieSurveyResults = ({ data }) => {
    const renderCustomizedTooltip = (value, name, props) => {
        let total = 0;
        data.map((item) => total += item.count);
        return [`Total ${value} (${Math.round(value * 100 / total)}%)`, `${props.payload.payload.answer}`];
    }

    return (
        <PieChart className='center' width={200} height={200}>
            <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="count"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Tooltip formatter={renderCustomizedTooltip} separator=' : ' />
        </PieChart>
    )
}

export const itemLabelLanguage = {
    "es": "item_label",
    "en": "item_label_en",
    "fr": "item_label_fr"
}

export const optionsLabelLanguage = {
    "es": "options",
    "en": "options_en",
    "fr": "options_fr"
}
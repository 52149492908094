import { createContext, useEffect, useState } from "react";
import useLocalStorage from "../../../CustomHooks/useLocalStorage";
const DashboardContext = createContext({});

export const DashboardProvider = ({ children }) => {
    const availableRanges = [3, 7, 10, 15, 20, 30];
    const today = new Date(new Date().getTime() - (5 * 60 * 60 * 1000));
    const [rangeDate, setRangeData] = useLocalStorage('rangeDate', 7);
    const [initDate, setInitDate] = useState(
        availableRanges.includes(rangeDate) && rangeDate !== 0 ?
            new Date(today.getTime() - (rangeDate * 24 * 60 * 60 * 1000)).toISOString().split('T')[0]
            :
            new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0]
    );
    const [endDate, setEndDate] = useState(
        today.toISOString().split('T')[0]
    );

    useEffect(() => {
        if (rangeDate === 0) return;
        if (availableRanges.includes(rangeDate)) {
            const initDateStr = new Date(today.getTime() - (rangeDate * 24 * 60 * 60 * 1000)).toISOString().split('T')[0];
            // Display the results
            // console.log("UTC Date minus 5 hours:", todayStr);
            // console.log("UTC Date minus 5 hours:", initDateStr);
            setInitDate(prev => initDateStr);
            setEndDate(prev => today.toISOString().split('T')[0]);
        } else {
            setRangeData(7);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeDate]);

    return (
        <DashboardContext.Provider value={{ rangeDate, setRangeData, initDate, endDate, availableRanges, setInitDate, setEndDate }}>
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardContext;
import { FormattedMessage } from "react-intl";

export const AnonymousSurvey = (props) => {
    const { formValues, handleChange, required, formErrors } = props;

    // const validation = (values) => {
    //     const errors = {};
    //     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    //     const regexName = /^[a-zA-Z\s]+$/;
    //     if (!values.display_name) {
    //         errors.display_name = "El nombre es requerido";
    //     } else if (!regexName.test(values.display_name)) {
    //         errors.display_name = "El nombre solo puede contener letras";
    //     }
    //     if (!values.email) {
    //         errors.email = "El email es requerido";
    //     } else if (!regex.test(values.email)) {
    //         errors.email = "El email no es valido";
    //     }
    //     if (!values.phone) {
    //         errors.phone = "El telefono es requerido";
    //     }
    //     return errors;
    // }

    return (
        <>
            <div className="row">
                <div className="col-sm-7 mt-4">
                    <div className="card shadow">
                        <div className="card-body">
                            <label id="Label2" className="form-label mb-3" htmlFor="floatingInputNombre">
                                <FormattedMessage
                                    id="amonymous.survey.title.name"
                                    defaultMessage="Por favor ingresa tu nombre."
                                />
                                {required && <span className="mt-3" style={{ color: "#FF0000" }}> *</span>}
                            </label>
                            <div className="form-floating mb-2">
                                <input
                                    type="text"
                                    name="display_name"
                                    className="form-control"
                                    id="floatingInputNombre"
                                    placeholder="Nombre"
                                    value={formValues.display_name}
                                    onChange={handleChange}
                                    maxLength="60"
                                    data-hj-allow
                                />
                                <label htmlFor="floatingInputNombre">
                                    <FormattedMessage
                                        id="amonymous.survey.name"
                                        defaultMessage="Nombre"
                                    />
                                </label>
                            </div>
                            <span style={{ color: "#FF0000" }}>{formErrors?.display_name}</span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-5 mt-4">
                    <div className="card shadow">
                        <div className="card-body">
                            <label id="Label2" className="form-label mb-3" htmlFor="floatingInputCedula">
                                <FormattedMessage
                                    id="amonymous.survey.title.id"
                                    defaultMessage="Por favor ingresa tu cedula."
                                />
                                {required && <span className="mt-3" style={{ color: "#FF0000" }}> *</span>}
                            </label>
                            <div className="form-floating mb-2">
                                <input
                                    type="number"
                                    name="identification_number"
                                    className="form-control"
                                    id="floatingInputCedula"
                                    min="0"
                                    max="99999999999"
                                    placeholder="Cedula"
                                    value={formValues.identification_number}
                                    onChange={handleChange}
                                    data-hj-allow
                                />
                                <label htmlFor="floatingInputCedula">
                                    <FormattedMessage
                                        id="amonymous.survey.id"
                                        defaultMessage="Cedula"
                                    />
                                </label>
                            </div>
                            <span style={{ color: "#FF0000" }}>{formErrors?.identification_number}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-sm-7 mt-4">
                    <div className="card shadow">
                        <div className="card-body">
                            <label id="Label1" className="form-label mb-3" htmlFor="floatingInputEmail">
                                <FormattedMessage
                                    id="amonymous.survey.title.email"
                                    defaultMessage="Por favor ingresa tu email."
                                />
                                {required && <span className="mt-3" style={{ color: "#FF0000" }}> *</span>}
                            </label>
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    id="floatingInputEmail"
                                    placeholder="name@example.com"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    data-hj-allow
                                />
                                <label htmlFor="floatingInputEmail">
                                    <FormattedMessage
                                        id="amonymous.survey.email"
                                        defaultMessage="Email"
                                    />
                                </label>
                            </div>
                            <span style={{ color: "#FF0000" }}>{formErrors?.email}</span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-5 mt-4">
                    <div className="card shadow">
                        <div className="card-body">
                            <label id="Label3" className="form-label mb-3" htmlFor="floatingInputCelular">
                                <FormattedMessage
                                    id="amonymous.survey.title.phone"
                                    defaultMessage="Por favor ingresa tu celular."
                                />
                                {required && <span className="mt-3" style={{ color: "#FF0000" }}> *</span>}
                            </label>
                            <div className="form-floating mb-3">
                                <input
                                    type="number"
                                    name="phone"
                                    className="form-control"
                                    id="floatingInputCelular"
                                    placeholder="Celular"
                                    min="0"
                                    max="9999999999"
                                    value={formValues.phone}
                                    onChange={handleChange}
                                    data-hj-allow
                                />
                                <label htmlFor="floatingInputCelular">
                                    <FormattedMessage
                                        id="amonymous.survey.phone"
                                        defaultMessage="Celular"
                                    />
                                </label>
                            </div>
                            <span style={{ color: "#FF0000" }}>{formErrors?.phone}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
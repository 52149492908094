import { Box } from '@mui/material'
import { SurveyHeader } from '../SurveyComponents/SurveyHeader';
import { useSurveyContext } from '../Context/SurveyCustomHooks/useSurveyContext';
import { FooterBtn } from './FooterBtn';
import { SurveySkeleton } from './SurveySkeleton';
import { NotFound } from '../../../ErrorComponents/NotFound';
import { SurveyQuestions } from './SurveyQuestions/SurveyQuestions';
import MetaDecorator from '../../../UtilsComponents/MetaDecorator';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const SurveyContent = () => {
    const {
        surveyBackgroundColor: backgroundColor,
        isLoadingStyles,
        customer,
        animation,
        handleNext,
        handleBack,
        isErrorStyles,
        isLoadingQuestions,
        surveyInfo,
        isFetchingQuestions,
        isErrorQuestions,
        userId,
        surveyStyles
    } = useSurveyContext();
    const navigate = useNavigate();

    //* Posible problema si la pregunta es muy larga 
    //* por el handleScroll y overflow: "hidden" en el Box
    //* Genera conflicto con el scroll normal de la página
    const handleScroll = (event) => {
        // let lastScrollTime = 0;
        // const now = Date.now();
        // if (now - lastScrollTime < 200) return; // Espera 500ms entre desplazamientos
        // lastScrollTime = now;
        const delta = event.deltaY; // Detecta la dirección del scroll
        if (delta < 0) {
            handleNext();
        } else if (delta > 0) {
            handleBack();
        }
    };

    //#region Navigate to PostSurvey when survey is completed or status is 4
    useEffect(() => {
        if (surveyInfo && surveyInfo.status === "4") {
            navigate(
                `/postsurvey/${customer}/${userId}`,
                {
                    state: {
                        surveyInfo,
                        surveyStyles
                    }
                }
            );
        }
    }, [surveyInfo, customer, userId, navigate, surveyStyles]);
    //#endregion

    if (isLoadingStyles || isLoadingQuestions || isFetchingQuestions) return <SurveySkeleton />
    if (isErrorStyles || isErrorQuestions) return <NotFound />
    if ([0, 1].includes(parseInt(surveyInfo.status))) {
        return <NotFound />
    }
    if (surveyInfo.status === "4") {
        // Return null to avoid rendering anything while navigating
        return null
    }
    return (
        <Box
            sx={{
                backgroundColor,
                height: "100vh",
                overflow: "hidden", // Scroll personalizado
            }}
            onWheel={handleScroll}
        >
            <MetaDecorator
                key={0}
                title={customer?.includes("20Mission".toLowerCase()) ? `Survey | 20Mission Cerveza` : `Survey | ${customer.split(" ").map(subStr => subStr[0].toUpperCase() + subStr.slice(1)).join(" ")}`}
                description={"Para nosotros es muy importante conocer tu opinión sobre nuestros productos y servicios. Por favor, ayúdanos a mejorar respondiendo esta encuesta."}
                imageUrl={"/images/7Bytes.svg"}
                imageAlt={"7Bytes"}
                path={window.location.pathname}
            />
            <SurveyHeader />
            <div
                className={`${animation}`}
                style={{
                    position: "relative",
                    width: "100%",
                }}
            >
                <SurveyQuestions />
            </div>
            <FooterBtn />
        </Box>
    )
}

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { SnackBarComponent } from '../../UtilsComponents/SnackBarComponent';
import { TransitionDown, topCenterPosition } from '../../UtilsComponents/SnackBarTransitions';

export const CreateSurveyDialog = ({ open, handleClosedialog, columns, onSubmit, change, openDialogSnackBar, handleClose, color, msg, validateCreateLimitation }) => {
    const theme = useTheme();
    const intl = useIntl();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [validationErrors, setValidationErrors] = useState({});
    const [clickedBtn, setClickedBtn] = useState(false);
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );

    useEffect(() => {
        setValues(() =>
            columns.reduce((acc, column) => {
                if (column.filterVariant === "select") {
                    acc[column.accessorKey ?? ''] = column.filterSelectOptions[0].value;
                } else {
                    acc[column.accessorKey ?? ''] = '';
                }
                return acc;
            }, {}),
        );
    }, [columns]);

    useEffect(() => {
        if (clickedBtn) {
            setValidationErrors(validateValues());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, clickedBtn]);

    const validateValues = () => {
        let errors = {};
        const specialCharacters = /[!@#ñÑ$%^&*()_+=[\]{};':"\\|,.<>/?]+/;
        const tilde = /[áéíóúÁÉÍÓÚ´]+/;
        Object.keys(values).forEach((key) => {
            const value = values[key];
            if (value === '') {
                errors[key] = intl.formatMessage({ id: "survey.create.required" });
            }
            if (key === "survey_name") {
                if (!(value.length >= 3 && value.length <= 50)) {
                    errors[key] = (intl.formatMessage({ id: "table.survey.name.length" }));
                }
            }
            else if (key === "source") {
                if (value.includes(" ")) {
                    errors[key] = (intl.formatMessage({ id: "register.url.name.space" }));
                } else if (specialCharacters.test(value) || tilde.test(value)) {
                    errors[key] = (intl.formatMessage({ id: "register.store.url.name.special.char" }));
                }
                if (!(value.length >= 3 && value.length <= 50)) {
                    errors[key] = (intl.formatMessage({ id: "table.survey.source.length" }));
                }
            } else if (!isNaN(value)) {
                if (parseInt(value) < 0) {
                    errors[key] = intl.formatMessage({ id: "edit.survey.label.order.error" })
                }
            }
        });
        return errors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateCreateLimitation()) return;
        setClickedBtn(true);
        const validations = validateValues();
        setValidationErrors(validations);
        if (Object.values(validations).some(error => error !== "")) {
            return null
        }
        onSubmit(values);
        setClickedBtn(false);
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClosedialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <FormattedMessage
                        id="survey.create.new"
                        defaultMessage={`Crear nueva encuesta`}
                    />
                </DialogTitle>
                <DialogContent>
                    <SnackBarComponent
                        time={5000}
                        position={topCenterPosition}
                        transition={TransitionDown}
                        handleClose={handleClose}
                        openSnackBar={openDialogSnackBar}
                        color={color}
                        msg={msg}
                    />
                    <form className='mt-2'>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: { xs: '200px', sm: '320px', md: '380px' },
                                gap: '1.5rem',
                            }}
                        >
                            {columns.map((column) => (
                                column.filterVariant === "select" ?
                                    <Box sx={{ minWidth: 120 }} key={column.accessorKey}>
                                        <FormControl fullWidth>
                                            <InputLabel id={"select" + column.accessorKey}>{column.header}</InputLabel>
                                            <Select
                                                labelId={"select" + column.accessorKey}
                                                id={"select" + column.accessorKey}
                                                value={values[column.accessorKey ?? '']}
                                                label={column.accessorKey}
                                                name={column.accessorKey}
                                                onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
                                            >
                                                {
                                                    column.filterSelectOptions.map((option, i) => (
                                                        <MenuItem key={"select" + i} value={option.value}>{option.text}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    :
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        required
                                        error={validationErrors[column.accessorKey ?? ''] ? true : false}
                                        type={column.filterVariant}
                                        value={values[column.accessorKey ?? '']}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                        helperText={
                                            validationErrors[column.accessorKey ?? ''] ?
                                                validationErrors[column.accessorKey ?? ''] :
                                                column.filterVariant === "number" ? intl.formatMessage({ id: "edit.survey.item.helper.text" }) :
                                                    column?.id === "source" ? intl.formatMessage({ id: "edit.survey.source.helper.text" }) : null
                                        }
                                    />
                            ))}
                        </Stack>
                    </form>
                    {
                        change ?
                            <Box sx={{ width: '100%', paddingTop: "20px" }}>
                                <LinearProgress />
                            </Box>
                            :
                            null
                    }
                </DialogContent>
                <DialogActions sx={{ p: '0 1.25rem 1.25rem 1.25rem' }}>
                    <Button onClick={handleClosedialog}>
                        <FormattedMessage
                            id="settings.cancel.pass.btn"
                            defaultMessage={`Cancelar`}
                        />
                    </Button>
                    <Button color="secondary" disabled={change} onClick={handleSubmit} variant="contained">
                        {
                            change ?
                                <FormattedMessage
                                    id="creating.user.btn"
                                    defaultMessage={`Creando...`}
                                />
                                : <FormattedMessage
                                    id="create.user.btn"
                                    defaultMessage={`Crear`}
                                />
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

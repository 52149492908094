import { Button, Dialog, DialogActions, DialogContent, Tooltip } from '@mui/material';
import { QRCodeSVG } from "qrcode.react";
import { DownloadQrToPng, DownloadQrToSvg } from '../UtilsComponents/DownloadQr';
import { Link } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FormattedMessage, useIntl } from 'react-intl';
import { MainDialogTitle } from './MainDialogTitle';
import LaunchIcon from '@mui/icons-material/Launch';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import template from "../../Domains/customers.json";

export const QrDialog = ({ open, setOpen, qrId, qrUlr }) => {
    const locationUrl = "https://" + window.location.host;
    const intl = useIntl();

    //Estas son las medidas de la imagen que se utiliza de logo en el QR (No CAMBIAR)
    const width7BytesImg = 1213;
    const height7BytesImg = 278;
    //(SE PUEDEN CAMBIAR)
    const qrSize = 800; //Para tener una buena deficion al descargar la imagen en PNG
    const logoHeight = ((height7BytesImg * (qrSize / 2)) / width7BytesImg);
    const logoWidth = ((width7BytesImg * logoHeight) / height7BytesImg);
    const reduceSize = 200; //Para reducir el tamaño del QR y no se vea tan grande y no ocupe qrSize

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <MainDialogTitle id="responsive-dialog-title" onClose={handleClose}>
                QR {qrId}
            </MainDialogTitle>
            <DialogContent >
                <div className='text-center mb-3' >
                    <QRCodeSVG
                        style={{ width: reduceSize, height: "auto" }}
                        value={locationUrl + qrUlr}
                        includeMargin={false}
                        size={qrSize}
                        level="H"
                        id={qrId}
                        imageSettings={{
                            src: template["data7Bytes"],
                            x: undefined,
                            y: undefined,
                            height: logoHeight,
                            width: logoWidth,
                            excavate: true,
                        }}
                    />
                </div>
                {qrUlr &&
                    <div className='text-center '>
                        <Tooltip title={locationUrl + qrUlr} placement="bottom">
                            <span>
                                <Link to={qrUlr} target="_blank" autoFocus rel="noopener noreferrer" style={{ color: "#3c8bd9" }}>
                                    <LaunchIcon sx={{ width: "17px" }} />&nbsp;
                                    <FormattedMessage
                                        id="open.survey"
                                        defaultMessage={'Abrir encuesta'}
                                    />
                                </Link>
                            </span>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title={intl.formatMessage({ id: "clickToCopy" })} placement="right">
                            <ContentCopyIcon sx={{ width: "15px", cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(locationUrl + qrUlr) }} />
                        </Tooltip>
                    </div>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => DownloadQrToSvg(qrId, qrId)}>
                    <CodeOutlinedIcon />&nbsp;
                    <FormattedMessage
                        id="download.qr.code.svg"
                        defaultMessage={'Descargar SVG'}
                    />
                </Button>
                <Button autoFocus onClick={() => DownloadQrToPng(qrId, qrId, qrSize)}>
                    <InsertPhotoOutlinedIcon />&nbsp;
                    <FormattedMessage
                        id="download.qr.code.png"
                        defaultMessage={'Descargar PNG'}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    )
        ;
}

import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

export const DependentQuestions = (props) => {
    const { fields } = props;
    const { depentQues } = props;
    const { openItem } = props; //openItem es el id de la pregunta que abre la dependencia
    const { activationValue } = props;
    const oneDepentQues = depentQues.filter(question => question.optionvalue === activationValue);
    const [item, setItem] = useState(oneDepentQues[0].dependentQuestion);
    // Para dar el valor a la respuesta del campo otros dependiendo el id que abre la pregunta, 
    // para que asi se almacene una respuesta diferente 
    const [checkTextValues, setCheckTextValues] = useState(
        item.values && item.values[`checkOpt${item.itemId}${openItem}`] ?
            { [`checkOpt${item.itemId}${openItem}`]: item.values[`checkOpt${item.itemId}${openItem}`] }
            :
            { [`checkOpt${item.itemId}${openItem}`]: "" }
    );
    const forOtherOptions = process.env.REACT_APP_FOR_OTHERS_OPTIONS.replace(/\s/g, "").replace("'", "").split(",");

    useEffect(() => {
        if (item.itemType === "Radio" && item.values && item.values.find(value => value === true)) {
            const indexOfTrue = item.values.indexOf(true);
            if (forOtherOptions.includes(item.options[indexOfTrue].toLowerCase()) && item.values[indexOfTrue]) {
                let dropdownDiv = document.querySelector(`#DepentRadio${item.itemId}${indexOfTrue}`);
                dropdownDiv.classList.remove("d-none")
                dropdownDiv.focus()
            }
        }
    }, [item, forOtherOptions]);

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        // console.log(name, value,name.search(/\d/));
        let i = parseInt(name.substring(name.search(/\d/)));
        item.value = value;
        setItem({ ...item });
        fields.questions.forEach((field) => {
            if (field.depentId.length > 0) {
                field.depentId.forEach((q) => {
                    if (q.dependentQuestion.itemId === i.toString() && activationValue === q.optionvalue) {
                        q.dependentQuestion.value = item.value;
                    }
                    else if (q.dependentQuestion.depentId.length > 0) {
                        ChangeFieldsTextValue(q, i, item.value);
                    }
                });
            }
        });
    };

    const ChangeFieldsTextValue = (q, i, value) => {
        q.dependentQuestion.depentId.forEach((depentQ) => {
            if (depentQ.dependentQuestion.itemId === i.toString() && activationValue === depentQ.optionvalue) {
                depentQ.dependentQuestion.value = value;
            } else if (depentQ.dependentQuestion.depentId.length > 0) {
                ChangeFieldsTextValue(depentQ, i, value);
            }
        });
    };

    const handleRadioChange = (event) => {
        const { id } = event.target;
        const onlyNumber = id.substring(id.search(/\d/));
        let j = parseInt(onlyNumber) % 10;//Aqui solo importa el ultimo digito
        // Get the index of the last true value
        //!Aqui puede haber un problema si la opcion de otros no es la ultima
        let indexOfTrue = item.values.lastIndexOf(true);
        let indexOfFalse = item.values.lastIndexOf(false);
        let maxIndex = Math.max(indexOfTrue, indexOfFalse);
        item.values.fill(false, 0, maxIndex + 1);
        item.values[j] = true;
        setItem({ ...item });
        // console.log(item);
        if (forOtherOptions.includes(item.options[j].toLowerCase()) && item.values[j]) {
            let dropdownDiv = document.querySelector(`#DepentRadio${onlyNumber}`);
            dropdownDiv.classList.remove("d-none")
            dropdownDiv.focus()
        } else {
            //Search the index of the option equal to otros
            const indexOtros = item.options.findIndex(e => forOtherOptions.includes(e.toLowerCase()));
            if (indexOtros !== -1) {
                let dropdownDiv = document.querySelector(`#DepentRadio${item.itemId}${indexOtros}`);
                dropdownDiv.classList.add("d-none")
            }
        }
    };

    const handleRadioTextChange = async (event) => {
        const { value } = event.target;
        //check the last true value
        const indexOfTrue = item.values.indexOf(true);
        const indexOfFalse = item.values.indexOf(false);
        const maxIndex = indexOfTrue >= indexOfFalse ? indexOfTrue : indexOfFalse;
        //add new value to the options
        let newOptions = item.values;
        newOptions[maxIndex + 1] = value;
        setItem({ ...item, values: newOptions });
    };

    const handleCheckChange = (event) => {
        const { id, checked } = event.target;
        let divNumber = id.substring(id.search(/\d/));
        let k = parseInt(divNumber) % 10;
        item.values[k] = checked;
        setItem({ ...item });
        let trueOption = item.options[k];
        if (trueOption.toLowerCase() === "otros" || trueOption.toLowerCase() === "Otro" || trueOption.toLowerCase() === "Otr@s" || trueOption.toLowerCase() === "otra" || trueOption.toLowerCase() === "otras") {
            let dropdownDiv = document.querySelector(`#divOpt${divNumber}`);
            if (checked) {
                dropdownDiv.classList.remove("d-none");
                dropdownDiv.querySelector(`#checkOpt${divNumber}`).focus();//Creo que no sirve el focus porque hay dos inputs con el mismo id
            } else {
                dropdownDiv.classList.add("d-none");
            }
        }
    };

    const handleCheckTextChange = (event) => {
        const { name, value } = event.target;
        setCheckTextValues({ ...checkTextValues, [name]: value });
        item.values[name] = value;
        setItem({ ...item });
    };

    return (<>
        {item.itemType === "Text Box" && (
            <div className="row mt-4" key={item.itemId}>
                <div className="col-sm-12">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="form-group">
                                <label className="form-label mb-3" htmlFor={`text${item.itemId}`}>{item.textLabel}</label>
                                <br />
                                <input className="form-control" data-hj-allow type="text" maxLength="100" value={item.value} name={`text${item.itemId}`} onChange={handleTextChange} id={`text${item.itemId}`} ></input>
                                {/* <p className="mt-2" style={{ color: "#FF0000" }}>{formErrors[item.itemId]}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        {item.itemType === "Text Area" && (
            <div className="row mt-4" key={item.itemId}>
                <div className="col-sm-12">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="form-group">
                                <label className="form-label mb-3" htmlFor={`text${item.itemId}`}>{item.textLabel}</label>
                                <br />
                                {/* Falta crear el onchange para el valor  */}
                                <input className="form-control" data-hj-allow type="text" maxLength="100" value={item.value} name={`text${item.itemId}`} onChange={handleTextChange} id={`text${item.itemId}`} rows="3"></input>
                                {/* <p className="mt-2" style={{ color: "#FF0000" }}>{formErrors[item.itemId]}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        {item.itemType === "Radio" && (
            <>
                <div className="row mt-4" key={`radioRow${item.itemId}`}>
                    <div className="col-sm-12" key={`radioCol${item.itemId}`}>
                        <div className="card shadow" key={`radioCard${item.itemId}`}>
                            <div className="card-body" key={`radioBody${item.itemId}`}>
                                <div className="form-group" key={`radioGroup${item.itemId}`}>
                                    <label key={`radiolabel${item.itemId}`} className="form-label mb-3" htmlFor={`text${item.itemId}`}>{item.textLabel}</label>
                                    <br />
                                    <div className="container mw-fit-content" key={`radioContainer${item.itemId}`}>
                                        <div className="col text-center" key={`radioCenter${item.itemId}`}>
                                            {item.options.map((option, optionIndex) => (
                                                <>
                                                    <div className="form-check-inline text-center align-top" key={`radio${item.itemId}${optionIndex}`}>
                                                        {/* Falta crear el onchange para el valor y checked  */}
                                                        <input className="btn-check" key={`input${item.itemId}${optionIndex}`} type="radio" checked={item.values[optionIndex]} onChange={handleRadioChange} name={`option${item.itemId}`} id={`option${item.itemId}${optionIndex}`} value={option} />
                                                        {item.emoji_pack !== "" ?
                                                            <label className="btn btn-emoji" style={{ padding: "0px" }} key={`label${item.itemId}${optionIndex}`} htmlFor={`option${item.itemId}${optionIndex}`} id={`option${item.itemId}${optionIndex}`}>  <img alt={option} className="emoji" src={`https://storage.googleapis.com/server-7bytes.appspot.com/Emoji%20Packs/Pack%20${item.emoji_pack.match(/\d+/)[0]}/Pack%20${item.emoji_pack.match(/\d+/)[0]}.${option}.png`}></img></label>
                                                            :
                                                            <label className="btn btn-outline-dark mt-1" key={`label${item.itemId}${optionIndex}`} htmlFor={`option${item.itemId}${optionIndex}`}>{option}</label>
                                                        }
                                                        {/* {item.options !== undefined &&
                                                beginningAndEnd(item.options)[0] === parseInt(option) ? <div className="mt-1"><i className="fa fa-thumbs-down fa-2x" style={{ color: "#f0eced" }} aria-hidden="true"></i></div> : beginningAndEnd(item.options)[1] === parseInt(option) && <div className="mt-1"><i className="fa fa-thumbs-up fa-2x" style={{ color: "#e7f0e7" }} aria-hidden="true"></i></div>
                                                  } */}
                                                    </div>
                                                    {forOtherOptions.includes(option.toLowerCase()) &&
                                                        <div className="form-group mt-2 small d-none" id={`DepentRadio${item.itemId}${optionIndex}`}>
                                                            <label className="form-label small mb-1" htmlFor={`DepentRadiooption${item.itemId}${optionIndex}`}>
                                                                <FormattedMessage
                                                                    id="survey.msg.radioForOthersField"
                                                                    defaultMessage="Por favor especifique su respuesta en el siguiente campo de texto."
                                                                />
                                                            </label>
                                                            {item.values[item.values.length - 1] !== true && item.values[item.values.length - 1] !== false ?
                                                                <input type="text"
                                                                    className="form-control"
                                                                    maxLength="50"
                                                                    data-hj-allow
                                                                    name={`DepentRadiooption${item.itemId}${optionIndex}`}
                                                                    id={`DepentRadiooption${item.itemId}${optionIndex}`}
                                                                    value={item.values[item.values.length - 1]}
                                                                    onChange={handleRadioTextChange}
                                                                    placeholder=""
                                                                    autoComplete="off" />
                                                                :
                                                                <input type="text"
                                                                    className="form-control"
                                                                    maxLength="50"
                                                                    data-hj-allow
                                                                    name={`DepentRadiooption${item.itemId}${optionIndex}`}
                                                                    id={`DepentRadiooption${item.itemId}${optionIndex}`}
                                                                    value={undefined}
                                                                    onChange={handleRadioTextChange}
                                                                    placeholder=""
                                                                    autoComplete="off" />
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {item.depentId.length > 0 && (item.depentId.some((e) => e.optionvalue === item.options[item.values.indexOf(true)])) && (
                    <DependentQuestions key={`test${item.options[item.values.indexOf(true)]}`} fields={fields} depentQues={item.depentId} openItem={item.itemId} activationValue={item.options[item.values.indexOf(true)]} />
                )}
            </>
        )}
        {item.itemType === "Check" && (
            <div className="row mt-4" key={item.itemId}>
                <div className="col-sm-12">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="form-group">
                                <label className="form-label mb-3" htmlFor="exampleCheckButton">{item.textLabel}</label>
                                <br />
                                {item.options.map((option, optionIndex) => (
                                    option.toLowerCase() !== "otro" && option.toLowerCase() !== "otros" && option.toLowerCase() !== "otr@s" && option.toLowerCase() !== "otra" && option.toLowerCase() !== "otras" ?
                                        <div className="form-check form-check-inline" key={`check${item.itemId}${optionIndex}`}>
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckChange} checked={item.values[optionIndex]} name={`option${item.itemId}`} id={`option${item.itemId}${optionIndex}`} value={option} />
                                            <label className="form-check-label" htmlFor={`option${item.itemId}${optionIndex}`}>{option}</label>
                                        </div>
                                        : <div key={`divcheck${item.itemId}${optionIndex}`} style={{ display: "inline" }}>
                                            <div className="form-check form-check-inline" key={`check${item.itemId}${optionIndex}`}>
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckChange} checked={item.values[optionIndex]} name={`option${item.itemId}`} id={`option${item.itemId}${optionIndex}`} value={option} />
                                                <label className="form-check-label" htmlFor={`option${item.itemId}${optionIndex}`}>{option}</label>
                                            </div>
                                            {item.values[optionIndex] ?
                                                <div className="form-group mt-1 small" id={`divOpt${item.itemId}${optionIndex}`}>
                                                    <label className="form-label small mb-1" htmlFor={`checkOpt${item.itemId}${openItem}`}>
                                                        <FormattedMessage
                                                            id="survey.msg.checkForOthersField"
                                                            defaultMessage="Por favor especifique (si son multiples valores debe separar cada uno por coma)"
                                                        />
                                                    </label>
                                                    <input type="text" data-hj-allow className="form-control" name={`checkOpt${item.itemId}${openItem}`} onChange={handleCheckTextChange} id={`checkOpt${item.itemId}${optionIndex}`} placeholder="Ejemplo: amigos/amigas, ahijado/ahijada, etc." value={checkTextValues[`checkOpt${item.itemId}${openItem}`]} autoComplete="off" />
                                                </div>
                                                :
                                                <div className="form-group mt-1 small d-none" id={`divOpt${item.itemId}${optionIndex}`}>
                                                    <label className="form-label small mb-1" htmlFor={`checkOpt${item.itemId}${openItem}`}>
                                                        <FormattedMessage
                                                            id="survey.msg.checkForOthersField"
                                                            defaultMessage="Por favor especifique (si son multiples valores debe separar cada uno por coma)"
                                                        />
                                                    </label>
                                                    <input type="text" data-hj-allow className="form-control" name={`checkOpt${item.itemId}${openItem}`} onChange={handleCheckTextChange} id={`checkOpt${item.itemId}${optionIndex}`} placeholder="Ejemplo: amigos/amigas, ahijado/ahijada, etc." value={checkTextValues[`checkOpt${item.itemId}${openItem}`]} autoComplete="off" />
                                                </div>
                                            }
                                        </div>
                                ))}
                                {/* <p className="mt-2" style={{ color: "#FF0000" }}>{formErrors[item.itemId]}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
    );
}
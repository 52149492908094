import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, IconButton, InputLabel, LinearProgress, MenuItem, Select, Tooltip, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MainDialogTitle } from '../../../ReuseComponents/MainDialogTitle'
import { useTheme } from '@mui/material/styles';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from '../../../../CustomHooks/useAxiosPrivate';
import useAuth from '../../../../CustomHooks/useAuth';
import NotificationsOffRoundedIcon from '@mui/icons-material/NotificationsOffRounded';
import { AddMails } from './AddMails';
import { FormattedMessage, useIntl } from 'react-intl';

export const ConfigAlerts = () => {
    const intl = useIntl();
    const { defaultCustomer } = useAuth();
    const query = new URLSearchParams(window.location.search);
    const survey = query.get("survey");
    const theme = useTheme();
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const queryClient = useQueryClient();
    const [sendEmails, setSendEmails] = useState([]);
    const [listName, setListName] = useState('');
    const [selectedEmail, setSelectedEmail] = useState();
    const [saving, setSaving] = useState(false);
    const [editMails, setEditMails] = useState(false);
    const [activatedAlerts, setActivatedAlerts] = useState(true);
    const [sendMethod, setSendMethod] = useState('alert');
    const [checkIfReport, setCheckIfReport] = useState(false);

    const { data: alerts } = useQuery({
        queryKey: ["alert", survey],
        queryFn: async () => {
            const response = await axiosPrivate.get(`alert/survey/${survey}?customer=${defaultCustomer}`);
            setSelectedEmail(response.data.email_list);
            setActivatedAlerts(response.data.alert);
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 1,
    });
    const { data: AllAlerts, isLoading: isLoadingAllAlerts } = useQuery({
        queryKey: ["AllAlert", survey],
        queryFn: async () => {
            const response = await axiosPrivate.get(`alert?customer=${defaultCustomer}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 1,
    });

    const { data: report, isLoading: isLoadingReport } = useQuery({
        queryKey: ["report", survey],
        queryFn: async () => {
            const response = await axiosPrivate.get(`report/survey/${survey}?customer=${defaultCustomer}`);
            setSelectedEmail(response.data.email_list);
            setActivatedAlerts(response.data.alert);
            return response.data
        },
        refetchOnWindowFocus: false,
        enabled: checkIfReport,
        retry: 1,
    });

    const { data: AllReports, isLoading: isLoadingAllReports } = useQuery({
        queryKey: ["AllReports", survey],
        queryFn: async () => {
            const response = await axiosPrivate.get(`report?customer=${defaultCustomer}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        enabled: (checkIfReport && !isLoadingReport),
        retry: 1,
    });

    const saveAlertEmails = useMutation({
        mutationFn: async (values) => {
            await setSaving(true);
            const response = await axiosPrivate.post(
                `/alert/survey/${survey}?customer=${defaultCustomer}`, values
            )
            return response.data;
        },
        onSuccess: (data, values) => {
            queryClient.invalidateQueries(["alert", survey], { exact: true });
            queryClient.invalidateQueries(["AllAlert", survey], { exact: true });
            //!Warning porque acabo de eliminar la lista y el nombre
            setSelectedListEmail(values.name);
            alert(intl.formatMessage({ id: "alerts.emails.list.new.success" }));
            //close modal
            setOpen(false);
        },
        onError: (error) => {
            if (error.response?.status === 400) {
                alert(intl.formatMessage({ id: "alerts.emails.list.error.duplicate" }));
            } else {
                alert(intl.formatMessage({ id: "alerts.emails.list.error.create" }));
            }
            console.error(error);
        },
        onSettled: () => {
            setSaving(false);
        }
    });

    const saveReportEmails = useMutation({
        mutationFn: async (values) => {
            await setSaving(true);
            const response = await axiosPrivate.post(
                `/report/survey/${survey}?customer=${defaultCustomer}`, values
            )
            return response.data;
        },
        onSuccess: (data, values) => {
            queryClient.invalidateQueries(["report", survey], { exact: true });
            queryClient.invalidateQueries(["AllReports", survey], { exact: true });
            //!Warning porque acabo de eliminar la lista y el nombre
            setSelectedListEmail(values.name);
            alert(intl.formatMessage({ id: "alerts.emails.list.new.success" }));
            //close modal
            setOpen(false);
        },
        onError: (error) => {
            if (error.response?.status === 400) {
                alert(intl.formatMessage({ id: "alerts.emails.list.error.duplicate" }));
            } else {
                alert(intl.formatMessage({ id: "alerts.emails.list.error.create" }));
            }
            console.error(error);
        },
        onSettled: () => {
            setSaving(false);
        }
    });

    const updateAlertEmails = useMutation({
        mutationFn: async (values) => {
            await setSaving(true);
            const response = await axiosPrivate.put(
                `/alert/survey/${survey}?customer=${defaultCustomer}`, values
            )
            return response.data;
        },
        onSuccess: (data, row) => {
            queryClient.invalidateQueries(["alert", survey], { exact: true });
            queryClient.invalidateQueries(["AllAlert", survey], { exact: true });

            alert(intl.formatMessage({ id: "alerts.emails.list.success" }));
        },
        onError: (error) => {
            alert(intl.formatMessage({ id: "alerts.emails.list.error.create" }));
            console.error(error);
        },
        onSettled: () => {
            setSaving(false);
        }
    });

    const updateReportEmails = useMutation({
        mutationFn: async (values) => {
            await setSaving(true);
            const response = await axiosPrivate.put(
                `/report/survey/${survey}?customer=${defaultCustomer}`, values
            )
            return response.data;
        },
        onSuccess: (data, row) => {
            queryClient.invalidateQueries(["report", survey], { exact: true });
            queryClient.invalidateQueries(["AllReports", survey], { exact: true });
            alert(intl.formatMessage({ id: "alerts.emails.list.success" }));
        },
        onError: (error) => {
            alert(intl.formatMessage({ id: "alerts.emails.list.error.create" }));
            console.error(error);
        },
        onSettled: () => {
            setSaving(false);
        }
    });

    const updateAlertEmailsList = useMutation({
        mutationFn: async (values) => {
            await setSaving(true);
            const response = await axiosPrivate.put(
                `/alert/${values.alertId}?customer=${defaultCustomer}`, values
            )
            return response.data;
        },
        onSuccess: (data, row) => {
            queryClient.invalidateQueries(["alert", survey], { exact: true });
            queryClient.invalidateQueries(["AllAlert", survey], { exact: true });
            alert(intl.formatMessage({ id: "alerts.emails.list.success" }));
        },
        onError: (error) => {
            alert(intl.formatMessage({ id: "alerts.emails.list.error.create" }));
            console.error(error);
        },
        onSettled: () => {
            setSaving(false);
        }
    });

    const updateReportEmailsList = useMutation({
        mutationFn: async (values) => {
            await setSaving(true);
            const response = await axiosPrivate.put(
                `/report/${values.alertId}?customer=${defaultCustomer}`, values
            )
            return response.data;
        },
        onSuccess: (data, row) => {
            queryClient.invalidateQueries(["report", survey], { exact: true });
            queryClient.invalidateQueries(["AllReports", survey], { exact: true });
            alert(intl.formatMessage({ id: "alerts.emails.list.success" }));
        },
        onError: (error) => {
            alert(intl.formatMessage({ id: "alerts.emails.list.error.create" }));
            console.error(error);
        },
        onSettled: () => {
            setSaving(false);
        }
    });

    const handleSubmit = async () => {
        try {
            let alertId;
            if (selectedListEmail === 'Add') {
                if (!sendEmails.length) {
                    alert(intl.formatMessage({ id: "no.email.selected" }));
                    return;
                }
                if (!listName) {
                    alert(intl.formatMessage({ id: "alerts.emails.list.error.empty" }));
                    return;
                }
                //REgex to validate name only letters and numbers and max 30 characters
                const nameRegex = /^[a-zA-Z0-9]{1,30}$/;
                if (!nameRegex.test(listName)) {
                    alert(intl.formatMessage({ id: "valid.list.name" }));
                    return;
                }
                const values = {
                    email_list: sendEmails.join(',').toLowerCase(),
                    name: listName,
                    alert: activatedAlerts,
                }
                if (!checkIfReport) {
                    await saveAlertEmails.mutate(values);
                } else {
                    await saveReportEmails.mutate(values);
                }
            } else if (editMails) {
                if (!sendEmails.length) {
                    alert(intl.formatMessage({ id: "no.email.selected" }));
                    return;
                }
                if (!window.confirm(intl.formatMessage({ id: "alerts.emails.list.warning.msg.edit" }))) return false;
                if (!checkIfReport) {
                    alertId = AllAlerts.find(alert => alert?.name === selectedListEmail)?.id;
                } else {
                    alertId = AllReports.find(report => report?.name === selectedListEmail)?.id;
                }
                const values = {
                    alertId,
                    emailList: sendEmails.join(',').toLowerCase(),
                    surveyId: survey,
                    alert: activatedAlerts,
                }
                if (!checkIfReport) {
                    await updateAlertEmailsList.mutate(values);
                } else {
                    await updateReportEmailsList.mutate(values);
                }
            }
            else {
                if (!editMails && (activatedAlerts !== alerts?.alert || activatedAlerts !== report?.alert)) {
                    //Validacion para cuando deselecciona el boton de editar correos
                    //Y saber si se han realizado cambios
                    const currentEmails = !checkIfReport ? AllAlerts.find(alert => alert?.name === selectedListEmail)?.email_list
                        : AllReports.find(report => report?.name === selectedListEmail)?.email_list;
                    const newEmails = sendEmails.join(',').toLowerCase();
                    if (currentEmails === newEmails) {
                        if (!checkIfReport) {
                            if (alerts?.name === selectedListEmail && activatedAlerts === alerts?.alert) {
                                return alert("No se han realizado cambios");
                            }
                        } else {
                            if ((report?.name === selectedListEmail) && activatedAlerts === report?.alert) {
                                return alert("No se han realizado cambios");
                            }
                        }
                    } else {
                        if (!window.confirm(intl.formatMessage({ id: "alerts.emails.list.warning.msg.edit" }))) return false;
                        if (!checkIfReport) {
                            alertId = AllAlerts.find(alert => alert?.name === selectedListEmail)?.id;
                        } else {
                            alertId = AllReports.find(report => report?.name === selectedListEmail)?.id;
                        }
                        const values = {
                            alertId,
                            emailList: sendEmails.join(',').toLowerCase(),
                            surveyId: survey,
                            alert: activatedAlerts,
                        }
                        if (!checkIfReport) {
                            return await updateAlertEmailsList.mutate(values);
                        } else {
                            return await updateReportEmailsList.mutate(values);
                        }
                    }
                }
                if (!checkIfReport) {
                    alertId = AllAlerts.find(alert => alert?.name === selectedListEmail)?.id;
                } else {
                    alertId = AllReports.find(report => report?.name === selectedListEmail)?.id;
                }
                //Actualiza a la lista de correos a una ya existente(solo cambia el id FK en Survey)
                const values = {
                    alertId,
                    alert: activatedAlerts,
                }
                if (!checkIfReport) {
                    await updateAlertEmails.mutate(values);
                } else {
                    await updateReportEmails.mutate(values);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const [selectedListEmail, setSelectedListEmail] = React.useState('Add');

    const handleChange = (event) => {
        setSelectedListEmail(event.target.value);
        if (!checkIfReport) {
            setSelectedEmail(AllAlerts.find(alert => alert.name === event.target.value)?.email_list);
        } else {
            setSelectedEmail(AllReports.find(report => report.name === event.target.value)?.email_list);
        }
    };
    useEffect(() => {
        if (alerts && AllAlerts) {
            //get the matching alert
            // const match = AllAlerts.find(alert => alert.email_list === alerts.email_list);
            // if (!match) {
            //     setSelectedListEmail('Add');
            //     return;
            // }
            setSelectedListEmail(AllAlerts.find(alert => alert.name === alerts?.name)?.name);
            setActivatedAlerts(alerts?.alert);
        }
        // else {
        //     setSelectedListEmail('Add');
        // }
    }, [alerts, AllAlerts])

    useEffect(() => {
        if (checkIfReport && AllReports && report) {
            setSelectedListEmail(AllReports.find(r => r.name === report?.name)?.name);
            setSelectedEmail(AllReports.find(r => r.name === report?.name)?.email_list);
        }
    }, [AllReports, checkIfReport, report]);

    const handleCheck = (event) => {
        setActivatedAlerts(event.target.checked);
    }

    const handleMethodChange = (event) => {
        setSendMethod(event.target.value);
        if (event.target.value === 'report') {
            setCheckIfReport(true);
            if (AllReports && report) {
                setActivatedAlerts(report?.alert);
            } else {
                setSelectedListEmail('Add');
                setSelectedEmail();
            }
        }
        else {
            setCheckIfReport(false);
            setActivatedAlerts(alerts?.alert);
            if (AllAlerts && alerts) {
                setSelectedListEmail(AllAlerts.find(alert => alert.name === alerts?.name)?.name);
                setSelectedEmail(AllAlerts.find(alert => alert.name === alerts?.name)?.email_list);
            } else {
                setSelectedListEmail('Add');
                setSelectedEmail();
            }
        }
    }

    return (
        <div>
            <Tooltip title={"Settings"} arrow>
                <IconButton
                    size="large"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => setOpen(!open)}
                    color="inherit"
                >
                    {alerts && alerts.alert
                        ? <NotificationsRoundedIcon />
                        : <NotificationsOffRoundedIcon />
                    }
                    {/* <NotificationsRoundedIcon /> */}
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
            >
                <MainDialogTitle id="responsive-dialog-title" className="text-center" onClose={handleClose}>
                    <FormattedMessage
                        id="alerts.subtitle"
                        defaultMessage={`Envio de alertas`}
                    />
                    {/* <hr /> */}
                </MainDialogTitle>
                {/* <DialogTitle>
                    
                    <hr />
                </DialogTitle> */}
                <DialogContent>
                    <FormControl fullWidth className='mt-3 mb-3'>
                        <InputLabel id="SelectList">
                            <FormattedMessage
                                id="select.config.info"
                            />
                        </InputLabel>
                        <Select
                            labelId="SelectList"
                            id="selectList"
                            value={sendMethod}
                            label={intl.formatMessage({ id: "select.config.info" })}
                            onChange={handleMethodChange}
                        >
                            <MenuItem value="alert">
                                <FormattedMessage
                                    id="alerts.title"
                                />
                            </MenuItem>
                            <MenuItem value="report">
                                <FormattedMessage
                                    id="reports.title"
                                />
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <DialogContentText className='mb-3' sx={{ textAlign: "justify" }}>
                        {checkIfReport ?
                            <FormattedMessage
                                id="reports.description"
                            />
                            :
                            <FormattedMessage
                                id="alerts.description"
                            />
                        }
                    </DialogContentText>
                    {isLoadingAllAlerts || (isLoadingAllReports && checkIfReport) ? <LinearProgress /> :
                        <>
                            <FormControlLabel
                                className='mb-3'
                                control={
                                    <Checkbox
                                        checked={activatedAlerts}
                                        onChange={handleCheck}
                                    />
                                }
                                label={checkIfReport ?
                                    intl.formatMessage({ id: "activate.reports" })
                                    :
                                    intl.formatMessage({ id: "activate.alerts" })
                                }
                            />
                            <FormControl fullWidth className='mb-3'>
                                <InputLabel id="demo-simple-select-label">
                                    <FormattedMessage
                                        id="alerts.emails.list"
                                    />
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedListEmail}
                                    label={intl.formatMessage({ id: "alerts.emails.list" })}
                                    onChange={handleChange}
                                >
                                    {
                                        sendMethod === 'alert' ?
                                            AllAlerts?.map((alert) => (
                                                <MenuItem key={alert?.name} value={alert?.name}>- {alert?.name}</MenuItem>
                                            )) :
                                            AllReports?.map((report) => (
                                                <MenuItem key={report?.name} value={report?.name}>- {report?.name}</MenuItem>
                                            ))
                                    }
                                    <MenuItem value="Add">
                                        <em>+ <FormattedMessage
                                            id="alerts.emails.add"
                                        />
                                        </em>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <hr />
                            <AddMails
                                key={selectedListEmail}
                                alertEmail={selectedEmail}
                                sendEmails={sendEmails}
                                setSendEmails={setSendEmails}
                                listName={listName}
                                setListName={setListName}
                                selectedListEmail={selectedListEmail}
                                editMails={editMails}
                                setEditMails={setEditMails}
                            />
                            {saving &&
                                <>
                                    <Box className='mt-3' sx={{ width: '100%' }}>
                                        <LinearProgress />
                                    </Box>
                                    <em className='center text-center mt-2' style={{ color: "gray", width: '100%' }}>
                                        <FormattedMessage
                                            id="alerts.emails.list.saving"
                                        />
                                    </em>
                                </>
                            }
                        </>
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} disabled={saving} variant="contained" color='error'>
                        <FormattedMessage
                            id="cancel"
                        />
                    </Button>
                    {
                        saving ?
                            <Button onClick={handleSubmit} variant="contained" disabled type="submit">
                                <FormattedMessage
                                    id="saving"
                                />
                            </Button> :
                            <Button onClick={handleSubmit} variant="contained" type="submit">
                                <FormattedMessage
                                    id="save"
                                />
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}

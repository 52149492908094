import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
// import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { HelmetProvider } from 'react-helmet-async';

const { NODE_ENV, REACT_APP_SENTRY_DSN } = process.env;

if (NODE_ENV === "production") {
  disableReactDevTools();
  // Sentry.init({
  //   dsn: REACT_APP_SENTRY_DSN,
  //   integrations: [new BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 0.8,
  // })
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      "TagError: adsbygoogle.push() error: All ins elements in the DOM with class=adsbygoogle already have ads in them.",
      "UnhandledRejection",
      "Non-Error promise rejection captured",
      "UnhandledRejection: Non-Error promise rejection captured with value: undefined",
      "UnhandledRejection: Non-Error promise rejection captured with value: null"
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
      })
    ],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <HelmetProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </HelmetProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
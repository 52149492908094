import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../CustomHooks/useFetch";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import template from "../../../Domains/customers.json";
import axios from "../../../api/axios";
import MetaDecorator from "../../UtilsComponents/MetaDecorator";
import { languageContext } from "../../../context/languageContext";
import { useQuery } from "@tanstack/react-query";
import { RestaurantPostSurvey } from "./MainSurveyComponensts/RestaurantPostSurvey";
import { LoadingComponent } from "./MainSurveyComponensts/LoadingComponent";
import { SevenBytesPostSurvey } from "./MainSurveyComponensts/SevenBytesPostSurvey";

export const MainSurvey = () => {
    let backColor = "#051B29";
    const fontPostSurveyColor = "#072322"
    const navigate = useNavigate();
    const { REACT_APP_SERVER } = process.env;
    let { id, customer } = useParams();
    customer = customer.toLowerCase();
    const language = useContext(languageContext);
    const [isPending, setIsPending] = useState(true);
    const refreshTime = 1000 * 60 * 60 * 24;

    const { data: customerStyles, isLoading: isLoadingStyles } = useQuery({
        queryKey: [customer, "Style"],
        queryFn: async () => {
            const response = await axios.get(`customer/info/${customer}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: refreshTime,
    });

    const { data: survey, error, isLoadingQuestions } = useFetch(`${REACT_APP_SERVER}userquestions/${id}?customer=`, customer, language.locale);
    const { url, logo, headerBackground, bodyBackground, fontColor, category } = customerStyles ? customerStyles[customer] : template["7bytes"];

    const { data: customers, isLoading: isLoadingCustomers, error: customerError } = useQuery({
        queryKey: [customer, "info"],
        queryFn: async () => {
            const response = await axios.get(`customer/user/${id}?customer=${customer}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: refreshTime,
    });

    useEffect(() => {
        setIsPending(isLoadingQuestions || isLoadingCustomers || isLoadingStyles);
    }, [isLoadingQuestions, isLoadingCustomers, isLoadingStyles]);

    //Prevent user from going back
    useEffect(() => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const returnedComponent = [
        <MetaDecorator
            key={0}
            title={customer?.includes("20Mission".toLowerCase()) ? `Main Survey | 20Mission Cerveza` : `Main Survey | ${customer}`}
            description={"Para nosotros es muy importante conocer tu opinión sobre nuestros productos y servicios. Por favor, ayúdanos a mejorar respondiendo esta encuesta."}
            imageUrl={"/images/7Bytes.png"}
            imageAlt={"7Bytes"}
            path={window.location.pathname}
        />
    ];

    if (isPending || error) {
        return (
            <LoadingComponent errorMsg={error} />
        );
    } else if (customerError) {
        if (customerError?.response?.status === 404) {
            returnedComponent.push(
                <LoadingComponent
                    key={"error404"}
                    errorMsg={
                        <FormattedMessage
                            id="survey.customer.id.survey.error"
                        />
                    }
                />
            )
        } else {
            returnedComponent.push(
                <LoadingComponent
                    key={"error500"}
                    errorMsg={
                        <FormattedMessage
                            id="survey.server.error"
                        />
                    }
                />
            )
        }
    } else if (survey) {
        if (survey.status === "0") {
            returnedComponent.push(
                <LoadingComponent
                    key={"error0"}
                    errorMsg={
                        <FormattedMessage
                            id="error.sending.survey.msg"
                            defaultMessage={'Error al enviar la encuesta'}
                        />
                    }
                />
            )
        }
        else if (survey.status === "1") {
            returnedComponent.push(
                <LoadingComponent
                    key={"error1"}
                    errorMsg={
                        <FormattedMessage
                            id="survey.notSent.msg"
                            defaultMessage={'La encuesta aún no ha sido enviada'}
                        />
                    }
                />
            )
        }
        else if (survey.status === "2" || survey.status === "3") {
            if (customers) {
                navigate(
                    `/survey/${customer}/${id}`,
                    {
                        state: {
                            survey,
                            REACT_APP_SERVER,
                            customerStyles
                        }
                    }
                );
            }
        }
        else if (survey.status === "4") {
            if (category?.includes("restaurant") || category?.includes("hotel") || category?.includes("taller")) {
                returnedComponent.push(
                    <RestaurantPostSurvey
                        key={"restaurantPostSurvey"}
                        url={url}
                        logo={logo}
                        headerBackground={headerBackground}
                        bodyBackground={bodyBackground}
                        fontColor={fontColor}
                        category={category}
                        survey={survey}
                    />
                )
            } else {
                returnedComponent.push(
                    <SevenBytesPostSurvey
                        key={"sevenBytesPostSurvey"}
                        survey={survey}
                        fontPostSurveyColor={fontPostSurveyColor}
                        backColor={backColor}
                    />
                )
            }
        }
    }
    return returnedComponent;
}
import { Skeleton, Tooltip, useMediaQuery } from '@mui/material';
import React from 'react';
import { Treemap } from 'recharts';
import { CardElementComponent } from '../UtilsComponents/CardElementComponent';
import { FormattedMessage } from 'react-intl';
import { useResizeChart } from './ResizeCharts';

const CustomizedContent = ({ root, depth, x, y, width, height, index, payload, colors, rank, name }) => {
    return (
        <Tooltip title={`${name} ${root.children[index].value}`} placement="top">
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    style={{
                        fill: depth < 2 ? colors[Math.floor((index / root.children.length) * 6)] : '#ffffff00',
                        stroke: '#fff',
                        strokeWidth: 2 / (depth + 1e-10),
                        strokeOpacity: 1 / (depth + 1e-10),
                    }}
                />
                {depth === 1 ? (
                    null
                    // <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
                    //     {name}
                    // </text>
                ) : (
                    <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fontSize={12}>
                        {name}
                    </text>
                )}
                {depth === 1 ? (
                    <text x={x + 4} y={y + 18} fill="#fff" fontSize={16} fillOpacity={0.9}>
                        {indexName[root.children[index].name]}
                    </text>
                ) : null}
            </g>
        </Tooltip>
    );
};
const indexName = {
    'positive': <FormattedMessage id="dashboard.words.positive" />,
    'neutral': <FormattedMessage id="dashboard.words.neutral" />,
    'negative': <FormattedMessage id="dashboard.words.negative" />,
};
const COLORS = ['#03989e', '#d7b00b', '#be4b48'];
export const TreeMapEntities = ({ data, isLoading, isError, error, chartName }) => {
    const matches = useMediaQuery('(min-width:490px)');
    const chartWidth = useResizeChart(chartName);
    const checkDataLength = data?.reduce((count, item) => count + (item.children.length > 0 ? 1 : 0), 0) ?? 1;
    const divNumber = checkDataLength < 2 ? 1.5 : matches ? 4 : 4.5;

    return (
        <CardElementComponent sx={{ height: "346px" }} >
            <div sx={{ width: "100%" }}>
                <h2 className='text-center' style={{ paddingTop: "10px" }}>
                    <FormattedMessage id="dashboard.words.title" />
                </h2>
                {(isLoading) ?
                    <Skeleton animation="wave" variant="rounded" height={280} width={chartWidth / divNumber + 30} />
                    :
                    isError ?
                        <h4 className='text-center' style={{ color: error?.response?.status === 404 ? "orange" : "red" }}>
                            {error?.response?.status === 404 ?
                                <FormattedMessage id="dashboard.no.data" />
                                :
                                <FormattedMessage id="dashboard.survey.info.error" />}
                        </h4>
                        :
                        <div className='row inline' style={{ paddingRight: 0 }}>
                            {Object.keys(data).map((key, index) => {
                                return (
                                    data[index].children.length > 0 ?
                                        <Treemap
                                            style={{ right: "12px" }}
                                            key={index}
                                            width={chartWidth / divNumber + 25}
                                            height={280}
                                            data={[data[index]]}
                                            dataKey="size"
                                            stroke="#fff"
                                            fill="#8884d8"
                                            content={<CustomizedContent colors={[COLORS[index]]} />}
                                        />
                                        : null
                                )
                            })}
                        </div>
                }
            </div>
        </CardElementComponent>
    );
}

import { FormattedMessage } from "react-intl"

export const RadioAnswer = ({ options = [], id, emojiPack = [] }) => {
    const acceptedEmojiAnswers = ["1", "2", "3", "4", "5"];
    return (
        options.length > 0 ?
            options.map((option, questionIndex) => {
                return (
                    <div className="form-check-inline text-center align-top" key={`radio${id}${questionIndex}`}>
                        <input className="btn-check" type="radio" name={`radio${id}`} id={`radio${id}${questionIndex}`} value={option} />
                        {
                            !(emojiPack === null) && acceptedEmojiAnswers.includes(option) ?
                                <label className="btn btn-emoji" style={{ padding: "0px" }} htmlFor={`radio${id}${questionIndex}`}>  <img alt={option} className="emoji" src={`https://storage.googleapis.com/server-7bytes.appspot.com/Emoji%20Packs/Pack%20${emojiPack.match(/\d+/)[0]}/Pack%20${emojiPack.match(/\d+/)[0]}.${option}.png`}></img></label>
                                :
                                <label className="btn btn-outline-dark mt-1" htmlFor={`radio${id}${questionIndex}`} >{option}</label>
                        }
                    </div>
                )
            })
            :
            <h6 style={{ color: "red" }}><FormattedMessage id="survey.view.answers.error" /></h6>
    )
}

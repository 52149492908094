import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate'
// import { Navbar } from '../ReuseComponents/Navbar'
import MetaDecorator from '../UtilsComponents/MetaDecorator'
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material'
import { TransitionDown, topCenterPosition } from '../UtilsComponents/SnackBarTransitions'

export const CreateCustomer = () => {

    const intl = useIntl();
    const axiosPrivate = useAxiosPrivate();
    //Regex only letters and numbers
    const regex = /^[a-zA-Z0-9]+$/
    const initialValues = {
        name: '',
        db: [],
        plans: [],
        environment: {
            development: false,
            production: false,
        },
    }

    const [transition, setTransition] = React.useState(undefined);
    const [snackBarState, setSnackBarState] = React.useState(topCenterPosition);
    const { vertical, horizontal, open } = snackBarState;

    const [values, setValues] = useState(initialValues);
    const { name, db, plans, environment } = values;
    const [newDbName, setNewDbName] = useState("");

    const [selectedPlan, setSelectedPlan] = useState();
    const [selectedDb, setSelectedDb] = useState();

    const [errMsg, setErrMsg] = useState('');
    const [dbErrMsg, setDbErrMsg] = useState('');
    const [dbMsg, setDbMsg] = useState('');

    const [isSending, setIsSending] = useState(false);

    const [count, setCount] = useState(0);

    const getDbs = async () => {
        try {
            const res = await axiosPrivate.get('db');
            setValues(v => ({ ...v, db: res.data }));
        } catch (e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        document.body.style.backgroundColor = "#096a6e";
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const getPlans = async () => {
            try {
                const res = await axiosPrivate.get('plan');
                setValues(v => ({ ...v, plans: res.data }));
                getDbs();
            } catch (e) {
                console.error(e.message);
            }
        }
        getPlans();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [name]);

    const handleSelectEnv = (e) => {
        const { value } = e.target;
        //set all values to false
        const newObj = Object.keys(environment).reduce((accumulator, key) => {
            return { ...accumulator, [key]: false };
        }, {});
        //check if the value key is already in the array
        if (Object.keys(environment).includes(value)) {
            //save the value in the state and put the other to false
            setValues({
                ...values,
                environment: {
                    ...newObj,
                    [value]: !environment[value],
                },
            });
            setErrMsg('');
        } else {
            setValues({
                ...values,
                environment: {
                    ...newObj,
                },
            });
        }
    }

    const handleSelectPlan = (e) => {
        const { value } = e.target;
        if (!isNaN(value)) {
            setErrMsg('');
        }
        setSelectedPlan(value);
    }

    const handleSelectDb = (e) => {
        const { value } = e.target;
        if (!isNaN(value)) {
            setErrMsg('');
        }
        //save the name of the db in the state

        setSelectedDb(v => {
            return db.find(db => db.id === (value))
        });
    }

    const validations = () => {

        if (count === 0) setCount(1);
        if (!regex.test(name)) {
            //!! Hay un problema porque como queda guardado el mensaje en el primer idioma 
            //!! al momento de cambiar el idioma no cambia el mensaje porque el estado no cambia
            return (intl.formatMessage({ id: "create.customer.error.name" }));
        } else if (isNaN(selectedDb?.id)) {
            return (intl.formatMessage({ id: "create.customer.error.db" }));
        }
        else if (isNaN(selectedPlan)) {
            return (intl.formatMessage({ id: "create.customer.error.plan" }));
        }
        else if (Object.values(environment).filter((env) => env === true).length === 0) {
            return (intl.formatMessage({ id: "create.customer.error.env" }));
        }
    }

    useEffect(() => {
        //* Para hacer que cambie el mensaje cuando se cambia el idioma
        if (count === 1) {
            const getErrMsg = validations();
            if (getErrMsg) return setErrMsg(getErrMsg);
        }
        // eslint-disable-next-line
    }, [intl]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSending(true);
        const getErrMsg = validations();
        if (getErrMsg) return setErrMsg(getErrMsg);
        try {
            const response = await axiosPrivate.post('customer', {
                name,
                database_id: selectedDb.id,
                datebase_name: selectedDb.database_name,
                plan_id: selectedPlan,
                environment: Object.keys(environment)[Object.values(environment).indexOf(true)],
            });
            if (!response.data?.text) {
                await setValues({ ...values, name: '' });
                setTransition(() => TransitionDown);
                setSnackBarState({ ...snackBarState, open: true });
            }
        } catch (error) {
            console.error(error);
            if (error.response?.status === 409) {
                setErrMsg(intl.formatMessage({ id: "create.customer.error.name.exits" }));
            } else {
                setErrMsg(error.message);
            }
        } finally {
            setIsSending(false);
        }
    }

    const handleSubmitDb = async (e) => {
        e.preventDefault();
        if (newDbName === '') {
            return setDbErrMsg(intl.formatMessage({ id: "create.db.error.name.empty" }));
        } else if (!regex.test(newDbName)) {
            return setDbErrMsg(intl.formatMessage({ id: "create.db.error.name" }));
        }
        try {
            const response = await axiosPrivate.post('db', {
                database_name: newDbName,
            });
            if (response.data?.text) {
                setDbMsg(
                    intl.formatMessage({ id: "create.db.msg.success" })
                );
                setNewDbName("");
                getDbs();
            }
        } catch (error) {
            console.error(error);
            if (error.response?.status === 409) {
                setDbErrMsg("Ya existe");
            } else {
                setDbErrMsg(error.message);
            }
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarState({ ...snackBarState, open: false });
    };

    return (
        <div className="main " style={{
            padding: "2rem 0 0rem 0",
            minHeight: "80vh"
        }}>
            {/* <Navbar /> */}
            <MetaDecorator
                title={intl.formatMessage({ id: "create.customer.msg.title" })}
                description={"Entendiendo al consumidor de una forma. Amigable Efectiva y Relevante"}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"7Bytes - Estudio de mercado continuo"}
                path={window.location.pathname}
            />
            <div className="container ">
                <div className="signup-content">
                    <div className="text-center " >
                        <h2 className="fw-normal "
                            style={{
                                color: "whitesmoke",
                                letterSpacing: 1,
                                fontSize: 40
                            }}>
                            <strong>
                                <FormattedMessage
                                    id="create.customer.msg.title"
                                    defaultMessage={`Crear Nuevo Cliente`}
                                />
                            </strong>
                        </h2>
                    </div>
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <FormattedMessage
                            id="create.initial.msg"
                            defaultMessage={`Por favor recuerda ingresar todos los campos`}
                        />
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    {errMsg !== "" &&
                        <div className="alert alert-danger d-flex align-items-center" role="alert">
                            <svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>&nbsp;
                            <div>
                                {errMsg}
                            </div>
                        </div>
                    }
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        TransitionComponent={transition}
                        anchorOrigin={{ vertical, horizontal }}
                        key={vertical + horizontal}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            <FormattedMessage
                                id="create.customer.msg.success"
                            />
                        </Alert>
                    </Snackbar>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-2 mb-3 ">
                            <div className="col-md-7">
                                <div className="form-floating mb-2">
                                    <input
                                        type="name"
                                        className="form-control"
                                        style={{ borderRadius: "10px" }}
                                        id="inputname"
                                        autoComplete="off"
                                        placeholder="name"
                                        required
                                        onChange={(e) => {
                                            setValues({ ...values, name: e.target.value });
                                        }}
                                        value={name}
                                    />
                                    <label >
                                        <i className="fa fa-user" style={{ color: "gray" }} /> &nbsp;
                                        <FormattedMessage
                                            id="create.customer.name"
                                            defaultMessage={`Nombre del cliente`}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="form-floating" >
                                    <select className="form-select" onChange={handleSelectDb} style={{ borderRadius: "10px" }} id="Selectdb" aria-label="Floating label select example">
                                        <option defaultValue>
                                            {intl.formatMessage({ id: "create.customer.db.select" })}
                                        </option>
                                        {db.map((d) => (
                                            <option key={d.id} value={d.id}>
                                                {d.database_name}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="Selectdb">
                                        <i className="fa fa-database" style={{ color: "gray" }} /> &nbsp;
                                        <FormattedMessage
                                            id="create.customer.db"
                                            defaultMessage={`Base de datos `}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row g-2 mb-3">
                            <div className="col-md-7">
                                <div className="form-floating mt-2">
                                    <h4 style={{ color: "whitesmoke" }}>
                                        <FormattedMessage
                                            id="create.db.question"
                                            defaultMessage={`La base de datos no existe, ¿Desea crearla?`}
                                        />
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md">
                                <button type="button" className="glow-on-hover" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <i className="fa fa-plus" style={{ color: "whitesmoke" }} data-bs-toggle="modal" data-bs-target="#exampleModal" /> &nbsp;
                                    <FormattedMessage
                                        id="create.db.btn"
                                        defaultMessage={`Crear nueva base de datos`}
                                    />
                                </button>
                                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                    <FormattedMessage
                                                        id="create.db.msg.title"
                                                        defaultMessage={`Crear Una Nuevo Base de datos`}
                                                    />
                                                </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                {dbErrMsg !== "" &&
                                                    <div className="alert alert-danger d-flex align-items-center" role="alert">
                                                        <svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>&nbsp;
                                                        <div>
                                                            {dbErrMsg}
                                                        </div>
                                                    </div>
                                                }
                                                {dbMsg !== "" &&
                                                    <div className="alert alert-success d-flex align-items-center" role="alert">
                                                        <svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>&nbsp;
                                                        <div>
                                                            {dbMsg}
                                                        </div>
                                                    </div>
                                                }
                                                <div className="">
                                                    <div className="form-floating ">
                                                        <input
                                                            className="form-control"
                                                            style={{ borderRadius: "10px" }}
                                                            id="inputdbname"
                                                            autoComplete="off"
                                                            placeholder="name"
                                                            onChange={(e) => {
                                                                setDbErrMsg('');
                                                                setDbMsg('');
                                                                setNewDbName(e.target.value);
                                                            }}
                                                            value={newDbName}
                                                        />
                                                        <label >
                                                            <i className="fa fa-database" style={{ color: "gray" }} /> &nbsp;
                                                            <FormattedMessage
                                                                id="create.db.name"
                                                                defaultMessage={`Nombre de la base de datos`}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" onClick={handleSubmitDb} className="btn btn-primary">
                                                    <FormattedMessage
                                                        id="create.user.btn"
                                                        defaultMessage={`Crear`}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 mb-3 ">
                            <div className="col-md">
                                <div className="form-floating" >
                                    <select className="form-select" onChange={handleSelectPlan} style={{ borderRadius: "10px" }} id="Selectplans" aria-label="Floating label select example">
                                        <option defaultValue>
                                            {intl.formatMessage({ id: "create.customer.plan.select" })}
                                        </option>
                                        {plans.map((plans) => (
                                            <option key={plans.id} value={plans.id}>
                                                {plans.plan_name}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="Selectplans">
                                        <i className="fa fa-tasks" style={{ color: "gray" }} /> &nbsp;
                                        <FormattedMessage
                                            id="create.customer.plan"
                                            defaultMessage={`Plan`}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="form-floating" >
                                    <select className="form-select" onChange={handleSelectEnv} style={{ borderRadius: "10px" }} aria-label="Floating label select example">
                                        <option defaultValue>
                                            {intl.formatMessage({ id: "create.customer.env.select" })}
                                        </option>
                                        {Object.keys(environment).map((key, index) => (
                                            <option key={index} value={key}>
                                                {key}
                                            </option>
                                        ))}
                                    </select>
                                    <label>
                                        <svg style={{ width: "18px", margin: "0px 0px 3px 0px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 21 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" /></svg> &nbsp;
                                        <FormattedMessage
                                            id="create.customer.env"
                                            defaultMessage={`Ambiente`}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group ">
                            {isSending ?
                                <button type="submit" disabled name="submit" id="submit" className="form-submit">
                                    <FormattedMessage
                                        id="creating.user.btn"
                                        defaultMessage={`Creando Cliente...`}
                                    />
                                </button>
                                :
                                <button type="submit" name="submit" id="submit" className="form-submit">
                                    <FormattedMessage
                                        id="create.user.btn"
                                        defaultMessage={`Crear`}
                                    />
                                </button>
                            }
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export function invertColorAndDarken(hex, darkenFactor = 0.5) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');
    
    // Parse the r, g, b values
    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    
    // Invert each value by subtracting it from 255
    r = 255 - r;
    g = 255 - g;
    b = 255 - b;
    
    // Darken the color by reducing the brightness
    r = Math.floor(r * darkenFactor);
    g = Math.floor(g * darkenFactor);
    b = Math.floor(b * darkenFactor);
    
    // Convert the values back to hex
    let darkenedInvertedColor = '#' + 
        ('0' + r.toString(16)).slice(-2) + 
        ('0' + g.toString(16)).slice(-2) + 
        ('0' + b.toString(16)).slice(-2);
    
    return darkenedInvertedColor;
}
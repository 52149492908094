import { FormattedMessage } from "react-intl";

export const useSurveyValidations = () => {

    const validateRequired = (required, answer) => {
        return (required && answer.length === 0) ?
            <FormattedMessage id="survey.error.enterAnAnswer" defaultMessage="Por favor ingrese una respuesta" />
            :
            "";
    }

    const textValidation = (answer, textFormat) => {
        let msg = ''
        if (answer === "") return msg;
        const formatedAnswer = answer?.replace(/\s\s+/g, ' ').trim();
        if (textFormat === "only text") {
            if (!validateOnlyText(formatedAnswer)) {
                msg = <FormattedMessage id="survey.error.onlyText" defaultMessage="Por favor ingrese solo texto" />;
            }
        }
        return msg;
    }

    const validateOnlyOneWord = (answer, textLabel) => {
        const formatedAnswer = answer?.replace(/\s\s+/g, ' ').trim();
        const checkOneWorld =
            textLabel.toLowerCase().includes("una sola palabra") ||
            textLabel.toLowerCase().includes("sola palabra") ||
            textLabel.toLowerCase().includes("one word") ||
            textLabel.toLowerCase().includes("single word")
        if (!checkOneWorld) return "";
        if (answer === "") return "";
        if (formatedAnswer?.length > 30) {
            return <FormattedMessage id="survey.error.longWord" defaultMessage="Por favor ingrese una palabra mas corta" />;
        }
        const regexText = /^[A-Za-zÁáÉéÍíÓóÚúÑñ]+$/;
        if (!regexText.test(formatedAnswer)) {
            return <FormattedMessage id="survey.error.oneWord" defaultMessage="Por favor ingrese una sola palabra, sin números, ni puntos, ni comas, ni espacios" />;
        }
        return "";
    }
    const validateOnlyText = (text) => {
        const regexText = /^[A-Za-zÁáÉéÍíÓóÚúÑñ.\s]+$/;
        return regexText.test(text);
    }

    //Main function to validate the answers
    const handleValidation = (question, answers, updateAnswers) => {
        const answer = answers.filter(answer => answer.survey_item_id === question.itemId);
        let result = validateRequired(question?.required, answer);
        if (result !== '') {
            return result;
        }
        if (question?.itemType === "Text Box" || question?.itemType === "Text Area") {
            result = textValidation(answer[0]?.answer_value, question?.valueFormat);
            if (result !== '') {
                return result;
            }
        }
        if (question?.itemType === "Text Box") {
            result = validateOnlyOneWord(answer[0]?.answer_value, question?.textLabel);
            if (result !== '') {
                return result;
            } else if (answer[0]?.answer_value !== undefined) {
                //Actuliza la respuesta con el valor sin espacios en blanco
                updateAnswers(question.itemId, answer[0]?.answer_value?.replace(/\s\s+/g, ' ').trim());
            }
        }
        return '';
    }

    return {
        textValidation,
        validateRequired,
        validateOnlyText,
        validateOnlyOneWord,
        handleValidation
    }; // Exportar variables
}

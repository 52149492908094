import { FormattedMessage } from "react-intl"

export const CheckAnswer = ({ options = [], id }) => {
    return (
        options.length > 0 ?
            options.map((option, questionIndex) => {
                return (
                    <div className="form-check form-check-inline" key={`check${id}${questionIndex}`}>
                        <input className="form-check-input" type="checkbox" id={`check${id}${questionIndex}`} />
                        <label className="form-check-label" htmlFor={`check${id}${questionIndex}`} >{option}</label>
                    </div>
                )
            })
            :
            <h6 style={{ color: "red" }}><FormattedMessage id="survey.view.answers.error" /></h6>
    )
}
export const CapitializeFirstLetters = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).replace("_", " ");
};

export function checkArrayCommonValues(array1, array2) {
    try {
        // Convert arrays to sets for faster lookup
        const set1 = new Set(array1);
        // Check if any element of array2 exists in set1
        return array2.some(item => set1.has(item));
    } catch (error) {
        return false;
    }
}

import { invertColorAndDarken } from "../UtilsComponents/GetInvertColor";

export const Footer = (props) => {
    const { bodyBackground, fontColor = "#000000" } = props;
    var year = new Date().getFullYear();
    const colorInverted = invertColorAndDarken(fontColor);
    return (
        <td className="tdFooter1" bgcolor={bodyBackground} align="center">
            <table className="card-table" border="0" cellPadding="3" cellSpacing="0" width="100%">
                <tbody>
                    <tr>
                        <td className="tdFooter2" bgcolor={bodyBackground} align="center" >
                            <p className="pFooter">
                                <strong style={{ color: colorInverted }}>OCXCI - 7Bytes © {year}</strong>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    )
}
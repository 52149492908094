import { Box, Button, Card, CardContent, CardMedia, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate';
import { SnackBarComponent } from '../UtilsComponents/SnackBarComponent';
import { topCenterPosition, TransitionDown } from '../UtilsComponents/SnackBarTransitions';
import useAuth from '../../CustomHooks/useAuth';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const Communication = () => {
    const intl = useIntl();
    const { UserInfo, defaultCustomer } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState();
    const [initialState, setInitialState] = useState();
    const queryClient = useQueryClient();
    const noChanges = ["link", "qr"];
    const { data: resData, isLoading: loading } = useQuery({
        queryKey: ["Communication", defaultCustomer],
        queryFn: async () => {
            const response = await axiosPrivate.get(`communication/customer/${UserInfo?.email}?customer=${defaultCustomer}`);
            setData(response.data);
            setInitialState(response.data);
            return response.data;
        },
        refetchOnWindowFocus: false,
        retry: false,
    });
    const updateSurveyMutation = useMutation({
        mutationFn: async ({ values }) => {
            const response = await axiosPrivate.put(`communication/name/${defaultCustomer}`, values);
            return response.data;
        },
        onSuccess: (data, { values }) => {
            setData(values);
            setInitialState(values);
            setHasChanges(true);
            setMsg(intl.formatMessage({ id: "communication.update" }));
            setOpenSnackBar(true);
            queryClient.invalidateQueries(["Communication", defaultCustomer], { exact: true });
        },
        onError: (error) => {
            setColor("error");
            setOpenSnackBar(true);
            setMsg(intl.formatMessage({ id: "communication.update.error" }));
        }
    });

    const [openSnackBar, setOpenSnackBar] = useState(false);

    const [hasChanges, setHasChanges] = useState(true);
    const [color, setColor] = useState("success");
    const [msg, setMsg] = useState("");

    const miss = { "1": 1, "2": 1, "3": 1, "5": 1, "6": 1, "7": 1, "8": 1 }

    useEffect(() => {
        document.body.style.backgroundColor = "#66b7ba";
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (data) {
            if (JSON.stringify(data) === JSON.stringify(initialState)) {
                setHasChanges(true);
            }
        }
    }, [initialState, data])

    const handleCheck = (e) => {
        if (e.target.checked && !noChanges.includes(e.target.name)){
            alert(intl.formatMessage({ id: "communication.alert.msg" }))
        }
        setHasChanges(false);
        setData({ ...data, [e.target.name]: e.target.checked });
    }

    const clearValues = () => {
        setData(initialState);
        setHasChanges(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleSubmit = async () => {
        updateSurveyMutation.mutate({ values: data });
    }

    return (
        <div className="container light-style flex-grow-1 container-p-y mb-3">
            <div className="card overflow-hidden" style={{ borderRadius: "10px" }}>
                <h4 className="font-weight-bold py-3 mx-2" >
                    <strong>
                        <FormattedMessage
                            id="communication.title"
                        />
                    </strong>
                </h4>
                <SnackBarComponent
                    position={topCenterPosition}
                    transition={TransitionDown}
                    handleClose={handleClose}
                    openSnackBar={openSnackBar}
                    color={color}
                    msg={msg}
                />
                <div className='text-center mb-2'>
                    <div className="row mb-2 mx-3">
                        {Object.entries(data ? data : miss).map(([key, value], index) => {
                            return (
                                isNaN(key) ?
                                    !key.includes("id") &&
                                    <div className="col-sm col-xl-3 col-lg-4 col-md-6 mb-3" key={index}>
                                        <Card sx={{ display: 'flex', maxWidth: 331, minHeight: 160 }}>
                                            <Box >
                                                <CardContent sx={{ minWidth: 169 }}>
                                                    <Typography component="div" variant="h7">
                                                        <strong>
                                                            <FormattedMessage
                                                                id="communication.sends"
                                                                defaultMessage={`Envios mediante`}
                                                                values={
                                                                    {
                                                                        method: key.charAt(0).toUpperCase() + key.slice(1)
                                                                    }
                                                                }
                                                            />
                                                        </strong>
                                                    </Typography>
                                                    <Typography className='mt-2' variant="subtitle1" color="text.secondary" component="div">
                                                        <div className="form-check form-switch text-center">
                                                            {value ?
                                                                <label style={{ color: "#0a9e05" }} htmlFor={"flexSwitchCheck" + key}>
                                                                    <b>
                                                                        <FormattedMessage
                                                                            id="communication.active"
                                                                        />
                                                                    </b>
                                                                </label>
                                                                :
                                                                <label style={{ color: "#bd1515" }} htmlFor={"flexSwitchCheck" + key}>
                                                                    <b>
                                                                        <FormattedMessage
                                                                            id="communication.inactive"
                                                                        />
                                                                    </b>
                                                                </label>
                                                            }
                                                            <input className="form-check-input" disabled={noChanges.includes(key)} type="checkbox" name={key} id={"flexSwitchCheck" + key} onChange={handleCheck} style={{ marginTop: "6px" }} checked={value && value} />
                                                        </div>
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                            <CardMedia
                                                component="img"
                                                loading='lazy'
                                                sx={{ width: 131 }}
                                                image={`/Icons/Shipping/${key}.webp`}
                                                alt="Live from space album cover"
                                            />
                                        </Card>
                                    </div>
                                    :
                                    <div className="col-sm col-lg-3 col-md-6 mb-2" key={index}>
                                        <Card sx={{ display: 'flex', maxWidth: 331 }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto', minWidth: 200 }}>
                                                    <Typography component="div" variant="h5">
                                                        <Skeleton />
                                                        <Skeleton width="60%" />
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                        <Skeleton width="60%" />
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                            <Skeleton variant="rectangular" width="100%">
                                                <div style={{ paddingTop: '100%' }} />
                                            </Skeleton>
                                        </Card>
                                    </div>
                            )
                        })
                        }
                        {!loading && Object.keys(resData).length === 0 &&
                            <div>
                                <h2>
                                    <FormattedMessage
                                        id="communication.no.data"
                                    />
                                </h2>
                            </div>
                        }
                    </div>
                    {!loading &&
                        <div>
                            <Button variant="contained" onClick={handleSubmit} disabled={hasChanges} type='submit' id="mail" style={{}}>
                                <FormattedMessage
                                    id="save.changes"
                                />
                            </Button>
                            <Button className='mx-2' variant="contained" color="error" disabled={hasChanges} onClick={clearValues} type='submit' id="mail" style={{}}>
                                <FormattedMessage
                                    id="cancel"
                                />
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
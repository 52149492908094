import { FormattedMessage } from "react-intl";
import { Footer } from "../ReuseComponents/Footer";
import MetaDecorator from "../UtilsComponents/MetaDecorator";
// import MainNavbar from "./mainNavbar";

export const NotFound = () => {
    // const backColor = "#82CCCF";
    // const backColorText = "#f4f4f4";
    // const headerBackgroundColor = "#C6E5E7";
    document.body.style.backgroundColor = "#051B29";
    return (
        <section
            className="vh-100 d-flex justify-content-center align-items-center"
            style={{
                backgroundColor: "#f2f3f8"
            }}
        >
            <MetaDecorator
                title={"Not Found - 7Bytes"}
                description={"Entendiendo al consumidor de una forma. Amigable Efectiva y Relevante"}
                imageUrl={"/images/DontFound.png"}
                imageAlt={"NotFound"}
                path={window.location.pathname}
            />
            <div className="container ">
                {/* <MainNavbar/> */}
                <div className="card" style={{ width: "auto", height: "auto" }}>
                    <div className="card-body">
                        <div>
                            <img
                                className="img-fluid d-block mx-auto"
                                alt="Logo"
                                src="/images/7Bytes.svg"
                                style={{
                                    width: "250px",
                                    height: "auto",
                                    padding: "1.5rem",
                                }}
                            />
                        </div>
                        <div className="row text-center">
                            <div className="col-md ">
                                <div>
                                    <img
                                        className="img-fluid d-block mx-auto"
                                        alt="DontFound"
                                        src="/images/DontFound.png"
                                        style={{
                                            width: "350px",
                                            height: "auto",
                                            padding: "2rem",
                                            paddingTop: "3rem",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <h1
                                        className="Title fw-bold mb-2"
                                        style={{ fontFamily: "Regular MadeTommy", fontSize: "50px" }}
                                    >
                                        404
                                    </h1>
                                    <h2
                                        className="mb-3 "
                                        style={{ fontFamily: "Regular MadeTommy", fontSize: "35px" }}
                                    >
                                        <FormattedMessage
                                            id="notFound.title"
                                            defaultMessage="Paginá no encontrada"
                                        />
                                    </h2>
                                    <h5
                                        className="mb-4"
                                        style={{ fontFamily: "Regular MadeTommy", fontSize: "20px" }}
                                    >
                                        <FormattedMessage
                                            id="notFound.description"
                                        />
                                    </h5>
                                    {/* <Link
                                        className="btn btn-primary btn-lg "
                                        tabIndex={-1}
                                        role="button"
                                        aria-disabled="true"
                                        style={{
                                            background: "#03989E",
                                            fontFamily: "Regular MadeTommy",
                                            borderRadius: "20px",
                                        }}
                                        to="/"
                                    >
                                        <strong>
                                            <FormattedMessage
                                                id="back.home.button"
                                                defaultMessage="Volver al inicio"
                                            />
                                        </strong>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <table>
                                <tbody>
                                    <tr>
                                        <Footer />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};


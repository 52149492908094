import { useContext } from 'react';
import { languageContext } from '../../../../../context/languageContext';
import { useSurveyContext } from '../../Context/SurveyCustomHooks/useSurveyContext';

export const QuestionLabel = () => {
    const language = useContext(languageContext);
    const {
        activeStep,
        surveyQuestions,
        lightBackground,
        themeMode,
        isSmallScreen
    } = useSurveyContext();
    const question = surveyQuestions && surveyQuestions[activeStep];
    const languageLabel = language.locale === 'es' ? 'textLabel' : 'textLabel' + language.locale;
    return (
        question &&
        <>
            <h3 className='MediumMadeTommy' style={{ color: themeMode === "dark" ? lightBackground : "", textAlign: isSmallScreen ? "left" : "center" }}>
                {
                    question[languageLabel] ?
                        question[languageLabel] :
                        question.textLabel
                }
                &nbsp;
                {question.required && <span style={{ color: "red" }}>*</span>}
            </h3>
            <h5 className='LightMadeTommy'>
                {question?.[`questionSubtitle${language.locale}`] ?
                    question['questionSubtitle' + language.locale]
                    :
                    question.questionSubtitle
                }
            </h5>
            {!isSmallScreen && !question.emoji_pack && <br />}
        </>
    )
}

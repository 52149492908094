import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate';
import useAuth from '../../CustomHooks/useAuth';
import { SurveyNumbersInfo } from '../DashboardComponents.js/SurveyNumbersInfo';
import { SurveyOrigin } from '../DashboardComponents.js/SurveyOrigin';
import { NPS } from '../DashboardComponents.js/NPS';
import { SurveyNumberSankeyChart } from '../DashboardComponents.js/SurveyNumberSankeyChart';
import { SentSurveysStackedBarChart } from '../DashboardComponents.js/SentSurveysStackedBarChart';
import { DashboardTitle } from '../DashboardComponents.js/DashboardTitle';
import { useDashboardContext } from '../../CustomHooks/useDashboardContext';
// import { useEffect } from 'react';
// import { validateCache } from '../SurveyComponents/UtilsComponents/ValidateCache';
import { TreeMapEntities } from '../DashboardComponents.js/TreeMapEntities';
import { LoginHome } from '../DashboardComponents.js/LoginHome';
import { FormattedMessage } from 'react-intl';

export const Chart = () => {
    const axiosPrivate = useAxiosPrivate();
    const { defaultCustomer, isViewRole } = useAuth();
    const { initDate, endDate } = useDashboardContext();

    const { data: resDashboard } = useQuery({
        queryKey: ["Dashboard", defaultCustomer],
        queryFn: async () => {
            const response = await axiosPrivate.get(`customer/dashboard/${defaultCustomer}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 0,
    });
    const getData = async () => {
        const response = await axiosPrivate.get(`dashboard/numbersInfo?customer=${defaultCustomer}&initDate=${initDate}&endDate=${endDate}`);
        return response.data;
    }

    const waitTime = 60 * 5 * 1000; // 5 minutes
    const { data: SurveyInfo, isLoading, isError, error } = useQuery({
        queryKey: ["SurveyInfo", initDate, endDate, defaultCustomer],
        queryFn: getData,
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: waitTime,
    });

    const { data: surveyNumbers, isLoading: IsLoadingSurveyNumbers, isError: IsErrorSurveyNumbers, error: errorSurveyNumbers } = useQuery({
        queryKey: ["SurveyInfoByDate", initDate, endDate, defaultCustomer],
        queryFn: async () => {
            const response = await axiosPrivate.get(`dashboard/byDate?customer=${defaultCustomer}&initDate=${initDate}&endDate=${endDate}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 0,
    });


    // useEffect(() => {
    //     if (validateCache(1, initDate)) {
    //         refetch();
    //     }
    // }, [refetch, initDate]);

    const { data: entitiesData, isLoading: IsLoadingEntitiesData, isError: IsErrorEntitiesData, error: errorEntitiesData } = useQuery({
        queryKey: ["EntitiesData", initDate, endDate, defaultCustomer],
        queryFn: async () => {
            const response = await axiosPrivate.get(`dashboard/entities?customer=${defaultCustomer}&initDate=${initDate}&endDate=${endDate}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 0,
    });

    const getNPS = async () => {
        const response = await axiosPrivate.get(`dashboard/nps?customer=${defaultCustomer}&initDate=${initDate}&endDate=${endDate}`);
        return response.data;
    }

    const { data: nps, isLoading: isLoadingNPS, isError: isErrorNPS } = useQuery({
        queryKey: ["NPS", initDate, endDate, defaultCustomer],
        queryFn: getNPS,
        refetchOnWindowFocus: false,
        retry: false,
        // staleTime: 60 * 60 * 24 * 1000,
        // cacheTime: 60 * 60 * 24 * 1000,
    });
    // console.log('SurveyInfo', nps);
    const waitLoading = isLoading || isLoadingNPS || IsLoadingEntitiesData || IsLoadingSurveyNumbers;
    const waitData = SurveyInfo !== undefined || entitiesData !== undefined || surveyNumbers !== undefined;
    return (
        < >
            {!isViewRole && <LoginHome />}
            <DashboardTitle />
            {
                (waitLoading || waitData) ?
                    <>
                        {
                            (isLoading || SurveyInfo) &&
                            <SurveyNumbersInfo
                                data={SurveyInfo}
                                isLoading={isLoading}
                                isError={isError}
                                error={error}
                            />
                        }
                        <div className='row mt-3' style={{ paddingRight: 0 }}>
                            {
                                (isLoading || SurveyInfo) &&
                                <div className='col-md-4 mb-3' id='SankeyChart'>
                                    <SurveyNumberSankeyChart
                                        chartName='SankeyChart'
                                        data={SurveyInfo}
                                        isLoading={isLoading}
                                        isError={isError}
                                        error={error}
                                    />
                                </div>
                            }
                            {
                                nps !== 'No data' &&
                                <div className='col-md-4 mb-3'>
                                    <NPS
                                        nps={nps}
                                        isLoading={isLoadingNPS}
                                        isError={isErrorNPS}
                                    />
                                </div>
                            }
                            {
                                (isLoading || SurveyInfo) &&
                                <div className='col-md-4 mb-3'>
                                    <SurveyOrigin />
                                </div>
                            }
                        </div>
                        <div className='row' style={{ paddingRight: 0 }}>
                            {
                                (IsLoadingSurveyNumbers || surveyNumbers) &&
                                <div className={`col-md-6 mb-3`} id='BarChart'>
                                    <SentSurveysStackedBarChart
                                        chartName='BarChart'
                                        data={surveyNumbers}
                                        isLoading={IsLoadingSurveyNumbers}
                                        isError={IsErrorSurveyNumbers}
                                        error={errorSurveyNumbers}
                                    />
                                </div>
                            }
                            {
                                (IsLoadingEntitiesData || entitiesData) &&
                                <div className='col-md-6 mb-3' id='treemap' >
                                    <TreeMapEntities
                                        isLoading={IsLoadingEntitiesData}
                                        data={entitiesData}
                                        isError={IsErrorEntitiesData}
                                        error={errorEntitiesData}
                                        chartName='treemap'
                                    />
                                </div>
                            }
                        </div>
                    </>
                    :
                    <div className='col-md-12 mt-3'>
                        <h1 className='text-center'>
                            <FormattedMessage id="dashboard.empty" />
                        </h1>
                        <h2 className='text-center'>
                            <FormattedMessage id="dashboard.empty.desp" />
                        </h2>
                    </div>
            }
            {
                resDashboard?.link &&
                <div className="col-sm-12 my-1">
                    <div style={{ width: "auto", overflowY: "hidden", overflowX: "scroll", borderRadius: "20px" }}>
                        <iframe id='chart' title='brain' src={resDashboard?.link ?? "https://app.powerbi.com/view?r=eyJrIjoiOGFiZTRlY2YtMTIzNS00ZmZkLTgxMWMtZmIyZTQwOTc3OGIzIiwidCI6ImRiZWNmNjM0LTg3ZDktNDlhZC1iYzllLWEyYWFhNDQxMDFhYyIsImMiOjR9"} width={"auto"} style={{ margin: "0px" }} height={500} />
                    </div>
                </div>
            }
            {/* <Plan/> */}

        </>
    )

    // console.log('No hay nada');



}

import dependentvalidation from "./dependentValidation";
import { FormattedMessage } from "react-intl";
import anyAscii from 'any-ascii';

const surveyValidation = async (fields, formValues) => {
    var errors = {};
    var answers = [];
    const forOtherOptions = process.env.REACT_APP_FOR_OTHERS_OPTIONS.replace(/\s/g, "").replace("'", "").split(",");
    await fields.questions.forEach(question => {
        if (question.itemType === 'Radio') {
            if (question.values.find(value => value === true)) {
                if (forOtherOptions.includes(question.options[question.values.indexOf(true)].toLowerCase())) {
                    if (question.values[question.values.length - 1] !== true && question.values[question.values.length - 1] !== false && question.values[question.values.length - 1] !== "") {
                        answers.push({
                            id: fields.userId,
                            survey_item_id: question.itemId,
                            answer_value: question.values[question.values.length - 1]
                        });
                    } else {
                        errors[question.itemId] = <FormattedMessage id="survey.error.enterAnAnswer" defaultMessage="Por favor ingrese una respuesta" />;
                    }
                } else {
                    answers.push({
                        id: fields.userId,
                        survey_item_id: question.itemId,
                        answer_value: question.options[question.values.indexOf(true)]
                    });
                }
                if (question.depentId.length > 0) {
                    question.depentId.forEach((depentQ) => {
                        if (question.options[question.values.indexOf(true)] === depentQ.optionvalue) {
                            if (depentQ.dependentQuestion) {
                                dependentvalidation(depentQ.dependentQuestion, answers, fields.userId, question.itemId);
                            }
                        }
                    });
                }
            } else if (question.required) {
                errors[question.itemId] = <FormattedMessage id="survey.error.selectOption" defaultMessage="Por favor seleccione una opción" />;
            }
        }
        else if (question.itemType === 'Check') {
            // get index of checked values
            let checkedValues = [];
            question.values.forEach((value, index) => {
                if (value) {
                    checkedValues.push(index);
                }
            });
            const values = checkedValues.map(index => question.options[index]);
            if (values.length > 0) {
                values.forEach(value => {
                    if (value.toLowerCase() === "otros" || value.toLowerCase() === "otro" || value.toLowerCase() === "otr@s" || value.toLowerCase() === "otra" || value.toLowerCase() === "otras") {
                        let otros = question.values[question.options.indexOf(value)];
                        if (otros[0] === "") {
                            errors[question.itemId] = <FormattedMessage id="survey.error.enterAnAnswer" defaultMessage="Por favor ingrese una respuesta" />;
                        } else {
                            otros.length > 0 && otros.forEach(value => {
                                value.length === 0 ? errors[question.itemId] = 'Por favor especifique de manera correcta para quien compra.'
                                    :
                                    answers.push({
                                        id: fields.userId,
                                        survey_item_id: question.itemId,
                                        answer_value: value
                                    })
                            })
                        }
                        return;
                    }
                    answers.push({
                        id: fields.userId,
                        survey_item_id: question.itemId,
                        answer_value: value
                    });
                });
            } else if (question.required) {
                errors[question.itemId] = <FormattedMessage id="survey.error.selectOption" defaultMessage="Por favor seleccione una opción" />;
            }
        } else if (question.itemType === 'Select Radio') {
            if ((!question?.values || question?.values?.length === 0) && question.required) {
                errors[question.itemId] = <FormattedMessage id="survey.error.selectOption" defaultMessage="Por favor seleccione una opción" />;
            }
            else if (question?.values?.length > 0) {
                answers.push({
                    id: fields.userId,
                    survey_item_id: question.itemId,
                    answer_value: question.values
                });
            }
        }
        else if (question.itemType === 'Select Check') {
            if ((!question?.values || question?.values?.length === 0) && question.required) {
                errors[question.itemId] = <FormattedMessage id="survey.error.selectOption" defaultMessage="Por favor seleccione una opción" />;
            }
            else if (question?.values?.length > 0) {
                question.values.forEach(value => {
                    answers.push({
                        id: fields.userId,
                        survey_item_id: question.itemId,
                        answer_value: value
                    });
                });
            }
        }
        else if (question.itemType === 'Text Box') {
            if (question.value !== '') {
                if (question.textLabel.toLowerCase().includes("una sola palabra") || question.textLabel.toLowerCase().includes("one word") || question.textLabel.toLowerCase().includes("single word")) {
                    //Quitar los espacios del final y del principio
                    question.value = question.value.replace(/\s\s+/g, ' ').trim();
                    //regex for no dots or spaces or numbers or special characters or commas
                    const regex = /^[A-Za-zÁáÉéÍíÓóÚúÑñ]+$/;
                    if (!regex.test(question.value)) {
                        errors[question.itemId] = <FormattedMessage id="survey.error.oneWord" defaultMessage="Por favor ingrese una sola palabra, sin números, ni puntos, ni comas, ni espacios" />;
                    } else if (question.value.length > 30) {
                        errors[question.itemId] = <FormattedMessage id="survey.error.longWord" defaultMessage="Por favor ingrese una palabra mas corta" />;
                    } else {
                        answers.push({
                            id: fields.userId,
                            survey_item_id: question.itemId,
                            answer_value: anyAscii(question.value)
                        });
                    }
                } else {
                    if (question.valueFormat === "only text") {
                        //check if the value is only text
                        const regex = /^[A-Za-zÁáÉéÍíÓóÚúÑñ.\s]+$/;
                        if (!regex.test(question.value)) {
                            errors[question.itemId] = <FormattedMessage id="survey.error.onlyText" defaultMessage="Por favor ingrese solo texto" />;
                        } else {
                            answers.push({
                                id: fields.userId,
                                survey_item_id: question.itemId,
                                answer_value: anyAscii(question.value)
                            });
                        }
                    } else {
                        answers.push({
                            id: fields.userId,
                            survey_item_id: question.itemId,
                            answer_value: anyAscii(question.value)
                        });
                    }
                }
            } else if (question.required) {
                errors[question.itemId] = <FormattedMessage id="survey.error.enterAnAnswer" defaultMessage="Por favor ingrese una respuesta" />;
            }
        }
        else if (question.itemType === 'Text Area') {
            if (question.value !== '') {
                if (question.valueFormat === "only text") {
                    //check if the value is only text
                    const regex = /^[A-Za-zÁáÉéÍíÓóÚúÑñ.\s]+$/;
                    if (!regex.test(question.value)) {
                        errors[question.itemId] = <FormattedMessage id="survey.error.onlyText" defaultMessage="Por favor ingrese solo texto" />;
                    } else {
                        answers.push({
                            id: fields.userId,
                            survey_item_id: question.itemId,
                            answer_value: anyAscii(question.value)
                        });
                    }
                } else {
                    answers.push({
                        id: fields.userId,
                        survey_item_id: question.itemId,
                        answer_value: anyAscii(question.value)
                    });
                }
            } else if (question.required) {
                errors[question.itemId] = <FormattedMessage id="survey.error.enterAnAnswer" defaultMessage="Por favor ingrese una respuesta" />;
            }
        }
    });
    if (fields.requiredPersonalInfo && fields?.name === null) {
        if (formValues.display_name === "") {
            errors["display_name"] = <FormattedMessage id="survey.error.enterAnAnswer" defaultMessage="Por favor ingrese una respuesta" />;
        } if (formValues.phone === "") {
            errors["phone"] = <FormattedMessage id="survey.error.enterAnAnswer" defaultMessage="Por favor ingrese una respuesta" />;
        } if (formValues.email === "") {
            errors["email"] = <FormattedMessage id="survey.error.enterAnAnswer" defaultMessage="Por favor ingrese una respuesta" />;
        } if (formValues.identification_number === "") {
            errors["identification_number"] = <FormattedMessage id="survey.error.enterAnAnswer" defaultMessage="Por favor ingrese una respuesta" />;
        }
    }
    // console.log("Final", answers);
    return [errors, answers];
}

export default surveyValidation;

import { createContext, useState, useEffect } from "react";
import useLocalStorage from "../CustomHooks/useLocalStorage";
import jwt_decode from "jwt-decode";
import { ROLES_LIST } from "../Domains/roleList";
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState({});
    const [persist, setPersist] = useLocalStorage('persist', true);
    const [isLogin, setIsLogin] = useLocalStorage('isLogin', false);
    const [UserInfo, setUserInfo] = useState({});
    const [defaultCustomer, setDefaultCustomer] = useLocalStorage('selectedCustomer', '');
    const [availableCustomers, setAvailableCustomers] = useLocalStorage('availableCustomers', []);
    const [isViewRole, setIsViewRole] = useState(false);

    useEffect(() => {
        !persist && setIsLogin(isAuth?.accessToken ? true : false);
    }, [isAuth, setIsLogin, persist]);

    useEffect(() => {
        if (isAuth?.accessToken) {
            const decode = jwt_decode(isAuth.accessToken).UserInfo;
            setUserInfo(decode);
            const checkOnlyViewRole = !decode?.roles?.some((role) => role !== ROLES_LIST.View);
            setIsViewRole(checkOnlyViewRole);
            if (decode?.defaultCustomer) {
                setDefaultCustomer(decode.defaultCustomer);
                setAvailableCustomers(decode.customers);
            }
        } else {
            setUserInfo({});
            setIsViewRole(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    return (
        <AuthContext.Provider value={
            {
                isAuth,
                setIsAuth,
                persist,
                setPersist,
                isLogin,
                setIsLogin,
                UserInfo,
                defaultCustomer,
                availableCustomers,
                setDefaultCustomer,
                isViewRole
            }
        }>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
import React, { useEffect, useState } from 'react'

export const ScrollToTopComponent = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        //button is displayed after scrolling for 300 pixels
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        }
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, [])

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    return (
        visible &&
            <button
                type="button"
                className="btn btn-danger btn-floating btn-lg"
                id="btn-back-to-top"
                onClick={handleScrollToTop}
            >
                <i className="fa fa-arrow-up"></i>
            </button>
    )
}


export const QuestionError = ({ errorMsg, showError }) => {
    return (
        showError &&
        <h6
            className="mt-3 LightMadeTommy"
            style={{
                color: "#FF0000"
            }}>
            <b>¡Oops!</b>  {errorMsg}
        </h6>
    )
}

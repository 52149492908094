import { Autocomplete, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useSurveyContext } from '../../Context/SurveyCustomHooks/useSurveyContext';
import { languageContext } from '../../../../../context/languageContext';
import { useIntl } from 'react-intl';

export const SelectCheckQuestion = () => {
    const intl = useIntl()
    const language = useContext(languageContext);
    const {
        activeStep,
        surveyQuestions,
        setEnableScrollMove,
        updateAnswers,
        answers,
        setAnswers,
        getLanguageOptions
    } = useSurveyContext();
    const question = surveyQuestions[activeStep];
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const options = getLanguageOptions(question, language.locale, "select");
    // console.log(question?.[`optionsen`])
    //const [lastLanguage, setLastLanguage] = useState(language.locale);

    //ESTO ES PARA QUE SE ACTUALICEN LAS RESPUESTAS CUANDO SE CAMBIE DE IDIOMA
    // useEffect(() => {
    //     // delete answers
    //     console.log(lastLanguage)
    //     const languageLabel = lastLanguage === 'es' ? 'options' : 'options' + lastLanguage;
    //     const valueArray = answers.filter(answer => answer.survey_item_id === question.itemId).map(answer => answer.answer_value);
    //     //get the all the indexes of the answers in valueArray
    //     const index = valueArray.map((value, index) => {
    //         return question?.[languageLabel]?.findIndex(option => option === value)
    //     })
    //     console.log(index)
    //     //check if the indexes are valid
    //     if (index.includes(-1)) {
    //         console.log("Invalid index")
    //         return
    //     }
    //     //save the answers in the new language using the indexes
    //     setSelectedValues(index.map(i => question?.[languageLabel][i]))
    //     setSelectedOptions(index.map(i => question?.[languageLabel][i]))
    //     setLastLanguage(language.locale);
    // }, [intl])

    useEffect(() => {
        //clean the answers
        setAnswers(answers.filter(answer => answer.survey_item_id !== question.itemId));
        setSelectedValues([]);
        //eslint-disable-next-line
    }, [intl])


    useEffect(() => {
        // Initialize the selected values based on answers
        const initialValues = answers
            .filter(answer => answer.survey_item_id === question.itemId)
            .map(answer => answer.answer_value);
        setSelectedValues(initialValues);
        setSelectedOptions(initialValues);
        //eslint-disable-next-line
    }, []);

    const handleCheckChange = (event, value) => {
        setSelectedValues(value);
        //Value es un array con las opciones seleccionadas
        // Encuentra el último valor añadido o eliminado
        const added = value.find(option => !selectedOptions.includes(option));
        const removed = selectedOptions.find(option => !value.includes(option));
        setSelectedOptions(value);
        if (added) {
            return updateAnswers(question.itemId, added, "multiple");
        }
        if (value.length === 0) {
            return setAnswers(answers.filter(answer => answer.survey_item_id !== question.itemId));
        }
        if (removed) {
            return setAnswers(answers.filter(answer => answer.answer_value !== removed));
        }
    };

    return (
        <Autocomplete
            className=''
            multiple
            limitTags={4}
            //*Si hay opciones nulas, se muestran las opciones en español
            options={options}
            id={`selectcheck${(activeStep)}`}
            filterSelectedOptions
            onChange={handleCheckChange}
            value={selectedValues}
            // defaultValue={answers.filter(answer => answer.survey_item_id === question.itemId).map(answer => answer.answer_value)}
            onOpen={() => setEnableScrollMove(false)} // Se activa cuando el autocomplete se abre
            onClose={() => setEnableScrollMove(true)} // Se activa cuando el autocomplete se cierra
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props} className='RegularMadeTommy' key={props['data-option-index'] + language.locale}>
                        <input type="checkbox" style={{ margin: "10px 10px" }} checked={selected} readOnly />
                        {option ? option : question.options[props['data-option-index']]}
                    </li>
                )
            }}
            ChipProps={{ className: 'RegularMadeTommy mx-1' }}
            // renderTags={(tagValue, getTagProps) =>
            //     tagValue.map((option, index) => {
            //         const { key, ...tagProps } = getTagProps({ index });
            //         return (
            //             <Chip
            //                 key={key}
            //                 className='MuiChip-label'
            //                 label={option ? option : question.options[tagProps['data-option-index']]}
            //                 {...tagProps}
            //             />
            //         );
            //     })
            // }
            // isOptionEqualToValue={(option, value,) => {
            //     console.log(option, value)
            //     return option === value || option === "the first option is this";
            // }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label=""
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none', // Elimina el borde del campo
                            }
                        },
                    }}
                    placeholder={intl.formatMessage({ id: "survey.error.selectMultiOption" })}
                    className='MediumMadeTommy'
                />
            )}
        />
    )
}

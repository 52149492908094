import { useSurveyContext } from '../../Context/SurveyCustomHooks/useSurveyContext';
import { AreaQuestion } from './AreaQuestion';
import { CheckQuestion } from './CheckQuestion';
import { RadioQuestion } from './RadioQuestion';
import { TextQuestion } from './TextQuestion';
import { SelectCheckQuestion } from './SelectCheckQuestion';
import { SelectRadioQuestion } from './SelectRadioQuestion';
import { QuestionLabel } from './QuestionLabel';
import { QuestionError } from './QuestionError';

export const SurveyQuestions = () => {
    const {
        activeStep,
        surveyQuestions,
        isSmallScreen,
        errorMsg,
    } = useSurveyContext();

    const itemType = surveyQuestions && surveyQuestions[activeStep].itemType;
    const questionTypes = {
        'Radio': <RadioQuestion />,
        'Check': <CheckQuestion />,
        'Text Box': <TextQuestion />,
        'Text Area': <AreaQuestion />,
        'Select Check': <SelectCheckQuestion />,
        'Select Radio': <SelectRadioQuestion />,
    }

    return (
        <div className={`container ${!isSmallScreen && 'text-center'}`} style={{ marginTop: isSmallScreen ? "2vh" : "6vh", }}>
            <QuestionLabel />
            {questionTypes[itemType]}
            <QuestionError
                key={`error${activeStep}$`}
                errorMsg={errorMsg}
                showError={errorMsg !== ''}
            />
        </div>
    )
}

import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
// import { Navbar } from '../ReuseComponents/Navbar';
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate';
import MetaDecorator from '../UtilsComponents/MetaDecorator';
import { Autocomplete, Button, TextField, Tooltip } from '@mui/material';
import useAuth from '../../CustomHooks/useAuth';
import { ROLES_LIST, ROLES_NAMES } from '../../Domains/roleList';
import { CapitializeFirstLetters } from '../SurveyComponents/UtilsComponents/UtilsFunctions';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const CreateUser = () => {
    const { UserInfo, availableCustomers } = useAuth();
    const checkAdmin = UserInfo?.roles.includes(ROLES_LIST.Admin)
    const intl = useIntl();
    const axiosPrivate = useAxiosPrivate();
    const emailRef = useRef();
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);

    const controller = new AbortController();

    const [pwd, setPwd] = useState('');
    const [verifyPwd, setVerifyPwd] = useState('');

    const [validPwd, setValidPwd] = useState(false);
    const [validMatch, setValidMatch] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [msg, setMsg] = useState('');

    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState();

    const [count, setCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axiosPrivate.get('role', {
                    signal: controller.signal
                });
                if (checkAdmin) {
                    setRoles(res.data);
                } else {
                    setRoles(res.data.filter((role) => role.id !== ROLES_LIST.Admin.toString()));
                }
                const resp = await axiosPrivate.get('customer/info/all',
                    {
                        signal: controller.signal
                    });
                if (checkAdmin) {
                    //CapitializeFirstLetters
                    resp.data.forEach((customer) => {
                        customer.name = CapitializeFirstLetters(customer.name);
                    });
                    setCustomers(resp.data);
                } else {
                    const filteredCustomers = resp.data.filter((customer) => availableCustomers.includes(customer.name.toLowerCase()));
                    filteredCustomers.forEach((customer) => {
                        customer.name = CapitializeFirstLetters(customer.name);
                    });
                    setCustomers(filteredCustomers);
                }
            } catch (e) {
                console.error(e.message);
            }
        }
        getData();
        emailRef.current.focus();
        return () => {
            controller.abort();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        setValidEmail(EMAIL_REGEX.test(email));
        if (validEmail && errMsg.toLowerCase().includes('email')) {
            setErrMsg('');
        }
        // eslint-disable-next-line
    }, [email, validEmail]);

    useEffect(() => {
        const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/¡¿?]).{8,24}$/;
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === verifyPwd);
        if (validPwd) {
            if (errMsg.toLowerCase().includes('8-24')) setErrMsg('');
            if (!validMatch) setErrMsg('');
        }
        // eslint-disable-next-line
    }, [pwd, verifyPwd]);

    const handleSelectRoles = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedRoles([...selectedRoles, parseInt(value)]);
        }
        else {
            setSelectedRoles(selectedRoles.filter((role) => role !== parseInt(value)));
        }
    }

    const handleSelectCustomers = (e, values) => {

        //get the ids of the customers
        const ids = values.map((customer) => customer.id);
        setSelectedCustomers(ids);
    }

    useEffect(() => {
        //* Este useEffect se ejecuta cuando se cambia el idioma para asi volver a cargar los mensajes de error
        if (count === 1) {
            const getErrMsg = validations();
            if (getErrMsg) return setErrMsg(getErrMsg);
        }
        // eslint-disable-next-line
    }, [intl]);

    const validations = () => {
        if (count === 0) setCount(1);
        if (!validEmail) {
            setMsg('');
            //!! Hay un problema porque como queda guardado el mensaje en el primer idioma 
            //!! al momento de cambiar el idioma no cambia el mensaje porque el estado no cambia
            return (intl.formatMessage({ id: "create.user.error.email" }));
        }
        else if (!validPwd) {
            setMsg('');
            return (intl.formatMessage({ id: "create.user.error.valid" }));
        }
        else if (!validMatch) {
            setMsg('');
            return (intl.formatMessage({ id: "create.user.error.match" }));
        }
        else if (Object.keys(selectedCustomers).length === 0) {
            setMsg('');
            return (intl.formatMessage({ id: "create.user.error.customer" }));
        }
        else if (Object.keys(selectedRoles).length === 0) {
            setMsg('');
            return (intl.formatMessage({ id: "create.user.error.role" }));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const getErrMsg = validations();
        if (getErrMsg) return setErrMsg(getErrMsg);
        try {
            const response = await axiosPrivate.post('register',
                JSON.stringify({
                    email,
                    pwd,
                    roles: selectedRoles,
                    customersId: selectedCustomers
                }),
                {
                    signal: controller.signal,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response.data?.text) {
                setMsg(
                    <FormattedMessage
                        id="create.user.msg.success"
                        values={
                            {
                                email
                            }
                        }
                    />
                );
                setEmail('');
                setPwd('');
                setVerifyPwd('');
                setSelectedRoles([]);
                setSelectedCustomers([]);
            }
            return () => {
                controller.abort();
            }
        } catch (err) {
            setMsg('');
            console.error(err);
            if (err.response?.status === 409) {
                setErrMsg(intl.formatMessage({ id: "create.user.error.email.exits" }));
            } else {
                setErrMsg("Internal server error");
            }
        }
    }

    return (
        <div className="main " style={{
            padding: "2rem 0 1rem 0",
            minHeight: "80vh"
        }}>
            {/* <Navbar /> */}
            <MetaDecorator
                title={intl.formatMessage({ id: "create.user.msg.title" })}
                description={"Entendiendo al consumidor de una forma. Amigable Efectiva y Relevante"}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"7Bytes - Estudio de mercado continuo"}
                path={window.location.pathname}
            />
            <div className="container ">
                <Button color="inherit"
                    style={{ position: "absolute" }}
                    onClick={() => {
                        navigate(-1);
                    }}
                    variant="contained" >
                    <ArrowBackIcon /> &nbsp;
                    <FormattedMessage id="back" defaultMessage={`Regresar`} />
                </Button>
                <div className="signup-content">
                    <div className="text-center" >
                        <h2 className="fw-normal mt-3"
                            style={{
                                color: "whitesmoke",
                                letterSpacing: 1,
                                fontSize: 40
                            }}>
                            <strong>
                                <FormattedMessage
                                    id="create.user.msg.title"
                                    defaultMessage={`Crear Nuevo Usuario`}
                                />
                            </strong>
                        </h2>
                    </div>
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <FormattedMessage
                            id="create.initial.msg"
                            defaultMessage={`Por favor recuerda ingresar todos los campos`}
                        />
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    {errMsg !== "" &&
                        <div className="alert alert-danger d-flex align-items-center" role="alert">
                            <svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>&nbsp;
                            <div>
                                {errMsg}
                            </div>
                        </div>
                    }
                    {msg !== "" &&
                        <div className="alert alert-success d-flex align-items-center" role="alert">
                            <svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>&nbsp;
                            <div>
                                {msg}
                            </div>
                        </div>
                    }
                    <form onSubmit={handleSubmit}>
                        <div className="row g-2 mb-3">
                            <div className="col-md">
                                <div className="form-floating mb-2">
                                    <input
                                        ref={emailRef}
                                        type="email"
                                        className="form-control"
                                        style={{ borderRadius: "10px" }}
                                        id="inputemail"
                                        autoComplete="off"
                                        placeholder="Email"
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                    <label htmlFor="floatingInputEmail">
                                        <i className="fa fa-envelope" style={{ color: "gray" }} /> &nbsp;
                                        <FormattedMessage
                                            id="login.email"
                                            defaultMessage={`Email`}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row g-2 mb-3">
                            <div className="col-md">
                                {
                                    Object.keys(customers).length > 0 &&
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={customers}
                                        getOptionLabel={(option) => option.name}
                                        // defaultValue={[top100Films[13]]}
                                        onChange={handleSelectCustomers}
                                        limitTags={3}
                                        filterSelectedOptions
                                        style={{ backgroundColor: 'white', borderRadius: "10px" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label={<>
                                                    <i className="fa fa-user" style={{ color: "gray" }} /> &nbsp;
                                                    <FormattedMessage
                                                        id="user.table.date.customers"
                                                        defaultMessage={`Clientes`}
                                                    />
                                                </>}
                                                placeholder={intl.formatMessage({ id: "edit.user.customers.select" })}
                                            />
                                        )}
                                    />
                                }
                            </div>
                        </div>
                        {!validPwd && <div className="row g-3 mb-3">
                            <div className="col-md">
                                <div style={{ color: "whitesmoke" }}>
                                    <FormattedMessage
                                        id="create.user.title.pwd"
                                        defaultMessage={`Configurar la contraseña`}
                                    />
                                    <br />
                                    <FormattedMessage
                                        id="create.user.subtitle.pwd"
                                    />
                                    <br />
                                    <ul>
                                        <div className="row">
                                            <div className="col-md">
                                                <li>
                                                    <FormattedMessage
                                                        id="create.user.pwd.criteria.1"
                                                    />
                                                </li>
                                                <li>
                                                    <FormattedMessage
                                                        id="create.user.pwd.criteria.2"
                                                    />
                                                </li>
                                                <li>
                                                    <FormattedMessage
                                                        id="create.user.pwd.criteria.3"
                                                    />
                                                </li>
                                            </div>
                                            <div className="col-md">
                                                <li>
                                                    <FormattedMessage
                                                        id="create.user.pwd.criteria.4"
                                                    />
                                                </li>
                                                <li>
                                                    <FormattedMessage
                                                        id="create.user.pwd.criteria.5"
                                                    />
                                                </li>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>}
                        {/* Colocar que se puedan ver las contraseñas al momento de darle al boton de mirar */}
                        <div className="row g-3 mb-3">
                            <div className="col-md">
                                <div className="form-floating">
                                    <input
                                        type="password"
                                        className="form-control"
                                        style={{ borderRadius: "10px" }}
                                        id="inputpassword"
                                        placeholder="password"
                                        autoComplete='off'
                                        required
                                        onChange={(e) => setPwd(e.target.value)}
                                        value={pwd}
                                    />
                                    <label>
                                        <i className="fa fa-key" style={{ color: "gray" }} /> &nbsp;
                                        <FormattedMessage
                                            id="login.password"
                                            defaultMessage={`Contraseña`}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="form-floating">
                                    <input
                                        type="password"
                                        className="form-control"
                                        style={{ borderRadius: "10px" }}
                                        id="inputconfirmpassword"
                                        autoComplete='off'
                                        placeholder="password"
                                        required
                                        onChange={(e) => setVerifyPwd(e.target.value)}
                                        value={verifyPwd}
                                    />
                                    <label>
                                        <i className="fa fa-key" style={{ color: "gray" }} /> &nbsp;
                                        <FormattedMessage
                                            id="create.user.verify.pwd"
                                            defaultMessage={`Validar Contraseña`}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 mt-1" style={{ color: "white", padding: "5px 7px" }}>
                            <FormattedMessage
                                id="create.user.for.role"
                                defaultMessage={`Por favor seleccione los roles que tendrá el usuario`}
                            />
                        </div>
                        <div className="row g-3 mb-3">
                            <div className="col-md">
                                <div style={{ color: "whitesmoke" }}>
                                    {roles.map((rol, index) => (
                                        <Tooltip key={index} arrow placement="top" title={ROLES_NAMES[rol.id]["desp"]}>
                                            <div className="form-check form-check-inline" >
                                                <input className="form-check-input" type="checkbox" id={`check${index}`} defaultValue={rol.id} onChange={handleSelectRoles} />
                                                <label className="form-check-label" htmlFor={`check${index}`}>{ROLES_NAMES[rol.id]["name"]}</label>
                                            </div>
                                        </Tooltip>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <button type="submit" name="submit" id="submit" className="form-submit">
                                <FormattedMessage
                                    id="create.user.btn"
                                    defaultMessage={`Crear`}
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

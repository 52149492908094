import { FormattedMessage } from "react-intl";

export const ROLES_LIST = {
    "Admin": 1,
    "View": 4,
    "Editor": 12,
    "customer_admin": 13,
}

export const ROLES_NAMES = {
    1: {
        name: <FormattedMessage id="user.admin" defaultMessage={"Admin"} />,
        desp: <FormattedMessage id="user.admin.descrip" defaultMessage={"Admin"} />
    },
    4: {
        name: <FormattedMessage id="user.view" defaultMessage={"View"} />,
        desp: <FormattedMessage id="user.view.descrip" defaultMessage={"View"} />
    },
    12: {
        name: <FormattedMessage id="user.edit" defaultMessage={"Editor"} />,
        desp: <FormattedMessage id="user.edit.descrip" defaultMessage={"Editor"} />
    },
    13: {
        name: <FormattedMessage id="user.customer.admin" defaultMessage={"Customer Admin"} />,
        desp: <FormattedMessage id="user.customer.admin.descrip" defaultMessage={"Customer Admin"} />
    },
};

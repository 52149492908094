import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SevenBytesPostSurvey } from '../CreateSurveyComponents/MainSurveyComponensts/SevenBytesPostSurvey';
import { RestaurantPostSurvey } from '../CreateSurveyComponents/MainSurveyComponensts/RestaurantPostSurvey';

export const PostSurvey = () => {
    let { userId, customer } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { surveyInfo, surveyStyles } = location.state || {};

    useEffect(() => {
        if (!surveyInfo || !surveyStyles) {
            navigate(`/survey/${customer}/${userId}`);
        }
    }, [surveyInfo, surveyStyles, customer, userId, navigate]);
    const category = surveyStyles?.category ?? "";

    if (!surveyInfo || !surveyStyles) {
        // Return null to avoid rendering anything while navigating
        return null;
    }

    if (category?.includes("restaurant") || category?.includes("hotel") || category?.includes("taller")) {
        return (
            <RestaurantPostSurvey
                key={"restaurantPostSurvey"}
                url={surveyStyles?.url}
                logo={surveyStyles?.logo}
                headerBackground={surveyStyles?.headerBackground}
                bodyBackground={surveyStyles?.bodyBackground}
                fontColor={surveyStyles?.fontColor}
                category={surveyStyles?.category}
                survey={surveyInfo}
            />
        )
    }

    return surveyInfo ? (
        <SevenBytesPostSurvey
            key={"sevenBytesPostSurvey" + surveyInfo?.userId}
            survey={surveyInfo}
        />
    ) : (
        <div>Loading...</div>
    );
}

import React from 'react'
import { useSurveyContext } from '../../Context/SurveyCustomHooks/useSurveyContext';
import anyAscii from 'any-ascii';

export const AreaQuestion = () => {
    const {
        answers,
        activeStep,
        setAnswers,
        updateAnswers,
        surveyQuestions
    } = useSurveyContext();
    const question = surveyQuestions[activeStep];
    const currentAnswer = answers?.find(answer => answer.survey_item_id === question.itemId)?.answer_value;
    const maxCharsTextArea = 300;

    const handleTextChange = (e) => {
        if (e.target.value !== "") {
            updateAnswers(question.itemId, anyAscii(e.target.value));
        } else {
            setAnswers(answers.filter(answer => answer.survey_item_id !== question.itemId));
        }
    }

    return (
        <div className="form-group">
            <br />
            <textarea
                className="form-control"
                style={{ resize: "none" }}
                maxLength={question?.maxLength && question?.maxLength > 0 && question?.maxLength < maxCharsTextArea ? question?.maxLength : maxCharsTextArea}
                name={`textArea`}
                id={`textArea`}
                rows="4"
                onChange={handleTextChange}
                value={currentAnswer ?? ''}
            />
        </div>
    )
}

import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

const MetaDecorator = ({ title, description, imageUrl, imageAlt, path }) => (
    <Helmet>
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="description" content={description} />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={"https://" + window.location.hostname + imageUrl} />
        <meta property="og:type" content="image" />
        <meta
            property="og:url"
            content={window.location.hostname + window.location.pathname + window.location.search}
        />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.hostname + window.location.pathname + window.location.search} />
        <meta property="twitter:title" content={title} />
        <meta name="twitter:image:alt" content={imageAlt} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={"https://" + window.location.hostname + imageUrl} />
        <link rel="canonical" href={path} />
    </Helmet>
);

MetaDecorator.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};

export default MetaDecorator;
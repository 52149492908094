import { Grid, Skeleton, Tooltip } from '@mui/material'
import React from 'react'
import FeedIcon from '@mui/icons-material/Feed';
import { CardViewComponent } from '../UtilsComponents/CardViewComponent'
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardElementComponent } from '../UtilsComponents/CardElementComponent';
import CallMadeIcon from '@mui/icons-material/CallMade';

export const SurveyNumbersInfo = ({ data, isLoading, isError, error }) => {
    const intl = useIntl();
    // const total = data && Object.values(data).reduce((acc, value) => acc + value, 0) - data?.surveyTotal;

    const names = {
        errors: {
            name: intl.formatMessage({ id: "dashboard.sending.error" }),
            icon: <WarningIcon style={{ color: "orange" }} className='cardIcon' />,
            description: intl.formatMessage({ id: "dashboard.sending.error.description" })
        },
        sent: {
            name: intl.formatMessage({ id: "dashboard.sent" }),
            icon: <CallMadeIcon style={{ color: "yellowgreen" }} className='cardIcon' />,
            description: intl.formatMessage({ id: "dashboard.sent.description" })
        }
        ,
        surveyTotal: {
            name: intl.formatMessage({ id: "dashboard.survey.total" }),
            icon: <FeedIcon className='cardIcon' style={{ color: "lightslategray" }} />,
            description: intl.formatMessage({ id: "dashboard.survey.total.description" })
        },
        opened: {
            name: intl.formatMessage({ id: "dashboard.opened" }),
            icon: <VisibilityIcon style={{ color: "blueviolet" }} className='cardIcon' />,
            description: intl.formatMessage({ id: "dashboard.opened.description" })
        },
        completed: {
            name: intl.formatMessage({ id: "dashboard.completed" }),
            icon: <CheckIcon style={{ color: "green" }} className='cardIcon' />,
            description: intl.formatMessage({ id: "dashboard.completed.description" })
        },
        sentSurveyTotal: {
            name: intl.formatMessage({ id: "dashboard.sent.survey.total" }),
            icon: <ForwardToInboxIcon style={{ color: "lightcoral" }} className='cardIcon' />,
            description: intl.formatMessage({ id: "dashboard.sent.survey.total.description" })
        }
    };

    return (
        <div className='row mt-3' style={{ paddingRight: 0 }}>
            {isLoading ?
                Array.from(new Array(4)).map((item, index) => (
                    <div className='col-md-3 col-sm-6 col-xs-12 mt-1' key={index}>
                        <CardElementComponent sx={{ height: "76px", justifyContent: "left" }} >
                            <Skeleton className='mt-2 mx-3' animation="wave" variant="rounded" height={60} width={150} />
                        </CardElementComponent>
                    </div>
                ))
                :
                isError ?
                    <div className='col-md-3 col-sm-6 col-xs-12 mt-1'>
                        <Grid >
                            <CardViewComponent
                                title={'Error'}
                                subtitle={error?.response?.status === 409 ?
                                    <FormattedMessage id="dashboard.no.surveys.error.msg" />
                                    :
                                    error?.response?.status === 404 ?
                                        <FormattedMessage id="dashboard.no.surveys.data.error.btn" />
                                        :
                                        <FormattedMessage id="dashboard.survey.info.error" />
                                }
                                color="error"
                                icon={<FeedIcon className='cardIcon' />}
                            />
                        </Grid>
                    </div>
                    :
                    Object.keys(data).map((key, index) => {
                        if (key === 'pending') return null;
                        return (
                            <div className='col-md-4 col-sm-6 col-xs-12 mt-1' key={index}>
                                <Tooltip
                                    placement="bottom"
                                    arrow
                                    title={names[key].description}>
                                    <Grid >
                                        <CardViewComponent
                                            title={data[key]}
                                            subtitle={names[key].name}
                                            color="success"
                                            icon={names[key].icon}
                                        />
                                    </Grid>
                                </Tooltip>
                            </div>
                        )
                    })
            }
        </div>
    )
}

import React, { useContext, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import StylesContext from './SytlesContextViewSurvey';
import { useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';

export const FileInput = () => {
    const {
        originalLogo,
        setSurveyLogo,
        setSubmitLogo
    } = useContext(StylesContext);
    const intl = useIntl();
    const [fileName, setFileName] = useState(intl.formatMessage({ id: "styles.change.img" }));
    const [file, setFile] = useState(null);

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        //scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        if (selectedFile) {
            if (selectedFile.size > 1048576) {
                alert(intl.formatMessage({ id: "create.customer.styles.error.logo.size" }));
                return;
            }
            if (
                selectedFile.type !== "image/png" &&
                selectedFile.type !== "image/jpeg" &&
                selectedFile.type !== "image/jpg" &&
                selectedFile.type !== "image/svg" &&
                selectedFile.type !== "image/svg+xml" &&
                selectedFile.type !== "image/webp"
            ) {
                alert(intl.formatMessage({ id: "create.customer.styles.error.logo.type" }));
                return;
            }
            if (selectedFile.name.length > 15) {
                setFileName(selectedFile.name.substring(0, 16) + '...');
            } else {
                setFileName(selectedFile.name);
            }
            getBase64(selectedFile).then(base64 => {
                setSurveyLogo(base64);
            });
            setSubmitLogo(selectedFile);
            setFile(selectedFile);
        } else {
            setFileName(intl.formatMessage({ id: "styles.change.img" }));
            setFile(null);
        }
    };

    const handleCancel = () => {
        setFileName(intl.formatMessage({ id: "styles.change.img" }));
        setFile(null);
        setSurveyLogo(originalLogo);
        setSubmitLogo(undefined);
        document.getElementById('inputGroupFile01').value = null;
    };

    return (
        <div className="input-group " style={{ position: 'relative' }}>
            <label
                className="input-group-text"
                style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: 0,
                    width: '100%'
                }}
                htmlFor="inputGroupFile01"
            >
                <ImageIcon /> &nbsp;&nbsp;
                {fileName}
            </label>
            <input
                type="file"
                style={{ display: 'none' }}
                className="form-control"
                id="inputGroupFile01"
                onChange={handleFileChange}
            // onChange={handleLogo}
            />
            {file && (
                <Tooltip title={intl.formatMessage({ id: "delete" })} arrow>
                    <button
                        onClick={handleCancel}
                        style={{
                            position: 'absolute',
                            right: '-3px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            background: 'none',
                            border: 'none',
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: '1.2rem'
                        }}
                    >
                        <DeleteIcon />
                    </button>
                </Tooltip>
            )}
        </div>
    );
}

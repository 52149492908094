import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import axios from "../../../../../api/axios";
import { languageContext } from "../../../../../context/languageContext";
import { useContext, useEffect, useState } from "react";

export const useSurveyQuestions = () => {
    let { userId, customer } = useParams();
    customer = customer.toLowerCase();

    const language = useContext(languageContext);
    const [sending, setSending] = useState(false);
    const [surveyQuestions, setSurveyQuestions] = useState(null);
    const queryClient = useQueryClient();

    //#region Get survey info questions
    const { data: surveyInfo, isLoading: isLoadingQuestions, isError: isErrorQuestions, isFetching: isFetchingQuestions } = useQuery({
        queryKey: ["surveyInfo", userId],
        queryFn: async () => {
            const response = await axios.get(`userquestions/${userId}?customer=${customer}&lang=${language.locale}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        retry: 0,
    });
    const questionsNumber = surveyInfo?.questions?.length || 0;
    useEffect(() => {
        if (surveyInfo) {
            setSurveyQuestions(surveyInfo.questions);
        }
    }, [surveyInfo]);
    //#endregion

    //#region Save answers
    /**
     * Mutation hook to save survey answers.
     * 
     * This hook uses `useMutation` from React Query to handle the process of saving survey answers.
     * It sends a POST request to the `answer` endpoint with the provided values and customer information.
     * 
     * @constant
     * @type {object}
     * @property {function} mutationFn - The function to execute the mutation. It sends a POST request with the survey answers.
     * @property {function} onSuccess - Callback function executed when the mutation is successful. It invalidates the `surveyInfo` query for the current user.
     * @property {function} onError - Callback function executed when the mutation fails. It handles different error statuses and logs the error.
     * 
     * @param {object} values - The survey answers to be saved.
     * @param {string} customer - The customer identifier.
     * @param {string} userId - The user identifier.
     * 
     * @returns {number} - The status code of the response.
     */
    const saveAnswersMutation = useMutation({
        mutationFn: async (values) => {
            setSending(true);
            const response = await axios.post(`answer?customer=${customer}`, values);
            return response.status;
        },
        onError: (error) => {
            if (error.response.status === 400) {
                console.error(error);
            } else if (error.response.status === 409) {
                // console.error(error.response?.data?.text);
                console.error(error);
            } else {
                console.error(error);
            }
            setSending(false);
        }
    });
    //#endregion

    //#region Update user information
    /**
     * Mutation hook to update user information.
     * 
     * @constant
     * @type {Object}
     * @property {Function} mutationFn - The function to perform the mutation. Sends a PUT request to update user data.
     * @property {Function} onSuccess - Callback function executed on successful mutation. Redirects to the main survey page and removes user answers from local storage.
     * @property {Function} onError - Callback function executed on mutation error. Logs the error to the console.
     * @property {Function} onSettled - Callback function executed when the mutation is either successfully completed or encounters an error. Sets the sending state to false.
     */
    const updateUserMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axios.put(`users/${userId}?customer=${customer}`, values);
            return response.status;
        },
        onSuccess: (data, values) => {
            //TODO: Redirect to main survey page
            //delete answers from local storage
            window.localStorage.removeItem(userId);
            queryClient.invalidateQueries(["surveyInfo", userId], { exact: true });
        },
        onError: (error) => {
            if (error.response.status === 400) {
                console.error(error);
            } else {
                console.error(error);
            }
        },
        onSettled: () => {
            setSending(false);
        }
    });
    //#endregion

    //#region Get and Save IP info
    const { data: ipInfo, isLoading: isLoadingIpInfo } = useQuery({
        queryKey: ["ipinfo", userId],
        queryFn: async () => {
            const response = await axios.get(`https://geolocation-db.com/json/d802faa0-10bd-11ec-b2fe-47a0872c6708`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        retry: 0,
    });

    const saveipInfo = useMutation({
        mutationFn: async (values) => {
            const response = await axios.post(`log?customer=${customer}`, values);
            return response.status;
        },
    });
    //#endregion

    const getLanguageOptions = (question, language, questionType = null) => {
        const languageLabel = language === 'es' ? 'options' : 'options' + language;
        //check if all the options are null
        const checkAllNullOptions = question?.[languageLabel].every(option => option === null);
        const options = checkAllNullOptions ? question?.options : question?.[languageLabel];
        if (questionType === "select") {
            //Cuando son tipo select radio o check da problema en el autocomplete si tiene opciones nulas
            return options.filter(option => option === null).length > 0 ? question.options : options;
        }
        return options;
    }

    return {
        surveyInfo,
        surveyQuestions,
        questionsNumber,
        isLoadingQuestions,
        isErrorQuestions,
        isFetchingQuestions,
        saveAnswersMutation,
        updateUserMutation,
        sending,
        ipInfo,
        isLoadingIpInfo,
        saveipInfo,
        getLanguageOptions
    }; // Exportar variables
}

import React from 'react'
import { FormattedMessage } from 'react-intl';

export const LoadingComponent = ({ errorMsg, styles, fontStyles = { color: "whitesmoke" } }) => {
    const backColor = "#051B29";
    document.body.style.backgroundColor = backColor;
    return (
        <div style={styles ? styles : { padding: "20rem 0px" }} align="center">
            {errorMsg ?
                <h2 className="mt-5" style={fontStyles}>
                    {errorMsg}
                </h2>
                :
                <>
                    <span className="loader"></span>
                    <h2 style={fontStyles}>
                        <FormattedMessage
                            id="loading.msg"
                            defaultMessage={'Cargando...'}
                        />
                    </h2>
                </>
            }
        </div>
    )
}

import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import useAuth from '../../CustomHooks/useAuth';
import useLogout from '../../CustomHooks/useLogout';

export const RequireAuth = (props) => {
    const { isAuth, setIsLogin, UserInfo } = useAuth();
    const logout = useLogout();
    const location = useLocation();
    const { allowedRoles } = props;
    const roles = UserInfo?.roles;

    useEffect(() => {
        if (!isAuth?.accessToken) {
            setIsLogin(false);
        }
        // eslint-disable-next-line
    }, []);

    return (
        allowedRoles.some(role => roles?.includes(role))
            ? <Outlet />
            : isAuth?.accessToken
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                :
                logout() &&
                <Navigate to={`/login`} state={{ from: location }} replace />
    );
}
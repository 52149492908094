import { Card, Stack } from '@mui/material'
import React from 'react'

export const CardElementComponent = ({ sx, maxWidth, minWidth, children }) => {
    return (
        <Card
            component={Stack}
            spacing={3}
            direction="row"
            sx={{
                justifyContent: "center",
                borderRadius: 2,
                maxWidth: maxWidth ?? "auto",
                minWidth: minWidth ?? "auto",
                ...sx,
            }}
            className='valuesCard'
        >
            <Stack spacing={0}>
                {children}
            </Stack>
        </Card>
    )
}

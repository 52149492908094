import { useEffect, useState } from "react";

export const handleResize = (name) => {
    const element = document.getElementById(name);
    if (element) {
        return element.clientWidth;
    }
};

export const useResizeChart = (name) => {
    const [width, setWidth] = useState();
    useEffect(() => {
        // Add event listener for window resize
        const handleResizeWrapper = () => {
            const width = handleResize(name);
            setWidth(width);
        }
        window.addEventListener('resize', handleResizeWrapper);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResizeWrapper);
        };
    }, [name]);

    //wait until .wui-side-menu is loaded
    useEffect(() => {
        const cardWith = document.getElementById(name);
        if (cardWith) {
            setWidth(cardWith.clientWidth);
        }
    }, [name]);

    return width;
};

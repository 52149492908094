import React from 'react'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { useResizeChart } from '../../DashboardComponents.js/ResizeCharts';

export const BarChartSurveyResults = ({ data, maxValue, chartName }) => {
    const chartWidth = useResizeChart(chartName);
    return (
        <BarChart
            className='center'
            width={chartWidth - 20}
            height={300}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 0,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="1 3" />
            <XAxis dataKey="answer" />
            <YAxis allowDecimals={false} type='number' domain={[0, maxValue + Math.ceil(maxValue * 0.1)]} />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
    );
}

import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage } from 'react-intl';

export const EditCheckOptions = ({ options, handleOptionChange, handleDelete, handleAddOption, validationErrors, duplicatedOptionValue }) => {
    return (
        <FormGroup row>
            {options.length > 0 && options[0] !== null &&
                options?.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        control={<Checkbox />}
                        label={
                            <>
                                <TextField
                                    id={index.toString()}
                                    error={
                                        duplicatedOptionValue && duplicatedOptionValue === option ?
                                            true
                                            :
                                            validationErrors.options && option === "" && !duplicatedOptionValue
                                                ?
                                                true
                                                :
                                                false
                                    }
                                    variant="standard"
                                    sx={{ width: "150px" }}
                                    onChange={handleOptionChange}
                                    value={option}
                                    helperText={
                                        duplicatedOptionValue && duplicatedOptionValue === option ?
                                            validationErrors.options
                                            :
                                            validationErrors.options && option === "" && !duplicatedOptionValue
                                                ?
                                                validationErrors.options
                                                :
                                                ""
                                    }
                                />
                                <DeleteOutlineIcon className='editButtonIcon' id={index} onClick={handleDelete} />
                            </>
                        }
                    />
                ))}
            <FormControlLabel
                control={<Checkbox />}
                label={
                    <div onClick={handleAddOption}>
                        <strong>
                            <FormattedMessage
                                id="edit.survey.label.question.options.add"
                                defaultMessage={`Agregar opción`}
                            />
                        </strong>
                        <AddIcon className='editButtonIcon' />
                    </div>
                }
            />
            {validationErrors?.options && (options.length === 0 || options.length === 1) &&
                <label className='m-2' style={{ color: "red" }}>{validationErrors.options}</label>
            }
        </FormGroup>
    )
}

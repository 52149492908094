import React from 'react'
import { useSurveyContext } from '../../Context/SurveyCustomHooks/useSurveyContext';
import anyAscii from 'any-ascii';

export const TextQuestion = () => {
    const {
        answers,
        setAnswers,
        activeStep,
        updateAnswers,
        surveyQuestions,
    } = useSurveyContext();
    const question = surveyQuestions[activeStep];
    const maxCharsTextBox = 70;
    const currentAnswer = answers?.find(answer => answer.survey_item_id === question.itemId)?.answer_value;

    const handleTextChange = (e) => {
        if (e.target.value !== "") {
            updateAnswers(question.itemId, anyAscii(e.target.value));
        } else {
            setAnswers(answers.filter(answer => answer.survey_item_id !== question.itemId));
        }
    }

    return (
        <div className="form-group">
            <input
                className="form-control RegularMadeTommy"
                data-hj-allow
                style={{
                    padding: "15px 10px"
                }}
                onChange={handleTextChange}
                value={currentAnswer ?? ''}
                type={question?.valueFormat ? question?.valueFormat : `text`}
                maxLength={question?.maxLength && question?.maxLength > 0 && question?.maxLength < maxCharsTextBox ? question?.maxLength : maxCharsTextBox}
                min={question?.minNumber ? question.minNumber : ""}
                max={question?.maxNumber ? question.maxNumber : ""}
                name={`text`}
                id={`text`}
            />
        </div>
    )
}

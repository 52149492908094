import { Footer } from "../../ReuseComponents/Footer";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdComponent } from "../../UtilsComponents/AdComponent";
import useAxios from "../../../CustomHooks/useAxios";
import axios from "../../../api/axios";
// import Navbar from "./Navbar";
import useLocalStorage from "../../../CustomHooks/useLocalStorage";
import MetaDecorator from "../../UtilsComponents/MetaDecorator";
import { WaitSurvey } from "../UtilsComponents/WaitSurvey";

export const UserForm = () => {
    const navigate = useNavigate();
    var { id, customer } = useParams();// El Id es el source
    customer = customer.toLowerCase();
    document.body.style.backgroundColor = "#f4f4f4";
    // eslint-disable-next-line
    const [resCustomer, error, loading] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: `customer/${id}?customer=${customer}`
    });
    // eslint-disable-next-line
    const [resStores, errorStores, loadingStores] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: `store/${id}?customer=${customer}`
    });
    // eslint-disable-next-line
    const [resLink, errorLink, loadingLink] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        url: `survey/link/${id}?customer=${customer}`
    });

    const { REACT_APP_SERVER } = process.env;
    // eslint-disable-next-line
    const [localTime, setLocalTime] = useLocalStorage("time", "0");

    const [formValues, setFormValues] = useState({
        identification_number: ""
    });
    const [formErrors] = useState({});
    const [isPending, setIsPending] = useState(false);
    const [waitMsg, setWaitMsg] = useState("");
    const [myTime, setMyTime] = useState();

    const [idShop, setIdShop] = useState("");

    //for ipaper
    const [isDisabled, setIsDisabled] = useState(true);
    const [Link, setLink] = useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const postUser = async (values) => {
        try {
            if (myTime === undefined || myTime > 0) {
                setWaitMsg("Por favor espere");
                return;
            } else {
                setLocalTime(new Date().getTime());
            }
            const res = await axios.post(`users?customer=${customer}`, values);
            if (res.data?.id) {
                setWaitMsg("Redirigiendo...");
                navigate(`/survey/${customer}/${res.data.id}`);
            } else {
                setWaitMsg("Error al crear usuario");
            }
        } catch (err) {
            console.error(err);
            setWaitMsg("Error interno al crear usuario");
        }
    };

    useEffect(() => {
        if (Object.keys(resLink).length > 0) {
            setLink(resLink[0].infolink);
        }
    }, [resLink]);

    useEffect(() => {
        if (customer !== "hermeco") return setIdShop("No encontrado");
        if ((id === "ipaper" || id === "podcast" || id === "expertos")) return;
        const capitalizeFirstLetter = str => (str && str.split(" ").map(subStr => subStr[0].toUpperCase() + subStr.slice(1)).join(" ")) || ""
        let initialValues = {
            display_name: null,
            phone: null,
            email: null,
            survey_id: null,
            identification_number: null,
            status: 3,
            store: null,
            store_id: null,
            city: null,
            sent_method: "WEB"
        };
        if (loading === false && loadingStores === false) {
            if (Object.keys(resStores).length > 0) {
                if (resStores[0].store_code === id) {
                    if (resStores[0].survey_id !== null) {
                        initialValues.survey_id = resStores[0].survey_id;
                        initialValues.store = resStores[0].store_code;
                        initialValues.store_id = resStores[0].store_id;
                        initialValues.city = capitalizeFirstLetter(resStores[0].city.toLowerCase());
                        postUser(initialValues);
                    } else {
                        setIdShop("No tiene codigo de encuesta");
                    }
                }
            }
            else if (Object.keys(resCustomer).length > 0) {
                if (!id.toLowerCase().includes("mercadeo") && Object.keys(resCustomer).length > 0) {
                    initialValues.survey_id = resCustomer[customer][0];
                    postUser(initialValues);
                } else {
                    Object.keys(resCustomer).length > 0 ? setIdShop("mercadeo") : setIdShop("No encontrado")
                }
            }
            else {
                setIdShop("No encontrado");
            }
        }
        return () => {
            // cleanup
        }
        // eslint-disable-next-line
    }, [loading, loadingStores, myTime]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsPending(true);
        fetch(`${REACT_APP_SERVER}users?customer=${customer}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: id !== "podcast" && id !== "expertos" ? JSON.stringify({ identification_number: formValues.identification_number, survey_source: id, status: 3, sent_method: "WEB" }) : JSON.stringify({ identification_number: formValues.identification_number, survey_source: id, status: 0, sent_method: "WEB" }),
        }).then(async (res) => {
            if (id !== "podcast" && id !== "expertos") {
                var data = await res.json();
                // Redirect to the Survey page
                navigate(`/survey/${customer}/${data.id}`);
                setIsPending(false);
            } else if (id === "podcast" || id === "expertos") {
                setIsPending(false);
                window.location.href = Link
            }
        })
            .catch((err) => console.error(err));
    }

    return (
        <table border="0" cellPadding="0" cellSpacing="0" width="100%">
            <MetaDecorator
                title={"New Survey | " + id}
                description={"Para nosotros es muy importante conocer tu opinión sobre nuestros productos y servicios. Por favor, ayúdanos a mejorar respondiendo esta encuesta."}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"7Bytes"}
                path={window.location.pathname}
            />
            <tbody>
                <tr>
                    <td bgcolor="#111111" align="center">
                        <table className="tableWith">
                            <tbody>
                                <tr>
                                    <td className="td1" align="center" valign="top">
                                        <a target="_blank" rel="noreferrer" href="https://www.offcorss.com/">
                                            <img className="img1" alt="Logo" src="\images\Logo.png" width="40" height="40" border="0" />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td className="td2" bgcolor="#111111"  >
                        <table className="tableWith" align="center"  >
                            <tbody>
                                <tr>
                                    <td className="tdTitle" align="center"  >
                                        <h1 className="Title mb-3">Bienvenid@</h1>
                                        {/* {idShop=== "" ?  <h3>Cargando...</h3>: idShop !== "No encontrado" && <h3>Por favor ingresa tus datos en la parte inferior</h3>} */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td className="tdBody" align="center" >
                        <table className="tableWith shadow2" >
                            <tbody>
                                <tr>
                                    <td className="tdBody2" align="left"  >
                                        {idShop !== "No encontrado" & (id === "ipaper" || idShop === "mercadeo" || id === "podcast" || id === "expertos") & customer === "hermeco" ?
                                            <form id={`${id}Survey`} onSubmit={handleSubmit}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label id="Label2" className="form-label mb-3" htmlFor="floatingInputCedula">Por favor ingresa tu cedula </label>
                                                                    <div className="form-floating mb-3">
                                                                        <input
                                                                            type="number"
                                                                            name="identification_number"
                                                                            className="form-control"
                                                                            id="floatingInputCedula"
                                                                            placeholder="Cedula"
                                                                            min="1"
                                                                            max="9999999999"
                                                                            required
                                                                            value={formValues.identification_number}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <label htmlFor="floatingInputCedula">Cedula</label>
                                                                    </div>
                                                                    <p style={{ color: "#FF0000" }}>{formErrors.identification_number}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <AdComponent dataAdSlot="9980816009" path={window.location.href} className={window.location.href} />
                                                    {id === "ipaper" &&
                                                        <div className="row mt-4">
                                                            <div className="col-sm-12">
                                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                                                    Para abrir el boletín comercial dar <a target="_blank" rel="noreferrer" style={{ color: "blue" }} onClick={() => setIsDisabled(false)} href={Link}>click aqui</a>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                    {Object.keys(formErrors).length > 0 ?
                                                        <div className="row">
                                                            <div className="d-flex justify-content-center">
                                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                                                    <p style={{ color: "#FF0000", textAlign: "center" }}>Numero de errores: {Object.keys(formErrors).length}</p>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        : <div></div>}
                                                    {id === "podcast" || id === "expertos" ?
                                                        <div className="row">
                                                            <div className="col text-center mt-3">
                                                                {!isPending && <button type="submit" className="btn btn-warning btn-lg shadow">Enviar</button>}
                                                                {isPending && <button type="submit" className="btn btn-warning btn-lg shadow">Enviando...</button>}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col text-center mt-3">
                                                                {!isPending && <button type="submit" disabled={idShop !== "mercadeo" ? isDisabled : false} className="btn btn-warning btn-lg shadow">Enviar</button>}
                                                                {isPending && <button type="submit" disabled className="btn btn-warning btn-lg shadow">Enviando...</button>}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </form> :
                                            idShop === "No encontrado" ?
                                                <div align="center"><h1>Url invalida</h1></div>
                                                :
                                                idShop === "No tiene codigo de encuesta" ?
                                                    <div align="center"><h1>No tiene codigo de encuesta</h1></div>
                                                    :
                                                    idShop === "No existe el codigo de tienda" ?
                                                        <div align="center"><h1>No existe el codigo de tienda</h1></div>
                                                        :
                                                        id !== "ipaper" & customer !== "customer" ?
                                                            <div align="center">{waitMsg !== "" ?
                                                                <WaitSurvey myTime={myTime} setMyTime={setMyTime} />
                                                                :
                                                                <h3>Cargando...</h3>}
                                                            </div>
                                                            :
                                                            customer !== "hermeco" ?
                                                                <div align="center"><h3>Url Invalida</h3></div>
                                                                :
                                                                <div align="center"></div>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <Footer />
                </tr>
            </tbody>
        </table >
    );
}
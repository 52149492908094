import { useIntl } from "react-intl";

export const TableLanguages = () => {
    const intl = useIntl();
    const actionLanguage = {
        actions: "",
        and: "",
        cancel: "",
        changeFilterMode: "",
        changeSearchMode: "",
        clearFilter: "",
        clearSearch: "",
        clearSort: "",
        clickToCopy: "",
        collapse: "",
        collapseAll: "",
        columnActions: "",
        copiedToClipboard: "",
        dropToGroupBy: "",
        edit: "",
        expand: "",
        expandAll: "",
        filterArrIncludes: "",
        filterArrIncludesAll: "",
        filterArrIncludesSome: "",
        filterBetween: "",
        filterBetweenInclusive: "",
        filterByColumn: "",
        filterContains: "",
        filterEmpty: "",
        filterEndsWith: "",
        filterEquals: "",
        filterEqualsString: "",
        filterFuzzy: "",
        filterGreaterThan: "",
        filterGreaterThanOrEqualTo: "",
        filterInNumberRange: "",
        filterIncludesString: "",
        filterIncludesStringSensitive: "",
        filterLessThan: "",
        filterLessThanOrEqualTo: "",
        filterMode: "",
        filterNotEmpty: "",
        filterNotEquals: "",
        filterStartsWith: "",
        filterWeakEquals: "",
        filteringByColumn: "",
        goToFirstPage: "",
        goToLastPage: "",
        goToNextPage: "",
        goToPreviousPage: "",
        grab: "",
        groupByColumn: "",
        groupedBy: "",
        hideAll: "",
        hideColumn: "",
        max: "",
        min: "",
        move: "",
        noRecordsToDisplay: "",
        noResultsFound: "",
        of: "",
        or: "",
        pinToLeft: "",
        pinToRight: "",
        resetColumnSize: "",
        resetOrder: "",
        rowActions: "",
        rowNumber: "",
        rowNumbers: "",
        rowsPerPage: "",
        save: "",
        search: "",
        selectedCountOfRowCountRowsSelected: "",
        select: "",
        showAll: "",
        showAllColumns: "",
        showHideColumns: "",
        showHideFilters: "",
        showHideSearch: "",
        sortByColumnAsc: "",
        sortByColumnDesc: "",
        sortedByColumnAsc: "",
        sortedByColumnDesc: "",
        thenBy: "",
        toggleDensity: "",
        toggleFullScreen: "",
        toggleSelectAll: "",
        toggleSelectRow: "",
        toggleVisibility: "",
        ungroupByColumn: "",
        unpin: "",
        unpinAll: "",
        unsorted: "",
    };

    for (const [key] of Object.entries(actionLanguage)) {
        actionLanguage[key] = intl.formatMessage({ id: key });
    };


    return actionLanguage;
};
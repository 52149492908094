import React, { useState } from "react";
import EnglishMessages from '../Languages/en.json';
import SpanishMessages from '../Languages/es.json';
import FrenchMessages from '../Languages/fr.json';
import { IntlProvider } from "react-intl";
import useLocalStorage from "../CustomHooks/useLocalStorage";

const languageContext = React.createContext();

const LanguageProvider = ({ children }) => {
    const userLang = navigator.language || navigator.userLanguage;
    const [localLang, setLocalLang] = useLocalStorage("localLang", userLang);
    const languages = {
        "en": EnglishMessages,
        "es": SpanishMessages,
        "fr": FrenchMessages
    };
    const selectedLanguage = Object.keys(languages).find(l => l === localLang) || "es";
    const [language, setLanguage] = useState(languages[selectedLanguage]);
    const [locale, setLocale] = useState(selectedLanguage);

    const changeLanguage = (lang) => {
        switch (lang) {
            case "en":
                setLanguage(EnglishMessages);
                setLocale("en");
                setLocalLang("en");
                break;
            case "es":
                setLanguage(SpanishMessages);
                setLocale("es");
                setLocalLang("es");
                break;
            case "fr":
                setLanguage(FrenchMessages);
                setLocale("fr");
                setLocalLang("fr");
                break;
            default:
                setLanguage(SpanishMessages);
                setLocale("es");
                setLocalLang("es");
        }
    }


    return (
        <languageContext.Provider value={{ changeLanguage, locale }}>
            <IntlProvider locale={locale} messages={language}>
                {children}
            </IntlProvider>
        </languageContext.Provider >
    );
}

export { LanguageProvider, languageContext };
import { Box, Card, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const CardViewComponent = ({ subtitle, title, icon, color = 'primary', sx, ...other }) => {
    const matches = useMediaQuery('(min-width:900px)');

    return (
        <Card
            component={Stack}
            spacing={3}
            direction="row"
            sx={{
                px: 1,
                py: 1,
                borderRadius: 2,
                ...sx,
            }}
            {...other}
            className='valuesCard'
            width={title === 'Error' ? 400 : "auto"}
        >
            {icon && title === 'Error' ?
                <Box color={"red"}>
                    <ErrorOutlineIcon className='cardIcon' />
                </Box>
                :
                <Box>
                    {icon}
                </Box>
            }
            <Stack spacing={0} >
                <Typography variant={matches ? "h4" : "h5"}>
                    <strong style={{ color: title === 'Error' ? "red" : "black" }}>
                        {(title)}
                    </strong>
                </Typography>
                <Typography variant="subtitle2" >
                    {subtitle}
                </Typography>
            </Stack>
        </Card>
    );
}

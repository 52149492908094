import { FormattedMessage, useIntl } from "react-intl"
import useLocalStorage from "../../../../CustomHooks/useLocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { LoadingComponent } from "./LoadingComponent";
import { MainFooter } from "../../../ReuseComponents/MainFooter";

export const RestaurantPostSurvey = ({ url, logo, headerBackground, bodyBackground, fontColor, category, survey }) => {
    const navigate = useNavigate();
    const { REACT_APP_SERVER } = process.env;
    const intl = useIntl();
    const { id, customer } = useParams();
    const [clickGoogleReview, setClickGoogleReview] = useLocalStorage(`ClickGR${id}`, 0);
    const [placeId, setPlaceId] = useState();
    const [showGoogleReview, setShowGoogleReview] = useState(true);
    const [storeName, setStoreName] = useState("");
    const [wait, setWait] = useState(true);
    document.body.style.backgroundColor = headerBackground;

    const forReview = () => {
        // alert("Gracias por contestar esta encuesta")
        setShowGoogleReview(false);
        setClickGoogleReview(1);
    }

    useEffect(() => {
        if (survey.store) {
            fetch(`${REACT_APP_SERVER}store/${survey.store}?customer=${customer}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(async (res) => {
                //#region Get the restaurant answer name
                let restaurantAnswer = "";
                if (survey.questions[0].textLabel?.includes("restaurante") || survey.questions[0].textLabel?.toLowerCase().includes("en que sede")) {
                    try {
                        const response = await axios.get(`answer/question/${survey.questions[0].itemId}/${id}?customer=${customer}`);
                        if (response.data?.answer) {
                            restaurantAnswer = response.data.answer;
                        }
                    }
                    catch (err) {
                        //console.error(err);
                    }
                }
                //#endregion
                const data = await res.json();
                if (!data.text) {
                    data.forEach(store => {
                        if (store.place_id) {
                            //check if store place_id is a json
                            const isJson = (str) => {
                                try {
                                    JSON.parse(str);
                                } catch (e) {
                                    return false;
                                }
                                return true;
                            }
                            if (restaurantAnswer !== "" && isJson(store.place_id)) {
                                const placeJson = JSON.parse(store.place_id);
                                //get the entry of the object when the key is equal to the answer of the question
                                const entry = placeJson[restaurantAnswer.toLowerCase()] ?? placeJson[restaurantAnswer];
                                setStoreName(restaurantAnswer);
                                setPlaceId(entry);
                            } else {
                                setStoreName(store.name);
                                setPlaceId(store.place_id);
                            }
                        }
                    });
                }
            }).catch((err) => {
                console.error(err);
                // Sentry.captureException(err);
            }).finally(() => {
                setWait(false);
            });
        } else {
            setWait(false);
        }
    }, [])

    const handleCreateSurvey = async () => {
        const confirmBtn = window.confirm(intl.formatMessage({ id: "create.new.survey.msg" }));
        if (confirmBtn) {
            navigate(`/newsurvey/${customer}/${survey.store ? survey.store : survey.name}`, {});
        }
    }
    if (wait) {
        return (
            <LoadingComponent errorMsg={null} />
        )
    }

    return (
        <>
            <section id="hirev2" style={{ background: bodyBackground, padding: "2%" }}>
                <div className="container" align="center" style={{ background: headerBackground, borderRadius: "20px", padding: "50px 0% 50px 0%" }}>
                    <a target="_blank" style={{ outline: "none" }} rel="noreferrer" href={url}>
                        <img className="img1" alt="Logo" src={logo} border="0" />
                    </a>
                </div>
                <div className="container" style={{ borderRadius: "20px", padding: "10rem 0rem", minHeight: "480px" }}>
                    <div>
                        <div className="tdBody" style={{ padding: "0" }} align="center"  >
                            <div className="" border="0" cellPadding="0" cellSpacing="0" >
                                <div>
                                    <>
                                        <div>
                                            <div className="tdBody3" align="center"  ><br></br>
                                                {placeId ?
                                                    showGoogleReview && clickGoogleReview === 0 ?
                                                        <div id="review">
                                                            <h3 style={{ color: fontColor }}>
                                                                <FormattedMessage
                                                                    id="rating.msg"
                                                                    values={
                                                                        {
                                                                            restaurantName: storeName
                                                                        }
                                                                    }
                                                                    defaultMessage="¿Qué puntuación le darías a ?"
                                                                />
                                                            </h3>
                                                            <div>
                                                                <div className="star-wrapper" style={{ color: "white" }}>
                                                                    <a id={`google-review-${customer}-${survey.store}`} onClick={forReview} className="fas fa-star s1" target="blank" href={placeId?.includes("tripadvisor") ? placeId : `https://search.google.com/local/writereview?placeid=${placeId}`}><p style={{ margin: "0px" }} /></a>
                                                                    <a href="#4" className="fas fa-star s2" onClick={forReview}><p style={{ margin: "0px" }} /></a>
                                                                    <a href="#3" className="fas fa-star s3" onClick={forReview}><p style={{ margin: "0px" }} /></a>
                                                                    <a href="#2" className="fas fa-star s4" onClick={forReview}><p style={{ margin: "0px" }} /></a>
                                                                    <a href="#1" className="fas fa-star s5" onClick={forReview}><p style={{ margin: "0px" }} /></a>
                                                                </div>
                                                                <p className="mt-3" style={{ color: fontColor }}>
                                                                    <FormattedMessage
                                                                        id="rating.msg.description"
                                                                    />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        :
                                                        survey.name ?
                                                            <h1 className="masthead-subheading " style={{ color: fontColor }}>
                                                                <FormattedMessage
                                                                    id="complete.survey.msg.name"
                                                                    values={
                                                                        {
                                                                            name: survey.name
                                                                        }
                                                                    }
                                                                />
                                                            </h1>
                                                            :
                                                            <h1 className="masthead-subheading " style={{ color: fontColor }}>
                                                                <FormattedMessage
                                                                    id="complete.survey.msg"
                                                                />
                                                            </h1>
                                                    :
                                                    survey.name ?
                                                        <h1 className="masthead-subheading mt-5" style={{ color: fontColor }}>
                                                            <FormattedMessage
                                                                id="complete.survey.msg.name"
                                                                values={
                                                                    {
                                                                        name: survey.name
                                                                    }
                                                                }
                                                            />
                                                        </h1>
                                                        :
                                                        <h1 className="masthead-subheading mt-5" style={{ color: fontColor }}>
                                                            <FormattedMessage
                                                                id="complete.survey.msg"
                                                            />
                                                        </h1>
                                                }
                                                {category?.includes("hotel") &&
                                                    <>
                                                        <br />
                                                        <button onClick={handleCreateSurvey} style={{ color: "black" }} className="btn btn-info">
                                                            <FormattedMessage
                                                                id="create.new.survey"
                                                            />
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                </div>
                                {/* {customerStyles[Object.keys(customerStyles)].plan.includes("piloto") &&
                            <AdComponent dataAdSlot="9228488024" path={window.location.href} />
                        } */}
                            </div>
                        </div>
                    </div>
                </div >
            </section>
            <MainFooter backColor={headerBackground} />
        </>
    )
}

import { TextField } from '@mui/material'
import { useIntl } from 'react-intl';

export const EditQuestionText = ({ questionText, setQuestionText, validationErrors }) => {
    const intl = useIntl();
    return (
        <TextField sx={{ mt: 2 }}
            fullWidth
            error={validationErrors?.textField ? true : false}
            maxRows={4}
            multiline
            id="inputQuestion"
            label={intl.formatMessage({ id: "edit.survey.label.question.text" })}
            onChange={(e) => setQuestionText(e.target.value)}
            defaultChecked={""}
            value={questionText}
            helperText={validationErrors?.textField ? validationErrors.textField : ""}
        />
    )
}

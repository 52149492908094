import { Autocomplete, TextField } from '@mui/material'
import { QuestionsMarksInfo } from './QuestionsMarksInfo'

export const SelectCheckQuestion = ({ index, question, itemLabel, optionLabel }) => {
    return (
        <>
            <label className="form-label mb-3" htmlFor={`selectcheck${(index + 1)}${(question.id)}`}>
                {`${(index + 1)}. ${question[itemLabel]}`}
                <QuestionsMarksInfo required={question.required} type={question.question_type} />
            </label>
            <br />
            <Autocomplete
                className='mt-2'
                multiple
                limitTags={2}
                options={question[optionLabel]}
                getOptionLabel={opcion => opcion}
                id={`selectcheck${(index + 1)}${(question.id)}`}
                // defaultValue={}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label=""
                        placeholder="Seleccione una o varias opciones"
                    />
                )}
            />
        </>
    )
}
const DownloadQrToPng = (id, qrName = "QR", qrSize) => {
    const svg = document.getElementById(id);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    canvas.width = qrSize;
    canvas.height = qrSize;
    const ctx = canvas.getContext("2d");
    const img = document.createElement("img");
    img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));
    img.onload = function () {
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = `${qrName}.png`;
        downloadLink.href = pngFile;
        downloadLink.click();
    }
};

const DownloadQrToSvg = (id, qrName = "QR") => {
    const svg = document.getElementById(id)
    //create another svg node with the same attributes
    const svgNode = svg.cloneNode(true);
    //remove style element
    svgNode.removeAttribute("style");

    const svgData = new XMLSerializer().serializeToString(svgNode);
    const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = `${qrName}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export {
    DownloadQrToPng,
    DownloadQrToSvg
}

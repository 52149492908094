import React, { useCallback, useState } from 'react'
import { styled } from '@mui/material/styles';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate';
import { useIntl } from 'react-intl';
import useAuth from '../../CustomHooks/useAuth';

export const AndroidSwitch = ({ label, forTooltip, id, checked, idNumber }) => {
    const { defaultCustomer } = useAuth();
    const intl = useIntl();
    const query = new URLSearchParams(window.location.search);
    const survey = query.get("survey");
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    const updateSurveyMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axiosPrivate.put(`question/actions/${idNumber}?customer=${defaultCustomer}`, values)
            return response.data
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["surveyQuestions", survey], { exact: true });
        },
        onError: (error) => {
            console.error(error);
            alert(intl.formatMessage({ id: "edit.survey.msg.question.error" }));
        }
    });
    const [checkedQuestion, setCheckedQuestion] = useState(checked);

    const handleChange = (event) => {
        setCheckedQuestion(prev => !prev);
        let values = {};
        if (id.includes("enabled")) {
            values = {
                enabled: event.target.checked,
            }
        } else {
            values = {
                required: event.target.checked,
            }
        }
        updateSurveyMutation.mutate(values)
    }

    //!Esto puede ser un problema despues
    //eslint-disable-next-line 
    const Android12Switch = useCallback(
        styled(Switch)(({ theme }) => ({
            padding: 8,
            '& .MuiSwitch-track': {
                borderRadius: 22 / 2,
                '&:before, &:after': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: 16,
                    height: 16,
                },
                '&:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                        theme.palette.getContrastText(theme.palette.primary.main)
                    )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                    left: 12,
                },
                '&:after': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                        theme.palette.getContrastText(theme.palette.primary.main)
                    )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                    right: 12,
                },
            },
            '& .MuiSwitch-thumb': {
                boxShadow: 'none',
                width: 16,
                height: 16,
                margin: 2,
            },
        })),
        []
    );
    return (
        <Tooltip title={forTooltip}>
            <FormControlLabel
                className=''
                control={<Android12Switch id={id} key={label} checked={checkedQuestion} onChange={handleChange} />}
                label={label}
            />
        </Tooltip>
    )
}

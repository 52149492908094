import React from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, LinearProgress, Tooltip } from '@mui/material';

export const SurveyCards = ({ image, name, styles, icon, click, checkClickState }) => {
    //check if any survey is clicked
    const allClicks = Object.values(checkClickState).some((val) => val === true);
    const checkClick = checkClickState[name];
    //This is to disable all other cards when one is clicked
    const validate = allClicks && !checkClick;
    return (
        <Tooltip title={checkClick || validate ? "" : name} >
            <Card sx={{ maxWidth: 245, minWidth: 245, padding: 0, maxHeight: 180, marginRight: "15px", opacity: validate ? 0.6 : 1, }} onClick={checkClick || validate ? undefined : click}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        image={image}
                        alt="Survey Image"
                        sx={{
                            width: '100%',
                            height: '140px',
                            cursor: checkClick || validate ? "default" : "pointer",
                            ...styles
                        }}
                    />
                    <LinearProgress color="info" style={{ visibility: !checkClick ? "hidden" : "visible" }} />
                    <Typography gutterBottom variant="h6" component="div" sx={{ paddingLeft: "5px", paddingRight: "5px", fontSize: "18px" }}>
                        <i style={{ color: "gray" }}>{icon}</i> {name.length > 20 ? name.substring(0, 20) + "..." : name}
                    </Typography>
                    {/* <CardContent sx={{maxHeight:"30px"}}>
                </CardContent> */}
                </CardActionArea>
            </Card>
        </Tooltip>
    )
}

import { Box, Skeleton } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const TableLoadingSkeleton = ({ isLoading, errorMsg }) => {
    const intl = useIntl();
    const columnsLoading = useMemo(
        () => [
            {
                accessorKey: 'firstName',
                header: <Skeleton variant="rectangular" width={100} height={30} />,
            },
            {
                accessorKey: 'lastName',
                header: <Skeleton variant="rectangular" width={100} height={30} />,
            },
            {
                accessorKey: 'address',
                header: <Skeleton variant="rectangular" width={100} height={30} />,
            },
            {
                accessorKey: 'state',
                header: <Skeleton variant="rectangular" width={100} height={30} />,
            },
        ],
        [],
    );

    return (
        <MaterialReactTable
            key={1}
            columns={columnsLoading}
            data={[]}
            initialState={{
                pagination: {
                    pageSize: 5,
                },
                density: 'comfortable',
                columnVisibility: { id: false },
                sorting: [
                    { id: 'creation_date', desc: true }, //sort by state in ascending order by default
                ]
            }}
            positionToolbarAlertBanner="bottom"
            muiTablePaginationProps={{
                rowsPerPageOptions: [2, 5, 10, 25, 50, 100],
                showFirstButton: false,
                showLastButton: false
            }}
            state={{
                isLoading: isLoading,
                showAlertBanner: errorMsg,
            }}
            muiToolbarAlertBannerProps={
                errorMsg
                    ? {
                        color: 'error',
                        children: intl.formatMessage({ id: "get.data.error.msg" }),
                    }
                    : undefined
            }
            renderTopToolbarCustomActions={({ table }) => (
                isLoading &&
                <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                    <Skeleton variant="rectangular" width={200} height={40} />
                    <Skeleton variant="rectangular" width={180} height={40} />
                </Box>
            )}
        />
    )
};
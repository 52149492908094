import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ShuffleOutlinedIcon from '@mui/icons-material/ShuffleOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';

const myStyles = {
    fontSize : "19px"
}

export const QuestionsMarksInfo = ({ required, type }) => {
    let mark = {
        1: <LockOutlinedIcon style={myStyles}/>,
        3: <ShuffleOutlinedIcon style={myStyles}/>,
    };
    return (
        <>
            {required && <b className="text-danger"> *</b>}
            <b className="text-danger"> {mark[type]}</b>
        </>
    )
}

import { Fab, Tooltip } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage, useIntl } from 'react-intl';
import useAuth from '../../../CustomHooks/useAuth';

export const CreateNewQuestion = ({ setOpenModal, setEditQuestion }) => {
    const { isViewRole } = useAuth();
    const quetionFormat = {
        enabled: true,
        item_class: null,
        item_label: "",
        item_label_en: null,
        item_label_fr: null,
        item_order: 1,
        item_type: "Text Box",
        max_length: null,
        min_number: null,
        max_number: null,
        options: [],
        options_en: [],
        options_fr: [],
        pack_name: null,
        question_type: 2,
        required: false,
        value_format: null,
        create: true,
    }
    const intl = useIntl();

    return (
        <>
            <div className='text-center'>
                <h4>
                    <FormattedMessage
                        id="empty.survey.msg"
                        defaultMessage={`Esta encuesta no tiene preguntas, por favor crea una nueva pregunta`}
                    />
                </h4>
            </div>
            <br />
            {!isViewRole &&
                <div className='text-center'>
                    <Tooltip arrow placement="top" title={intl.formatMessage({ id: "edit.survey.question.title.create" })} >
                        <Fab className='' color="primary" aria-label="add" onClick={() => {
                            setEditQuestion(quetionFormat);
                            setOpenModal(true);
                        }}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div>
            }
        </>
    )
}

import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

const useFetch = (url, customer, lang) => {
    const intl = useIntl();
    const [data, setData] = useState(null);
    const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const abortCont = new AbortController();
        setTimeout(() => {
            fetch(url + customer + "&lang=" + lang, { signal: abortCont.signal })
                .then(async res => {
                    if (!res.ok) { // error coming back from server
                        const error = await res.json();
                        if (error?.text?.includes("Id")) {
                            throw Error(intl.formatMessage({ id: "survey.not.found.id.msg" }));
                        } else {
                            throw Error(intl.formatMessage({ id: "survey.server.error" }));
                        }
                    }
                    return res.json();
                })
                .then(data => {
                    setIsLoadingQuestions(false);
                    setData(data);
                    setError(null);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        // console.log('fetch aborted')
                    } else {
                        // auto catches network / connection error
                        setIsLoadingQuestions(false);
                        setError(err.message);
                    }
                })
        }, 1000);
        // abort the fetch
        return () => abortCont.abort();
    }, [customer]);
    return { data, isLoadingQuestions, error };
}
export default useFetch;
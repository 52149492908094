import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export const SnackBarComponent = (
    {
        position,
        transition,
        openSnackBar,
        color,
        handleClose,
        msg,
        time
    }
) => {
    const { vertical, horizontal } = position;
    return (
        <Snackbar
            open={openSnackBar}
            autoHideDuration={time ? time : null}
            onClose={handleClose}
            TransitionComponent={transition}
            anchorOrigin={{ vertical, horizontal }}
            key={vertical + horizontal}>
            <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                {msg}
            </Alert>
        </Snackbar>
    )
}

// import { useTheme } from '@emotion/react';
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage, useIntl } from 'react-intl';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const AddMails = ({
    alertEmail,
    sendEmails,
    setSendEmails,
    listName,
    setListName,
    selectedListEmail,
    editMails,
    setEditMails
}) => {
    const intl = useIntl();
    // const theme = useTheme();
    const [currentEmails, setCurrentEmails] = useState(alertEmail?.split(',').map(email => email.trim()) ?? []);
    const [newEmail, setNewEmail] = useState('');
    const [deselectedNames, setDeselectedNames] = useState([]);


    useEffect(() => {
        // Seleccionar todos los nombres inicialmente Para que solo lo haga una vez
        setSendEmails(currentEmails);
        // eslint-disable-next-line
    }, []);

    const handleChange = (event) => {
        const { target: { value } } = event;
        const selectedNames = typeof value === 'string' ? value.split(',') : value;

        // Actualizar la lista de nombres deseleccionados
        const newlyDeselected = sendEmails.filter(name => !selectedNames.includes(name));
        setDeselectedNames((prev) => [...prev, ...newlyDeselected]);
        setSendEmails(selectedNames);
    };
    const handleAddEmail = () => {
        //Validate with regex the email
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;
        if (!emailRegex.test(newEmail)) {
            alert('Email no válido');
            return;
        }
        if (newEmail && !currentEmails?.includes(newEmail)) {
            setSendEmails((prevSelected) => {
                // Mantener los seleccionados anteriores (excepto los deseleccionados) y agregar el nuevo nombre
                const updatedSelection = [
                    ...prevSelected,
                    newEmail,
                ];
                return updatedSelection;
            });
            setCurrentEmails([...currentEmails, newEmail]);
            setNewEmail('');
        }
    };

    const handleEditMails = () => {
        //toggle edit mails
        setEditMails(!editMails);
    };

    return (
        <div>
            {currentEmails.length > 0 &&
                <>
                    {selectedListEmail === 'Add' &&
                        <TextField
                            className='mb-3'
                            autoFocus
                            required
                            margin="dense"
                            id="image"
                            name="Image link"
                            label={
                                <label>
                                    <FormattedMessage
                                        id="alerts.emails.list.name"
                                    />
                                </label>
                            }
                            type="text"
                            fullWidth
                            variant="outlined"
                            error={false}
                            value={listName}
                            onChange={(e) => setListName(e.target.value)}
                        // helperText="Link de la imagen a mostrar."
                        />
                    }
                    <Box display="flex" gap={2}>
                        <FormControl sx={{ width: (selectedListEmail !== 'Add' && !editMails) ? "80%" : "100%" }}>
                            <InputLabel id="mails">{(selectedListEmail !== 'Add' && !editMails) ? intl.formatMessage({ id: "alerts.emails.read.only" }) : intl.formatMessage({ id: "alerts.emails" })}</InputLabel>
                            <Select
                                labelId="mails"
                                id="mailSelect"
                                multiple
                                value={sendEmails}
                                disabled={(selectedListEmail !== 'Add' && !editMails)}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-emails" label={(selectedListEmail !== 'Add' && !editMails) ? intl.formatMessage({ id: "alerts.emails.read.only" }) : intl.formatMessage({ id: "alerts.emails" })} />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {currentEmails?.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {selectedListEmail !== 'Add' &&
                            <Button style={{ width: "30%" }} type="submit" onClick={handleEditMails}>
                                <EditIcon /> &nbsp; <FormattedMessage
                                    id="edit"
                                />
                            </Button>
                        }
                    </Box>
                </>
            }
            {(selectedListEmail === 'Add' || editMails) &&
                <Box display="flex" gap={2} className="mt-3">
                    <TextField
                        label={intl.formatMessage({ id: "alerts.add.email" })}
                        variant="outlined"
                        value={newEmail}
                        style={{ width: "80%" }}
                        onChange={(e) => setNewEmail(e.target.value)}
                    />
                    <Button onClick={handleAddEmail} style={{ width: "25%" }} type="submit">
                        <AddRoundedIcon /> &nbsp; <FormattedMessage
                            id="add"
                        />
                    </Button>
                </Box>
            }
        </div>
    );
}

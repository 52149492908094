export const TextResults = ({ data }) => {
    return (
        <div>
            <ul>
                {data.map((item, index) => (
                    <li key={index}>
                        <b>{item.answer}</b> : {item.count}
                    </li>
                ))}
            </ul>
        </div>
    )
}

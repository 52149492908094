const dependentvalidation = (dependentQuestions, answers, userId, openItem) => {
    const forOtherOptions = process.env.REACT_APP_FOR_OTHERS_OPTIONS.replace(/\s/g, "").replace("'", "").split(",");
    if (dependentQuestions.itemType === 'Radio') {
        if (dependentQuestions.values.find(value => value === true)) {
            if (forOtherOptions.includes(dependentQuestions.options[dependentQuestions.values.indexOf(true)].toLowerCase())) {
                if (dependentQuestions.values[dependentQuestions.values.length - 1] !== true && dependentQuestions.values[dependentQuestions.values.length - 1] !== false) {
                    answers.push({
                        id: userId,
                        survey_item_id: dependentQuestions.itemId,
                        answer_value: dependentQuestions.values[dependentQuestions.values.length - 1]
                    });
                } 
            } else {
                answers.push({
                    id: userId,
                    survey_item_id: dependentQuestions.itemId,
                    answer_value: dependentQuestions.options[dependentQuestions.values.indexOf(true)]
                });
            }
            if (dependentQuestions.depentId.length > 0) {
                dependentQuestions.depentId.forEach((depentQ) => {
                    if (dependentQuestions.options[dependentQuestions.values.indexOf(true)] === depentQ.optionvalue) {
                        if (depentQ.dependentQuestion) {
                            dependentvalidation(depentQ.dependentQuestion, answers, userId, dependentQuestions.itemId);
                        }
                    }
                });
            }
        }
    }
    if (dependentQuestions.itemType === 'Check') {
        let checkedValues = [];
        dependentQuestions.values.forEach((value, index) => {
            if (value) {
                checkedValues.push(index);
            }
        });
        const values = checkedValues.map(index => dependentQuestions.options[index]);
        if (values.length > 0) {
            values.forEach(value => {
                if (value.toLowerCase() === "otros" || value.toLowerCase() === "otro" || value.toLowerCase() === "otr@s" || value.toLowerCase() === "otra" || value.toLowerCase() === "otras") {
                    let OtrosValues = dependentQuestions.values[`checkOpt${dependentQuestions.itemId}${openItem}`];
                    if (OtrosValues) {
                        let finalValues = OtrosValues.split(",");
                        finalValues.forEach(finalVal => {
                            finalVal = finalVal.trim();
                            if (finalVal.length > 0 && finalVal !== "") {
                                answers.push({
                                    id: userId,
                                    survey_item_id: dependentQuestions.itemId,
                                    answer_value: finalVal
                                });
                            }
                            // Se Comenta porque no se estan haciendo las validaciones para las dependientes
                            // else{
                            //     errors[dependentQuestions.itemId] = 'Por favor especifique de manera correcta para quien compra.'
                            // }

                        });
                    }
                } else {
                    answers.push({
                        id: userId,
                        survey_item_id: dependentQuestions.itemId,
                        answer_value: value
                    });
                }
            });
        }
    }
    if (dependentQuestions.itemType === 'Text Area') {
        if (dependentQuestions.value !== '') {
            answers.push({
                id: userId,
                survey_item_id: dependentQuestions.itemId,
                answer_value: dependentQuestions.value
            });
        }
    }
    if (dependentQuestions.itemType === 'Text Box') {
        if (dependentQuestions.value !== '') {
            answers.push({
                id: userId,
                survey_item_id: dependentQuestions.itemId,
                answer_value: dependentQuestions.value
            });
        }
    }
}

export default dependentvalidation;
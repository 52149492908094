import { CardElementComponent } from '../UtilsComponents/CardElementComponent'
import { FormattedMessage, useIntl } from 'react-intl'
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { useResizeChart } from "./ResizeCharts";
import { useState } from 'react';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                {new Date(payload.value).toLocaleString('default', { day: 'numeric', month: 'short', timeZone: 'UTC' })}
            </text>
        </g>
    );
}

export const SentSurveysStackedBarChart = ({ chartName, data, isLoading, isError, error }) => {
    const intl = useIntl();
    const chartWidth = useResizeChart(chartName);
    const [selectInfo, setSelectInfo] = useState("completed");

    const rederFormatedTooltip = (value, name, props) => {
        if (selectInfo === "completed") {
            return [value, name === 'completed' ? intl.formatMessage({ id: "dashboard.completed" }) : intl.formatMessage({ id: "dashboard.opened" })]
        } else {
            return [value, name === 'sent' ? intl.formatMessage({ id: "dashboard.sent" }) : intl.formatMessage({ id: "dashboard.sending.error" })]
        }
    }
    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry;
        if (selectInfo === "completed") {
            return <span style={{ color }}>{value === 'completed' ? intl.formatMessage({ id: "dashboard.completed" }) : intl.formatMessage({ id: "dashboard.opened" })}</span>;
        } else {
            return <span style={{ color }}>{value === 'sent' ? intl.formatMessage({ id: "dashboard.sent" }) : intl.formatMessage({ id: "dashboard.sending.error" })}</span>;
        }
    };

    return (
        <CardElementComponent sx={{ height: "346px" }} >
            <h2 className='text-center my-2' style={{ paddingTop: "10px" }}>
                <FormattedMessage id="dashboard.survey.by.date" />
                <FormControl className='mx-2' style={{ float: "right", right: "1rem", minWidth: "80px", left: (isError ? "1rem" : "-20px") }} size="small">
                    <InputLabel id="SurveyNumberSelect"><RemoveRedEyeTwoToneIcon /> Mostrar</InputLabel>
                    <Select
                        labelId="SurveyNumberSelect"
                        id="Mostrar"
                        value={selectInfo}
                        label="sho Mostrar"
                        onChange={(e) => setSelectInfo(e.target.value)}
                    >
                        <MenuItem key={'completed'} value={"completed"}>
                            <FormattedMessage id="dashboard.completed" />
                        </MenuItem>
                        <MenuItem key={'sent'} value={"sent"}>
                            <FormattedMessage id="dashboard.sent" />
                        </MenuItem>
                    </Select>
                </FormControl>
            </h2>
            {
                (isLoading) ?
                    <Skeleton animation="wave" variant="rounded" height={270} width={chartWidth - 70} />
                    :
                    isError ?
                        <h4 className='text-center' style={{ color: error?.response?.status === 404 ? "orange" : "red" }}>
                            {error?.response?.status === 404 ?
                                <FormattedMessage id="dashboard.no.surveys.data.error.btn" />
                                :
                                <FormattedMessage id="dashboard.survey.info.error" />}
                        </h4>
                        :
                        <BarChart
                            width={chartWidth}
                            height={300}
                            data={data}
                            margin={{
                                top: 0,
                                right: 30,
                                left: 10,
                                bottom: 55,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
                            <YAxis />
                            <Tooltip formatter={rederFormatedTooltip} />
                            <Legend verticalAlign="top" align="right" iconType='circle' formatter={renderColorfulLegendText} />
                            {
                                selectInfo === "completed" ?
                                    <>
                                        <Bar dataKey="opened" stackId="a" fill="#02666b" />
                                        <Bar dataKey="completed" stackId="a" fill="#66b7ba" />
                                    </>
                                    :
                                    <>
                                        <Bar dataKey="sent" stackId="a" fill="#018696" />
                                        <Bar dataKey="errors" stackId="a" fill="#e45a56" />
                                    </>
                            }
                        </BarChart>
            }
        </CardElementComponent>
    )
}

import "./App.css";
import Rutas from "./Rutas/Rutas";
import { LanguageProvider } from "./context/languageContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { persistQueryClient } from '@tanstack/react-query-persist-client'

const time = 1000 * 60 * 60 * 24;// 24 hours

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: time
    },
  },
})

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

const persistQueries = [
  'NPS',
  'limitations',
  'Dashboard',
  // 'pie',
  'SurveyInfoByDate',
  'EntitiesData',
  'itemClass',
  'itemTypes',
  'Emojis',
  'questionTypes',
  'questionValuesFormat'
]

persistQueryClient({
  queryClient,
  persister,
  maxAge: time,
  hydrateOptions: {},
  dehydrateOptions: {
    shouldDehydrateQuery: query => {
      return persistQueries.includes(query.queryKey[0])
    },
  },
})

function App() {
  return (
    <LanguageProvider>
      <QueryClientProvider client={queryClient}>
        <Rutas />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </LanguageProvider>
  );
}

export default App;

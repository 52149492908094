import { useQuery } from '@tanstack/react-query';
import axios from '../../../../../api/axios';
import { useParams } from 'react-router-dom';

export const useSurveyStyles = () => {
    let { customer } = useParams();
    customer = customer.toLowerCase();

    //#region Styles
    const { data: surveyStyles, isLoading: isLoadingStyles, isError: isErrorStyles } = useQuery({
        queryKey: ["surveystyles", customer],
        queryFn: async () => {
            const response = await axios.get(`customer/info/${customer}`);
            return response.data[customer];
        },
        refetchOnWindowFocus: false,
        retry: 0,
    });

    const makeColorDarker = (color, amount = 50) => {
        // Convert hex to RGB
        const hexToRgb = (hex) => {
            const bigint = parseInt(hex.replace("#", ""), 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;
            return { r, g, b };
        };

        const checkWhiteColor = color.trim().toLowerCase() === "#ffffff";
        if (checkWhiteColor) return "black";
        const { r, g, b } = hexToRgb(color);

        // Lighten the color
        const lighten = (value) => Math.min(255, value + amount);
        const newColor = `rgb(${lighten(r)}, ${lighten(g)}, ${lighten(b)})`;

        return newColor;
    }
    //#endregion

    return { surveyStyles, isLoadingStyles, isErrorStyles, makeColorDarker }; // Exportar variables
};

import React from 'react';
import PropTypes from 'prop-types';

const initAd = () => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
};

export class AdComponent extends React.Component {
    componentDidMount() {
        initAd();
    }

    shouldComponentUpdate(nextProps) {
        const { props: { path } } = this;
        return nextProps.path !== path;
    }

    componentDidUpdate() {
        initAd();
    }

    render() {
        //TODO: Falta para acepte mas tipos de anuncios
        //TODO: Falta solucionar el error de with 0 
        
        const { className, path } = this.props;
        return (
            <div key={path} className={`adsense ${className}`}>
                <ins className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-8975145943647028"
                    data-ad-slot={this.props.dataAdSlot}
                    data-ad-format="auto"
                    adtest={process.env.NODE_ENV === "development" ? "on" : "off"}
                    data-full-width-responsive="true" />
            </div>
        );
    }
}

AdComponent.propTypes = {
    path: PropTypes.string.isRequired,
    className: PropTypes.string,
};

AdComponent.defaultProps = {
    className: '',
};
import React from 'react'
import template from "../../../Domains/customers.json";
import { Footer } from '../../ReuseComponents/Footer';
import { Skeleton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import MetaDecorator from '../../UtilsComponents/MetaDecorator';

export const SurveyLoadingSkeleton = ({ errorMsg }) => {
    document.body.style.backgroundColor = "#e6e6e6";
    const query = new URLSearchParams(window.location.search);
    const survey = query.get("survey");
    const { url, headerBackground, bodyBackground } = template["Skeleton"];
    return (
        <table border="0" cellPadding="0" cellSpacing="0" width="100%">
            <MetaDecorator
                title={`Cargando Survey ${survey}`}
                description={`Cargando mi encuesta ${survey}`}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={`Cargando mi encuesta ${survey}`}
                path={window.location.pathname}
            />
            <tbody>
                <tr>
                    <td bgcolor={headerBackground} align="center">
                        <table className="tableWith">
                            <tbody>
                                <tr>
                                    <td className="td1" align="center" valign="top">
                                        <a target="_blank" rel="noreferrer" href={url}>
                                            <Skeleton className="img1" animation={false} variant="rounded" width={"auto"} height={120} />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td className="td2" bgcolor={headerBackground}  >
                        <table className="tableWith" align="center"  >
                            <tbody>
                                <tr>
                                    <td className="tdTitle" align="center"  >
                                        {!errorMsg ?
                                            <>
                                                <div className='sl-nav'>
                                                    <Skeleton animation="wave" variant="rounded" width={30} height={20} />
                                                </div>
                                                <Skeleton animation="wave" variant="rounded" width={"auto"} height={60} /></>
                                            :
                                            <h2 style={{ color: "red" }}>
                                                <FormattedMessage
                                                    id="survey.view.title.error"
                                                    defaultMessage={`Error al cargar la encuesta`}
                                                />
                                                <MetaDecorator
                                                    title={`Error Survey ${survey}`}
                                                    description={`Mi encuestas error ${survey}`}
                                                    imageUrl={"/images/7Bytes.png"}
                                                    imageAlt={`Mi encuestas error ${survey}`}
                                                    path={window.location.pathname}
                                                />
                                            </h2>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td className="tdBody" bgcolor={bodyBackground} align="center" >
                        <table className="tableWith mb-5 shadow2" >
                            <tbody>
                                <tr>
                                    <td className="tdBody2" align="left"  >
                                        {!errorMsg ?
                                            <>
                                                <div className="row mt-4" >
                                                    <div className="col-sm-12">
                                                        <div className="card shadow">
                                                            <div className="card-body" >
                                                                <div className="form-group" >
                                                                    <Skeleton className='mb-3' animation="wave" variant="rounded" width={"auto"} height={20} />
                                                                    <div className="" >
                                                                        <div className="col text-center" >
                                                                            <div className="form-check-inline text-center align-top" >
                                                                                <div className="" key={`radioContainer`}>
                                                                                    <div className="col text-center" key={`radioCenter`}>
                                                                                        {
                                                                                            [...Array(4)].map((e, i) => {
                                                                                                return (
                                                                                                    <div className="form-check-inline text-center align-top" key={`radio${i}`}>
                                                                                                        <Skeleton className='mb-3' animation="wave" variant="rounded" width={100} height={20} />
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-sm-12" >
                                                        <div className="card shadow" >
                                                            <div className="card-body" >
                                                                <div className="form-group text-center" >
                                                                    <Skeleton className='mb-3' animation="wave" variant="rounded" width={"auto"} height={20} />
                                                                    <br />
                                                                    {
                                                                        [...Array(4)].map((e, i) => {
                                                                            return (
                                                                                <div className="form-check-inline text-center align-top" key={`radio${i}`}>
                                                                                    <Skeleton className='mb-3' animation="wave" variant="rounded" width={100} height={20} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-4" >
                                                    <div className="col-sm-12">
                                                        <div className="card shadow">
                                                            <div className="card-body">
                                                                <div className="form-group">
                                                                    <Skeleton className='mb-3' animation="wave" variant="rounded" width={"auto"} height={20} />
                                                                    <br />
                                                                    <Skeleton className='mb-3' animation="wave" variant="rounded" width={"auto"} height={60} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-sm-12 ">
                                                        <div className="card shadow">
                                                            <div className="card-body">
                                                                <div className="form-group">
                                                                    <Skeleton className='mb-3' animation="wave" variant="rounded" width={"auto"} height={20} />
                                                                    <br />
                                                                    <Skeleton className='mb-3' animation="wave" variant="rounded" width={"auto"} height={110} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col text-center mt-3">
                                                        <Skeleton className='mb-3 center' animation="wave" variant="rounded" width={170} height={50} />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <h2 className='text-center' >
                                                    {errorMsg}
                                                </h2>
                                                <div>
                                                    <img
                                                        className="img-fluid d-block mx-auto"
                                                        alt="DontFound"
                                                        src="/images/DontFound.png"
                                                        style={{
                                                            width: "350px",
                                                            height: "auto",
                                                            padding: "2rem",
                                                            paddingTop: "3rem",
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <Footer bodyBackground={bodyBackground} fontColor={"#000"} />
                </tr>
            </tbody>
        </table >
    )
}

import React, { useContext } from 'react'
import StylesContext from '../UtilsComponents/SytlesContextViewSurvey';
import CreateIcon from '@mui/icons-material/Create';
import { FormattedMessage } from 'react-intl';

export const SurveyTitles = ({ title, subtitle }) => {
    const {
        surveyTitleColor,
        surveySubtitleColor
    } = useContext(StylesContext);

    const [surveyTitle, setSurveyTitle] = React.useState(title)
    const [surveySubtitle, setSurveySubtitle] = React.useState(subtitle)

    const autoGrow = (element) => {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
    };

    const handleTitleChange = (e) => {
        setSurveyTitle(e.target.value);
        autoGrow(e.target);
    };
    const handleSubTitleChange = (e) => {
        setSurveySubtitle(e.target.value);
        autoGrow(e.target);
    }
    return (
        <>
            {
                (title === surveyTitle || surveyTitle === "") &&
                <h5 className='text-center'>
                    <b>
                        <CreateIcon /> <FormattedMessage
                            id="styles.click.to.change.title"
                        />
                    </b>
                </h5>
            }
            <h1 className="Title mb-3">
                <textarea
                    id="input-title"
                    className='titleInput text-center'
                    type="text"
                    style={{ color: surveyTitleColor }}
                    maxLength={100}
                    spellCheck="false"
                    value={surveyTitle}
                    onChange={handleTitleChange}
                />
                <p style={{
                    fontSize: "38px",
                    letterSpacing: "0px",
                    margin: "0px",
                    lineHeight: "1.32",
                    display: "none",
                    color: surveyTitleColor
                }}>
                    {surveyTitle}
                </p>
            </h1>
            <h5 className="reduceTitle mt-3">
                <textarea
                    id="input-subtitle"
                    className='titleInputSmall text-center'
                    type="text"
                    style={{ color: surveySubtitleColor }}
                    maxLength={200}
                    spellCheck="false"
                    value={surveySubtitle}
                    onChange={handleSubTitleChange}
                />
            </h5>
        </>
    )
}

import { Slide } from '@mui/material';
import React from 'react'

function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}

const topCenterPosition = {
    open: false,
    vertical: 'top',
    horizontal: 'center',
}

const topRightPosition = {
    open: false,
    vertical: 'top',
    horizontal: 'right',
}

const bottomCenterPosition = {
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
}

const bottomRightPosition = {
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
}

const topLeftPosition = {
    open: false,
    vertical: 'top',
    horizontal: 'left',
}

const bottomLeftPosition = {
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
}


export { 
    TransitionLeft, 
    TransitionUp, 
    TransitionRight, 
    TransitionDown,
    topCenterPosition,
    topRightPosition,
    bottomCenterPosition,
    bottomRightPosition,
    topLeftPosition,
    bottomLeftPosition
}

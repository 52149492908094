import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useAxiosPrivate from "../../CustomHooks/useAxiosPrivate";
// import { Navbar } from "../ReuseComponents/Navbar"
import { Link } from "react-router-dom";
import { useMemo } from "react";
import MetaDecorator from "../UtilsComponents/MetaDecorator";
import useAuth from "../../CustomHooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { ROLES_LIST } from "../../Domains/roleList";

export const CustomerStyles = () => {

    const intl = useIntl();
    const { UserInfo, defaultCustomer } = useAuth();
    const customerName = defaultCustomer.toLowerCase();
    const axiosPrivate = useAxiosPrivate();
    const [customerNoStyles, setCustomerNoStyles] = useState({});

    const logoRef = useRef();
    const nameRef = useRef();
    const urlRef = useRef();
    const titleRef = useRef();

    const headerRef = useRef();
    const bodyRef = useRef();
    const fontRef = useRef();
    const btnBackRef = useRef();
    const btnFontRef = useRef();

    const [logo, setLogo] = useState();
    const checkAdmin = UserInfo?.roles.includes(ROLES_LIST.Admin)
    const [name, setName] = useState(customerName);
    const [url, setUrl] = useState("");
    const [title, setTitle] = useState("");
    const [isPending, setIsPending] = useState(false);
    const stylesInitialState = {
        bodyBackground: "#000000",
        fontColor: "#000000",
        headerBackground: "#000000",
        btnBackColor: "#000000",
        btnFontColor: "#000000",
    }
    const [surveyStyles, setSurveyStyles] = useState(stylesInitialState);

    const getData = async () => {
        const response = await axiosPrivate.get(`customer/info/${defaultCustomer}`);
        return response.data;
    }

    const { data: customerStyle, isLoading: isLoadingCustomerStyle } = useQuery({
        queryKey: ["customerStyle", defaultCustomer],
        queryFn: getData,
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !checkAdmin,
    });

    useEffect(() => {
        if (customerStyle) {
            setSurveyStyles({
                bodyBackground: customerStyle[customerName].bodyBackground,
                fontColor: customerStyle[customerName].fontColor,
                headerBackground: customerStyle[customerName].headerBackground,
                btnBackColor: customerStyle[customerName].btnSubmit.bg,
                btnFontColor: customerStyle[customerName].btnSubmit.color,
            })
            setTitle(customerStyle[customerName].title);
            setUrl(customerStyle[customerName].url);
            setName(customerName);
        }
    }, [customerStyle, customerName])

    // const previewUrl = `/previewsurvey?name=${name}&title=${"Holas"}&font=${fontRef.current?.value}&btnBack=${btnBackRef.current?.value}&btnFont=${btnFontRef.current?.value}`.replaceAll('#', "");
    let previewUrl = useMemo(() => {
        return `/surveypreview?name=${name}&title=${title}&url=${url}`
    }, [name, title, url]);

    const getCustomersWithoutStyles = async () => {
        try {
            const response = await axiosPrivate.get("customer/about/nostyles");
            if (response.status === 200) {
                setCustomerNoStyles(
                    prev => ({
                        ...prev,
                        ...response.data
                    })
                );
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        // document.body.style.backgroundColor = "#096a6e";
        UserInfo?.roles.includes(1) && getCustomersWithoutStyles();
        // eslint-disable-next-line
    }, [])

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const checkIfImageExists = async () => {
        if (localStorage[`img${name}`]) {
            localStorage.removeItem(`img${name}`);
        }
    }

    const handlePreview = async (e) => {
        try {
            //Esta validacion es mas que todo para el admin
            if (!localStorage.getItem(`img${name}`)) {
                e.preventDefault();
                return alert(intl.formatMessage({ id: "create.customer.styles.error.logo" }))
            }
            if (!title) {
                e.preventDefault();
                return alert(intl.formatMessage({ id: "create.customer.styles.error.welcome.msg" }));
            }
            else if (!isNaN(name) && UserInfo?.roles.includes(1)) {
                e.preventDefault();
                return alert(intl.formatMessage({ id: "create.customer.styles.error.customer" }));
            }
            else if (!url) {
                e.preventDefault();
                return alert(intl.formatMessage({ id: "create.customer.styles.error.url" }));
            }
            const a = document.getElementById("preview");
            previewUrl = `${previewUrl}&header=${headerRef.current?.value}&body=${bodyRef.current?.value}&font=${fontRef.current?.value}&btnBack=${btnBackRef.current?.value}&btnFont=${btnFontRef.current?.value}`.replaceAll('#', "");
            a.href = previewUrl;
        } catch (error) {
            console.error(error);
        }
    }

    const handleLogo = (e) => {
        console.log(e.target.files[0]);
        try {
            //check if an element was selected
            if (!e.target.files[0]) {
                localStorage[`img${name}`] = customerStyle[name].logo;
                return;
            }
            const logo = e.target.files[0];
            if (logo.size > 1000000) {
                e.target.value = "";
                setLogo(prev => {
                    return prev = "";
                });
                return alert(intl.formatMessage({ id: "create.customer.styles.error.logo.size" }));
            } else if (logo.type !== "image/png" && logo.type !== "image/jpeg" && logo.type !== "image/jpg" && logo.type !== "image/svg" && logo.type !== "image/svg+xml" && logo.type !== "image/webp") {
                e.target.value = "";
                setLogo(prev => {
                    return prev = "";
                });
                return alert(intl.formatMessage({ id: "create.customer.styles.error.logo.type" }));
            }
            else {
                checkIfImageExists();
                setLogo(prev => {
                    return prev = logo;
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (logo && typeof logo === 'object' && isNaN(name)) {
            getBase64(logo).then(base64 => {
                localStorage[`img${name}`] = base64;
            });
        } else if (customerStyle && customerStyle[name]?.logo) {
            localStorage[`img${name}`] = customerStyle[name].logo;
        }
    }, [logo, name, customerStyle])

    const handleSelectName = (e) => {
        try {
            const { value } = e.target;
            checkIfImageExists();
            setName(prev => {
                return prev = value;
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const regexHttp = /^(http|https):\/\//;
        if (!isNaN(name)) {
            return alert(intl.formatMessage({ id: "create.customer.styles.error.customer" }));
        } else if (!regexHttp.test(url)) {
            return alert(intl.formatMessage({ id: "create.customer.styles.error.valid.url" }));
        }
        setIsPending(true);
        try {
            const formData = new FormData();
            formData.append("customerImg", logo);
            formData.append("web_site_url", urlRef.current.value);
            formData.append("header_background_color", headerRef.current.value);
            formData.append("body_background_color", bodyRef.current.value);
            formData.append("font_color", fontRef.current.value);
            formData.append("btn_back_color", btnBackRef.current.value);
            formData.append("btn_font_color", btnFontRef.current.value);
            formData.append("title", titleRef.current.value);
            const controller = new AbortController();
            const headers = {
                "Content-Type": "multipart/form-data"
            }
            const response = await axiosPrivate.post(`customer/info?name=${name}`,
                formData,
                headers,
                {
                    signal: controller.signal,
                }
            );
            if (response.status === 200) {
                logoRef.current.value = "";
                //delete customer from the list
                delete customerNoStyles[name];
                setCustomerNoStyles(
                    prev => ({
                        ...prev,
                        ...customerNoStyles
                    })
                );
                // checkIfImageExists();
                setIsPending(false);
                alert(intl.formatMessage({ id: "create.customer.styles.success" }));
            }
        }
        catch (error) {
            console.error(error);
            if (error.response?.data?.text) {
                alert(`ERROR: ${error.response.data.text}`);
            } else {
                alert("ERROR: Something went wrong");
            }
        }
        finally {
            setIsPending(false);
        }
    }

    return (
        <>
            {/* <Navbar /> */}
            <MetaDecorator
                title={intl.formatMessage({ id: "change.customer.styles.title" })}
                description={"Entendiendo al consumidor de una forma. Amigable Efectiva y Relevante"}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"7Bytes - Estudio de mercado continuo"}
                path={window.location.pathname}
            />
            <section className="container" style={{ padding: "2rem 3rem 1rem 3rem", minHeight: "80vh" }}>
                <div className="signup-content">
                    <div className="text-center" >
                        <h2 className="fw-normal "
                            style={{
                                color: "whitesmoke",
                                letterSpacing: 1,
                                fontSize: 40
                            }}>
                            <strong>
                                <FormattedMessage
                                    id="change.customer.styles.title"
                                    defaultMessage={`Crear nuevos estilos`}
                                />
                            </strong>
                        </h2>
                    </div>
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <FormattedMessage
                            id="create.initial.msg"
                            defaultMessage={`Por favor recuerda ingresar todos los campos`}
                        />
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-2 mb-2">
                            <div className="col-md-7">
                                <div className="input-group mb-2">
                                    <label className="input-group-text" style={{ borderRadius: "10px 0px 0px 10px" }} htmlFor="inputGroupFile01"> <i className="fa fa-file-image-o" style={{ color: "gray" }} /> &nbsp;Logo</label>
                                    <input type="file" onChange={handleLogo} ref={logoRef} style={{ padding: "16px 4px", borderRadius: "0px 10px 10px 0px" }} className="form-control" id="inputGroupFile01" />
                                </div>
                            </div>
                            <div className="form-floating col-md-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    style={{ borderRadius: "10px" }}
                                    id="inputTitle"
                                    autoComplete="off"
                                    placeholder="name"
                                    required
                                    ref={titleRef}
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                />
                                <label >
                                    <i className="fa fa-hand-stop-o" style={{ color: "gray" }} /> &nbsp;
                                    <FormattedMessage
                                        id="create.customer.styles.welcome.msg"
                                        defaultMessage={`Mensaje de bienvenida`}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="row g-3 mb-3">
                            <div className="col-md">
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ borderRadius: "10px" }}
                                        id="inputUrl"
                                        autoComplete="off"
                                        placeholder="url"
                                        required
                                        ref={urlRef}
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                    />
                                    <label >
                                        <i className="fa fa-link" style={{ color: "gray" }} /> &nbsp;
                                        <FormattedMessage
                                            id="create.customer.styles.url"
                                            defaultMessage={`Link de la pagina`}
                                        />
                                    </label>
                                </div>
                            </div>
                            {
                                UserInfo?.roles.includes(1) &&
                                <div className="form-floating  col-md" >
                                    <select className="form-select" ref={nameRef} onChange={handleSelectName} style={{ borderRadius: "10px" }} id="Selectcustomer" aria-label="Floating label select example">
                                        <option defaultValue value={1}>
                                            {intl.formatMessage({ id: "create.customer.styles.select.customer" })}
                                        </option>
                                        {Object.entries(customerNoStyles).map(([key, value]) => {
                                            return (
                                                <option key={key} value={key}>
                                                    {key}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    <label htmlFor="Selectdb">
                                        <i className="fa fa-database" style={{ color: "gray" }} /> &nbsp;
                                        <FormattedMessage
                                            id="create.customer.styles.customers"
                                            defaultMessage={`Clientes disponibles`}
                                        />
                                    </label>
                                </div>
                            }
                        </div>
                        {
                            UserInfo?.roles.includes(1) &&
                            <div className="row g-3 mb-3">
                                <div className="form-floating col-md">
                                    <h5 style={{ color: "whitesmoke" }}>
                                        <FormattedMessage
                                            id="create.customer.styles.no.customers"
                                            defaultMessage={`Si no hay cliente o deseas agregar uno nuevo`}
                                        />
                                        &nbsp;
                                        <Link to="/dashboard/createcustomer" style={{ color: "rgb(17, 158, 148)" }}>
                                            <FormattedMessage
                                                id="navbar.create.customer"
                                                defaultMessage={`Crear Cliente`}
                                            />
                                        </Link>
                                    </h5>
                                </div>
                            </div>
                        }
                        {
                            (!isLoadingCustomerStyle || checkAdmin) &&
                            <>
                                <div className="row g-3 mb-3 container">
                                    <div className=" col-md">
                                        <div className="col-md">
                                            <label htmlFor="headerColor" className="mousePointer" style={{ color: "whitesmoke" }}>
                                                <FormattedMessage
                                                    id="create.customer.styles.header.color"
                                                    defaultMessage={`Escoge el color para el header: `}
                                                />
                                            </label>
                                            <input
                                                type="color"
                                                ref={headerRef}
                                                className="mousePointer"
                                                value={surveyStyles.headerBackground}
                                                onChange={(e) => {
                                                    setSurveyStyles({ ...surveyStyles, headerBackground: e.target.value })
                                                }}
                                                style={{ margin: "10px 0px 0px 5px" }}
                                                id="headerColor"
                                                name="headerColor"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="col-md">
                                            <label htmlFor="backColor" className="mousePointer" style={{ color: "whitesmoke" }}>
                                                <FormattedMessage
                                                    id="create.customer.styles.back.color"
                                                    defaultMessage={`Escoge el color para el fondo: `}
                                                />
                                            </label>
                                            <input
                                                type="color"
                                                className="mousePointer"
                                                style={{ margin: "10px 0px 0px 5px" }}
                                                value={surveyStyles.bodyBackground}
                                                onChange={(e) => {
                                                    setSurveyStyles({ ...surveyStyles, bodyBackground: e.target.value })
                                                }}
                                                id="backColor"
                                                name="backColor"
                                                ref={bodyRef}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-3 mb-3 container">
                                    <div className=" col-md">
                                        <div className="col-md">
                                            <label htmlFor="fontColor" className="mousePointer" style={{ color: "whitesmoke" }}>
                                                <FormattedMessage
                                                    id="create.customer.styles.font.color"
                                                    defaultMessage={`Escoge el color para las letras: `}
                                                />
                                            </label>
                                            <input
                                                type="color"
                                                className="mousePointer"
                                                style={{ margin: "10px 0px 0px 5px" }}
                                                value={surveyStyles.fontColor}
                                                onChange={(e) => {
                                                    setSurveyStyles({ ...surveyStyles, fontColor: e.target.value })
                                                }}
                                                id="fontColor"
                                                name="fontColor"
                                                ref={fontRef}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="col-md">
                                            <label htmlFor="btnBackColor" className="mousePointer" style={{ color: "whitesmoke" }}>
                                                <FormattedMessage
                                                    id="create.customer.styles.btn.color"
                                                    defaultMessage={`Escoge el color para el fondo del boton: `}
                                                />
                                            </label>
                                            <input
                                                type="color"
                                                className="mousePointer"
                                                style={{ margin: "10px 0px 0px 5px" }}
                                                value={surveyStyles.btnBackColor}
                                                onChange={(e) => {
                                                    setSurveyStyles({ ...surveyStyles, btnBackColor: e.target.value })
                                                }}
                                                id="btnBackColor"
                                                name="btnBackColor"
                                                ref={btnBackRef}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-3 mb-3 container">
                                    <div className=" col-md">
                                        <div className="col-md">
                                            <label htmlFor="btnFontColor" className="mousePointer" style={{ color: "whitesmoke" }}>
                                                <FormattedMessage
                                                    id="create.customer.styles.btn.font.color"
                                                    defaultMessage={`Escoge el color para el color de las letras dentro del boton: `}
                                                />
                                            </label>
                                            <input
                                                type="color"
                                                className="mousePointer"
                                                style={{ margin: "10px 0px 0px 5px" }}
                                                value={surveyStyles.btnFontColor}
                                                onChange={(e) => {
                                                    setSurveyStyles({ ...surveyStyles, btnFontColor: e.target.value })
                                                }}
                                                id="btnFontColor"
                                                name="btnFontColor"
                                                ref={btnFontRef}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="row g-3 mb-3 container">
                            <div className=" col-md">
                                <strong style={{ color: "whitesmoke" }}>
                                    <FormattedMessage
                                        id="create.customer.styles.preview"
                                        defaultMessage={`Preview de de como se veria la encuenta: `}
                                    />
                                    &nbsp;
                                </strong>
                                <a className="btn btn-primary" onClick={handlePreview} id="preview" href={previewUrl} target="_blank" rel="noopener noreferrer" >
                                    <FormattedMessage
                                        id="create.customer.styles.here"
                                        defaultMessage={`Aquí`}
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="form-group">
                            <button type="submit" disabled={isPending ? true : false} name="submit" id="submit" className="form-submit">
                                {isPending ?
                                    <FormattedMessage
                                        id="sending.button"
                                        defaultMessage={`Enviando...`}
                                    />
                                    :
                                    checkAdmin ?
                                        <FormattedMessage
                                            id="create.user.btn"
                                            defaultMessage={`Crear`}
                                        />
                                        :
                                        <FormattedMessage
                                            id="edit"
                                            defaultMessage={`Editar`}
                                        />
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

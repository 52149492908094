import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react'
import useAxiosPrivate from '../../../CustomHooks/useAxiosPrivate';
import useAuth from '../../../CustomHooks/useAuth';
import { BarChartSurveyResults } from './BarChartSurveyResults';
import { PieSurveyResults } from './PieSurveyResults';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import { QUESTIONS_TYPE_LIST } from '../../../Domains/questionTypeList';
import { FormattedMessage } from 'react-intl';
import { TextResults } from './TextResults';
import { Tooltip } from '@mui/material';
import { useSessionStorage } from '../../../CustomHooks/useSessionStorage';
import { languageContext } from '../../../context/languageContext';
import { itemLabelLanguage } from '../../../Languages/TranslationsLabels';

export const ResultsSurvey = () => {
    const query = new URLSearchParams(window.location.search);
    const survey = query.get("survey");
    const { defaultCustomer } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const language = useContext(languageContext);

    const { data: results, isLoading: loadingResults, isError: isErrorResults, error: errorResults } = useQuery({
        queryKey: ["Results", survey],
        queryFn: async () => {
            const response = await axiosPrivate.get(`answer/survey/${survey}?customer=${defaultCustomer}`)
            //Tambien esta guardando para las tipo text
            !openBarChart && setOpenBarChart(new Array(response.data.length).fill(true));
            !openPieChart && setOpenPieChart(new Array(response.data.length).fill(false));
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: false,
    });
    const [openPieChart, setOpenPieChart] = useSessionStorage(`surveybarchar${survey}`, 0);
    const [openBarChart, setOpenBarChart] = useSessionStorage(`surveypiechart${survey}`, 0);

    const showIconChart = (type) => {
        if (type.toLowerCase().includes("text")) {
            return false;
        }
        return true;
    }

    const handleOpenBarChart = (index) => {
        //change the state of the array using the index
        let newArray = [...openBarChart];
        newArray[index] = !newArray[index];
        if (newArray[index]) {
            setOpenBarChart(newArray);
            //close the pie chart
            let newArrayPie = [...openPieChart];
            newArrayPie[index] = false;
            setOpenPieChart(newArrayPie);
        }
    };

    const handleOpenPieChart = (index) => {
        //change the state of the array using the index
        let newArray = [...openPieChart];
        newArray[index] = !newArray[index];
        if (newArray[index]) {
            setOpenPieChart(newArray);
            //close the bar chart
            let newArrayBar = [...openBarChart];
            newArrayBar[index] = false;
            setOpenBarChart(newArrayBar);
        }
    };

    if (loadingResults) return <div className='text-center'><FormattedMessage id="loading.msg" /></div>
    if (isErrorResults) {
        if (errorResults.response.status === 404) {
            return <div className='text-center'><FormattedMessage id="not.found.answers" /></div>
        } else {
            return <div className='text-center'><FormattedMessage id="login.Error.No.Server" /></div>
        }
    }

    return (
        Object.keys(results).map((key, index) => {
            return (
                <div className="row mt-4" key={index}>
                    <div className="col-sm-12">
                        <div className="card shadow">
                            <div className="card-body" >
                                <div className="form-group"  >
                                    <label className='mb-1' htmlFor="">
                                        {index + 1}. {results[key][itemLabelLanguage[language.locale]] ?? results[key].item_label}
                                        {
                                            showIconChart(results[key].type) &&
                                            <div style={{ float: "right" }}>
                                                <Tooltip title={<FormattedMessage id="change.to.bar.chart" />} placement='top' arrow>
                                                    <BarChartIcon onClick={() => handleOpenBarChart(index)} className='mx-3' style={{ cursor: "pointer" }} />
                                                </Tooltip>
                                                <Tooltip title={<FormattedMessage id="change.to.pie.chart" />} placement='top' arrow>
                                                    <PieChartIcon onClick={() => handleOpenPieChart(index)} className='mx-1' style={{ cursor: "pointer" }} />
                                                </Tooltip>
                                            </div>
                                        }
                                    </label>
                                    <h6 className='mb-3' style={{ fontSize: "13px" }}>
                                        <b>
                                            <FormattedMessage
                                                id="edit.survey.label.question.type"
                                                defaultMessage={`Tipo de pregunta`}
                                            />:
                                        </b> &nbsp;
                                        {QUESTIONS_TYPE_LIST[results[key].type].name}
                                    </h6>
                                    {
                                        !results[key].type.toLowerCase().includes("text") ?
                                            <div id={`BarChartSurveyResults-${index}`}>
                                                {openBarChart[index] &&
                                                    <BarChartSurveyResults
                                                        chartName={`BarChartSurveyResults-${index}`}
                                                        data={results[key].answers}
                                                        maxValue={results[key].answers[0].count}
                                                    />
                                                }
                                                {openPieChart[index] &&
                                                    <PieSurveyResults
                                                        index={index}
                                                        chartName={`BarChartSurveyResults-${index}`}
                                                        data={results[key].answers}
                                                        maxValue={results[key].answers[0].count}
                                                    />
                                                }
                                            </div>
                                            :
                                            <div style={{ overflowY: "auto", maxHeight: "500px" }}>
                                                <TextResults data={results[key].answers} />
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    )
}

import axios from "../../api/axios";
import React, { useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import MetaDecorator from '../UtilsComponents/MetaDecorator';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { OutlinedInput, FormControl, IconButton, InputAdornment, InputLabel } from '@mui/material';
import { SnackBarComponent } from "../UtilsComponents/SnackBarComponent";
import { TransitionDown, topCenterPosition } from "../UtilsComponents/SnackBarTransitions";
import ReCAPTCHA from "react-google-recaptcha";
import { useQuery } from "@tanstack/react-query";
import { LoadingComponent } from "../SurveyComponents/CreateSurveyComponents/MainSurveyComponensts/LoadingComponent";

export const ResetPassword = () => {
    const intl = useIntl();
    //Si le dejo el punto en el servidor lo toma como un archivo y da error 404
    let token = useParams()["token"].replaceAll("[dcT]", ".");

    const [isSending, setIsSending] = useState(false);
    const [pwd, setPwd] = useState('');
    const [verifyPwd, setVerifyPwd] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);
    const [validPwd, setValidPwd] = useState();
    const [color, setColor] = useState("success");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [msg, setMsg] = useState('');
    const recaptchaRef = useRef();
    const [captchaToken, setCaptchaToken] = useState(null);

    const getValidationToken = async () => {
        const headers = {
            "Authorization": `Bearer ${token}`
        }
        const response = await axios.get(`/auth/verify-token-expiration`, { headers });
        return response.data;
    }
    const { isLoading: isLoadingTokenVerification, isError: isErrorTokenVerification } = useQuery({
        queryKey: ["verifyTokenExpiration"],
        queryFn: getValidationToken,
        refetchOnWindowFocus: false,
        retry: false,
    });

    const validatePassword = () => {
        //regex password
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,24}$/;
        const specialChars = /[!@#$%^&*(),.?":-_¿ñ{}|<>]/;
        if (pwd === "") {
            setValidPwd(true);
            return false;
        }
        else if (!passwordRegex.test(pwd) || !specialChars.test(pwd)) {
            setValidPwd(true);
            setMsg(intl.formatMessage({ id: "create.user.error.valid" }));
            return false;
        }
        else if (pwd !== verifyPwd) {
            setValidPwd(true);
            setMsg(intl.formatMessage({ id: "register.pwd.match" }));
            return false;
        } else if (captchaToken === null) {
            setValidPwd(true);
            setMsg(intl.formatMessage({ id: "register.check.captcha" }));
            return false;
        }
        else {
            setValidPwd(false);
            return true;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsSending(true);
            if (!validatePassword()) {
                return setColor("error");
            }
            //add headers
            const headers = {
                "Authorization": `Bearer ${token}`
            }
            await axios.post("/auth/reset-password", { password: pwd, captchaToken }, { headers });
            setColor("success");
            setMsg(intl.formatMessage({ id: "settings.change.pass.success" }));
            setPwd('');
            setVerifyPwd('');
            //reset captcha
            recaptchaRef.current.reset();
        } catch (error) {
            setColor("error");
            if (error.response?.status === 400) {
                setMsg(intl.formatMessage({ id: "error.captcha" }) + " " + intl.formatMessage({ id: "error.captcha.info" }));
            }
            else if (error.response?.status === 401) {
                setMsg(intl.formatMessage({ id: "reset.password.expire" }));
            } else {
                setMsg(intl.formatMessage({ id: "forgot.password.mail.error" }));
            }
        }
        finally {
            setOpenSnackBar(true);
            setIsSending(false);
        }
    };

    return (
        <section className=" py-5"
            style={{
                background: "#f3f2f8",
                minHeight: "100vh",
            }}>
            {/* <Particle /> */}
            <MetaDecorator
                title={"Restablecer contraseña"}
                description={"Restablecer contraseña 7Bytes"}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"Restablecer contraseña 7Bytes"}
                path={window.location.pathname}
            />
            <SnackBarComponent
                position={topCenterPosition}
                transition={TransitionDown}
                handleClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setOpenSnackBar(false);
                }}
                time={15000}
                openSnackBar={openSnackBar}
                color={color}
                msg={msg}
            />
            <div className="container py-4 Login RegularMadeTommy" style={{ width: "700px", marginTop: "5%" }}>
                <div className="row d-flex justify-content-center align-items-center ">
                    <div className="row mt-3 signup-content" id="" style={{ margin: "" }}>
                        {
                            isLoadingTokenVerification ?
                                <LoadingComponent styles={{ margin: "0px 0rem 2rem 0px" }} fontStyles={{ color: "black" }} />
                                :
                                isErrorTokenVerification ?
                                    <LoadingComponent
                                        styles={{ margin: "0px 0rem 2rem 0px" }}
                                        fontStyles={{ color: "black" }}
                                        errorMsg={intl.formatMessage({ id: "reset.password.expire" })}
                                    />
                                    :
                                    <form onSubmit={handleSubmit}>
                                        <div className="text-center" >
                                            <h2 className="fw-normal RegularMadeTommy"
                                                style={{
                                                    color: "#2b364a",
                                                    letterSpacing: 1,
                                                    fontSize: 40
                                                }}>
                                                <strong>
                                                    <FormattedMessage
                                                        id="reset.password"
                                                    />
                                                </strong>
                                            </h2>
                                        </div>
                                        <hr style={{ color: "#2b364a" }} />
                                        {/* ----------------------- */}
                                        <div className="col-md-12 align-items-center" >
                                            <div style={{ color: "#2b364a" }} className='mb-3'>
                                                {/* <h5>
                                        <FormattedMessage
                                            id="forgot.password.description"
                                        />
                                    </h5> */}
                                            </div>
                                            <div className="col-md">
                                                <FormControl fullWidth sx={{ borderRadius: "10px", backgroundColor: "white" }} variant="outlined">
                                                    <InputLabel htmlFor="inputpassword" style={{ color: validPwd ? 'red' : '' }}>
                                                        <i className="fa fa-key" style={{ color: "gray" }} /> <FormattedMessage id="reset.password.new.password" />
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        error={validPwd}
                                                        style={{ color: validPwd ? 'red' : '', }}
                                                        onChange={(e) => setPwd(e.target.value)}
                                                        value={pwd}
                                                        required
                                                        id="inputpassword"
                                                        type={showPassword ? 'text' : 'password'}
                                                        label={`${intl.formatMessage({ id: "reset.password.new.password" })}  *`}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => { setShowPassword(!showPassword) }}
                                                                    onMouseDown={(e) => { e.preventDefault() }}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="col-md mt-3">
                                                <FormControl fullWidth sx={{ borderRadius: "10px", backgroundColor: "white" }} variant="outlined">
                                                    <InputLabel htmlFor="inputconfirmpassword" >
                                                        <i className="fa fa-key" style={{ color: "gray" }} /> <FormattedMessage id="create.user.verify.pwd" />
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        onChange={(e) => setVerifyPwd(e.target.value)}
                                                        value={verifyPwd}
                                                        required
                                                        id="inputconfirmpassword"
                                                        type={showVerifyPassword ? 'text' : 'password'}
                                                        label={`${intl.formatMessage({ id: "create.user.verify.pwd" })}  *`}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => { setShowVerifyPassword(!showVerifyPassword) }}
                                                                    onMouseDown={(e) => { e.preventDefault() }}
                                                                    edge="end"
                                                                >
                                                                    {showVerifyPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                            <ReCAPTCHA
                                                className="centerRecaptcha mt-3"
                                                ref={recaptchaRef}
                                                size="normal"
                                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_RESET_PASSWORD}
                                                onChange={(token) => setCaptchaToken(token)}
                                            />
                                            <div className="form-group mt-3">
                                                <div className='row'>
                                                    <div className='col-md-8 mt-3'>
                                                        <button type="submit" name="submit" disabled={isSending} id="submit" className="form-submit">
                                                            {isSending ?
                                                                <FormattedMessage
                                                                    id="reset.password.changing.password"
                                                                />
                                                                :
                                                                <FormattedMessage
                                                                    id="reset.password.change.password"
                                                                />
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className='col-md-4 mt-3'>
                                                        <Link to={'/login'} type="submit" name="submit" disabled={isSending} id="submit" className="form-submit-small text-center btn" style={{ backgroundColor: "#218838" }}>
                                                            <FormattedMessage
                                                                id="navbar.login"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import React, { useEffect } from 'react'
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAuth from '../../CustomHooks/useAuth';
import { FormattedMessage, useIntl } from 'react-intl';
import { CapitializeFirstLetters } from '../SurveyComponents/UtilsComponents/UtilsFunctions';
import { ROLES_NAMES } from '../../Domains/roleList';

export const EditUser = ({
    handleClose,
    open,
    selectedRow,
    setChange,
    setColor,
    setMsg,
    setOpenSnackBar
}) => {
    const { UserInfo, availableCustomers } = useAuth();
    const intl = useIntl();
    const AdminID = "1";
    const checkAdmin = UserInfo?.roles.includes(parseInt(AdminID))
    const axiosPrivate = useAxiosPrivate();
    const [email, setEmail] = React.useState(selectedRow?.email);
    const [status, setStatus] = React.useState(selectedRow?.user_status_id);
    const [selectedCustomers, setSelectedCustomers] = React.useState(null);
    const [selectedRoles, setSelectedRoles] = React.useState(null);
    const [selectedPlan, setSelectedPlan] = React.useState([]);
    const queryClient = useQueryClient();

    useEffect(() => {
        setEmail(selectedRow?.email);
        setStatus(selectedRow?.user_status_id);
        setSelectedCustomers(null);
        setSelectedRoles(null);
        setSelectedPlan([]);
    }, [selectedRow]);

    const { data: customers, isLoading: isLoadingCustomers } = useQuery({
        queryKey: ["Customers"],
        queryFn: async () => {
            const response = await axiosPrivate.get(`customer/info/all`);
            if (checkAdmin) return response.data;
            const newResp = response.data.filter((customer) => availableCustomers.includes(customer.name));
            return newResp;
        },
        refetchOnWindowFocus: false,
        retry: false,
    });
    const { data: roles, isLoading: isLoadingRoles } = useQuery({
        queryKey: ["Roles"],
        queryFn: async () => {
            const response = await axiosPrivate.get(`role`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    const { data: plans, isLoading: isLoadingPlans } = useQuery({
        queryKey: ["Plans"],
        enabled: checkAdmin,
        queryFn: async () => {
            const response = await axiosPrivate.get(`plan`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    const updateUser = async ({ row, values }) => {
        const response = await axiosPrivate.put(`userinfo/${row.email}`, values);
        return response.data;
    }

    const updateUserMutation = useMutation({
        mutationFn: updateUser,
        onSuccess: (data, { row, values }) => {
            setColor("success");
            setMsg(intl.formatMessage({ id: "edit.user.msg.success" }));
            setOpenSnackBar(true);
            queryClient.invalidateQueries(["Users"], { exact: true });
        },
        onError: (error) => {
            if (error.response.status === 400) {
                setMsg(intl.formatMessage({ id: "edit.user.msg.error" }));
            } else {
                setMsg(intl.formatMessage({ id: "login.Error.No.Server" }));
            }
            setColor("error");
            setOpenSnackBar(true);
            console.error(error);
        }
    });

    const handlePlanChange = (event, newValue) => {
        const { id } = event.target;
        const index = id.split(" ").slice(-1)[0];
        const checkCustomer = selectedRow.customer_name[index];
        //get customer id
        const customer = customers.find((element) => element.name === checkCustomer);
        //append plan to customer;
        const newPlan = { ...customer, plan_id: newValue.id };
        //check if plan is already in the list
        const found = selectedPlan.some((el) => el.id === newPlan.id);
        //change plan
        if (found) {
            const newPlans = selectedPlan.map((plan) => {
                if (plan.id === newPlan.id) {
                    return newPlan;
                }
                return plan;
            });
            setSelectedPlan(newPlans);
        } else {
            setSelectedPlan((prev) => [...prev, newPlan]);
        }
    }

    const handleCustomerChange = (event, newValue) => {
        setSelectedCustomers(newValue);
    };

    const ValidatePlansAndCustomers = () => {
        if (selectedCustomers && Object.keys(selectedCustomers).length > 0) {
            //remove the missing customers from the selectedPlan
            const newPlan = selectedPlan.filter((plan) => selectedCustomers.map((element) => element.name).includes(plan.name));
            return newPlan;
        }
        return selectedPlan;
    }

    const validations = () => {
        const sendJson = {}
        let sameValues = true;
        if (status !== selectedRow?.user_status_id) {
            sendJson.status = status;
        } else {
            sameValues = false;
        }
        if (Object.keys(selectedPlan).length === 0) {
            sameValues = false;
        } else {
            sendJson.plans = ValidatePlansAndCustomers();
        }

        if (selectedCustomers === null) {
            sameValues = false;
        } else {
            sendJson.customers = selectedCustomers;
        }

        if (selectedRoles === null) {
            sameValues = false;
        } else {
            sendJson.roles = selectedRoles;
        }
        return [sendJson, sameValues];
    };

    const validateRequired = (value) => {
        let flag = false;

        if (selectedCustomers?.length === 0) {
            flag = true;
            alert(intl.formatMessage({ id: "edit.user.customers.select" }));
        }
        if (selectedRoles?.length === 0) {
            flag = true;
            alert(intl.formatMessage({ id: "edit.user.roles.select" }));
        }
        return flag;
    }

    const handleEdit = (event) => {
        event.preventDefault();
        if (validateRequired()) return;
        const [req, sameValues] = validations();
        if (Object.keys(req).length === 0 && !sameValues) {
            alert(intl.formatMessage({ id: "edit.user.no.changes" }))
        } else {
            updateUserMutation.mutate({ row: selectedRow, values: req });
            setSelectedPlan([]);
            handleClose();
        }
    }

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                <FormattedMessage id="edit.user.msg.title" />
            </DialogTitle>
            <DialogContent>
                <div className='row'>
                    <div className='col-sm-8'>
                        <TextField
                            disabled
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            value={email}
                            name="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <FormControl fullWidth className='mt-2'>
                            <InputLabel id="Status">
                                <FormattedMessage id="user.table.date.user.status" />
                            </InputLabel>
                            <Select
                                labelId="Status"
                                id="Status"
                                value={status}
                                label="Status"
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <MenuItem value={1}>
                                    <FormattedMessage id="edit.user.status.active" />
                                </MenuItem>
                                <MenuItem value={2}>
                                    <FormattedMessage id="edit.user.status.end.trial" />
                                </MenuItem>
                                <MenuItem value={3}>
                                    <FormattedMessage id="edit.user.status.blocked" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {
                    !isLoadingCustomers &&
                    <Autocomplete
                        className='mt-3'
                        multiple
                        limitTags={2}
                        id="customers"
                        filterSelectedOptions
                        options={customers}
                        onChange={handleCustomerChange}
                        getOptionLabel={(option) => CapitializeFirstLetters(option.name)}
                        defaultValue={selectedRow?.customer_name?.map((customer) => {
                            return customers.find((element) => element.name === customer);
                        })
                        }
                        renderInput={(params) => (
                            <TextField {...params}
                                label={intl.formatMessage({ id: "user.table.date.customers" })}
                                placeholder={intl.formatMessage({ id: "edit.user.customers.select" })}
                            />
                        )}
                    />
                }
                {
                    //check roles
                    checkAdmin &&
                    !isLoadingPlans &&
                    selectedRow?.plan_name?.map((plan, index) => {
                        const found = selectedCustomers?.some((element) => element.name === selectedRow.customer_name[index]) ?? true;
                        return (
                            found &&
                            <Autocomplete
                                className='mt-2'
                                disablePortal
                                id="plans"
                                key={index}
                                onChange={handlePlanChange}
                                renderOption={(props, option, { selected }) => {
                                    return (
                                        <li {...props} id={`${selectedRow.customer_name[index]} ${index}`}>
                                            {option.plan_name}
                                        </li>
                                    );
                                }}
                                options={plans}
                                getOptionLabel={(option) => option.plan_name}
                                defaultValue={plans.find((element) => element.plan_name === plan)}
                                renderInput={
                                    (params) =>
                                        <TextField {...params} label={`${CapitializeFirstLetters(selectedRow.customer_name[index])} plan`} />
                                }
                            />
                        )
                    })
                }
                {
                    !isLoadingRoles &&
                    <Autocomplete
                        className='mt-3'
                        multiple
                        limitTags={3}
                        id="roles"
                        filterSelectedOptions
                        options={roles}
                        onChange={
                            (event, newValue) => {
                                setSelectedRoles(newValue);
                            }
                        }
                        // Por si se quiere deshabilitar el rol de admin
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                (!checkAdmin && (option.id) === AdminID) ?
                                    null
                                    :
                                    <Chip
                                        key={index}
                                        label={ROLES_NAMES[parseInt(option.id)].name}
                                        {...getTagProps({ index })}
                                    />
                            ))
                        }
                        renderOption={(props, option, { selected }) => {
                            return (
                                (!checkAdmin && (option.id) === AdminID) ? null :
                                    <Tooltip key={option.role_name} placement="top-start" title={ROLES_NAMES[parseInt(option.id)]["desp"]}>
                                        <li {...props}>
                                            {ROLES_NAMES[parseInt(option.id)].name}
                                        </li>
                                    </Tooltip>
                            );
                        }}
                        //remove admin role from the list
                        // getOptionDisabled={(option) => option.id === AdminID}
                        getOptionLabel={(option) => option.role_name}
                        defaultValue={selectedRow?.role_name?.map((role) => {
                            return roles.find((element) => element.role_name === role);
                        })}
                        renderInput={(params) => (
                            <TextField {...params}
                                label={intl.formatMessage({ id: "user.table.date.roles" })}
                                placeholder={intl.formatMessage({ id: "edit.user.roles.select" })}
                            />
                        )}
                    />
                }
                <div className='row mt-2'>
                    <div className='col-sm-6'>
                        <TextField
                            disabled
                            margin="dense"
                            id={intl.formatMessage({ id: "user.table.date.last.login" })}
                            value={selectedRow?.last_login ?
                                new Date(selectedRow?.last_login).toLocaleDateString("en-GB") : "N/A"
                            }
                            name={intl.formatMessage({ id: "user.table.date.last.login" })}
                            label={intl.formatMessage({ id: "user.table.date.last.login" })}
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </div>
                    <div className='col-sm-6'>
                        <TextField
                            disabled
                            margin="dense"
                            id="creation_date"
                            value={selectedRow?.date_created ?
                                new Date(selectedRow?.date_created).toLocaleDateString("en-GB") : ""
                            }
                            name="creation_date"
                            label={intl.formatMessage({ id: "user.table.date.created" })}
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='error'>
                    <FormattedMessage id="settings.cancel.pass.btn" />
                </Button>
                <Button type="submit" onClick={handleEdit}>
                    <FormattedMessage id="edit" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

import { FormattedMessage } from 'react-intl';
import { useSurveyContext } from '../Context/SurveyCustomHooks/useSurveyContext';
import { SendSurveyBtn } from './SendSurveyBtn';

export const FooterBtn = () => {
    const {
        activeStep,
        questionsNumber,
        surveyStyles,
        handleNext,
        isSmallScreen
    } = useSurveyContext();

    return (
        <div className={`divmysurveybtn ${isSmallScreen ? "" : "mt-3"}`}>
            {
                activeStep === questionsNumber - 1 ?
                    <SendSurveyBtn />
                    :
                    <button
                        type="submit"
                        style={{
                            backgroundColor: surveyStyles.btnSubmit.bg,
                            color: surveyStyles.btnSubmit.color
                        }}
                        onClick={handleNext}
                        className="btn btn-lg shadow mysurveybtn"
                    >
                        <FormattedMessage
                            id="next"
                        />
                    </button>
            }
        </div>
    )
}

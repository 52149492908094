import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import useAuth from '../../CustomHooks/useAuth';
import SendIcon from '@mui/icons-material/Send';
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { FormattedMessage } from 'react-intl';

export const ChangeDefCustomer = () => {
    const { defaultCustomer, availableCustomers, UserInfo, setDefaultCustomer } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [customer, setCustomer] = React.useState(defaultCustomer.toUpperCase());
    const [showAlert, setShowAlert] = React.useState(false);
    const [colorAlert, setColorAlert] = React.useState('success'); //success, danger, warning, info
    const [msg, setMsg] = React.useState('');
    const icons = {
        success: <CheckCircleIcon />,
        danger: <ErrorIcon />,
        warning: <WarningIcon />
    }

    const handleChange = (event) => {
        setCustomer(event.target.value);
        setShowAlert(false);
    };

    const validations = () => {
        //check if the customer is already the default customer
        if (defaultCustomer.toUpperCase() === customer.toUpperCase()) {
            setMsg(
                <FormattedMessage
                    id="settings.customer.already"
                    values={{
                        customer: customer.toUpperCase()
                    }}
                />
            );
            setColorAlert('warning');
            setShowAlert(true);
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!validations()) return;
            await axiosPrivate.post('/customer/default', { customerName: customer.toLowerCase(), email: UserInfo.email });
            setDefaultCustomer(customer.toLowerCase());
            setColorAlert('success');
            setMsg(
                <FormattedMessage
                    id="settings.customer.success"
                    values={{
                        customer: customer.toUpperCase()
                    }}
                />
            );
            setShowAlert(true);
        }
        catch (error) {
            setMsg(
                <FormattedMessage
                    id="settings.customer.error"
                    values={{
                        customer: customer.toUpperCase()
                    }}
                />
            );
            setColorAlert('danger');
            setShowAlert(true);
            // console.error(error)
        }
    };

    return (
        <div className="card-body pb-2">
            {
                showAlert &&
                <div className={`alert alert-${colorAlert} d-flex align-items-center`} role="alert">
                    <div>
                        {icons[colorAlert]}  {msg}
                    </div>
                </div>
            }
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-helper-label">
                    <FormattedMessage
                        id="settings.customers"
                    />
                </InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={customer}
                    label="Customer"
                    onChange={handleChange}
                >
                    {
                        availableCustomers.map((customer, index) => (
                            <MenuItem className='text-uppercase' key={index} value={customer.toUpperCase()}>{customer.toUpperCase()}</MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText>
                    <FormattedMessage
                        id="settings.available.customers"
                    />
                </FormHelperText>
            </FormControl>
            <Button onClick={handleSubmit} className='mt-3 mx-3' variant="outlined" startIcon={<SendIcon />}>
                <FormattedMessage
                    id="save"
                />
            </Button>
        </div>
    )
}

import React, { useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { FilterDateRange } from '../ReuseComponents/FilterDateRange';
import { useDashboardContext } from '../../CustomHooks/useDashboardContext';
import { FormattedMessage } from 'react-intl';

export const DashboardTitle = () => {
    const { initDate, endDate } = useDashboardContext();

    const convertDate = (dateStr) => {
        let inputDate = new Date(dateStr);
        inputDate = new Date(inputDate.getTime() + 5 * 60 * 60 * 1000); // Adding 5 hours
        let formattedDate = new Intl.DateTimeFormat('es-ES', {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        }).formatToParts(inputDate).map(part => {
            if (part.type === 'month') {
                return part.value.charAt(0).toUpperCase() + part.value.slice(1);
            }
            return part.value;
        }).join('');
        //replace the las space with a comma
        formattedDate = formattedDate.replace(/\s(?=\S*$)/, ', ');
        return formattedDate;
    };

    const [initDateState, setInitDateState] = React.useState(convertDate(initDate));
    const [endDateState, setEndDateState] = React.useState(convertDate(endDate));

    useEffect(() => {
        setInitDateState(convertDate(initDate));
    }, [initDate]);
    useEffect(() => {
        setEndDateState(convertDate(endDate));
    }, [endDate]);

    return (
        <div className='col-md-12 '>
            <Card sx={{ minWidth: 275 }}>
                <CardContent style={{ paddingBottom: "4px" }}>
                    <div className='row inline'>
                        <div className='col-md-3'>
                            <h2 sx={{ fontSize: 14, right: "10px" }} color="text.secondary">
                                <strong className=''>
                                    <FormattedMessage
                                        id="dashboard.title"
                                        defaultMessage="Dashboard"
                                    />
                                </strong> &nbsp;
                                {/* <RangeDataPicker /> */}
                            </h2>
                        </div>
                        {/* <div className='col-md-6'>
                            
                        </div> */}
                        <div className='col-md-9'>
                            <FilterDateRange />
                            <h5 className='mx-2' style={{ float: "right", paddingTop: "4.5px" }}>
                                {initDateState} - {endDateState}
                            </h5>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

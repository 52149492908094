import { FormattedMessage } from "react-intl";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ShuffleOutlinedIcon from '@mui/icons-material/ShuffleOutlined';

const myStyles = {
    fontSize: "19px"
}

export const QUESTION_ITEM_NAME = {
    "fixed":{
        id:1,
        name:<><LockOutlinedIcon style={myStyles}/> <FormattedMessage id = "item.type.fixed" defaultMessage={"Fixed"}/></>,
        description: <FormattedMessage id = "item.type.fixed.description" defaultMessage={"Fixed"}/>
    },
    "dynamic":{
        id:2,
        name:<>< ShuffleOutlinedIcon style={myStyles}/> <FormattedMessage id = "item.type.dynamic" defaultMessage={"Dynamic"}/></>,
        description:<FormattedMessage id = "item.type.dynamic.description" defaultMessage={"Dynamic"}/>
    }

}
import React from 'react'

export const PostSurveyImage = ({openImageLink, imglink}) => {
    return (
        <div className="text-center">
            <a href={openImageLink} target='_blank' rel='noreferrer'>
                <img className="img-container-postSurvey" src={imglink} alt='PostsurveyImage' />
            </a>
        </div>
    )
}
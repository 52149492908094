import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../../CustomHooks/useRefreshToken";
import useAuth from "../../CustomHooks/useAuth";
import useLogout from "../../CustomHooks/useLogout";

export const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { isAuth, persist, isLogin } = useAuth();
    const logout = useLogout();

    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                // console.error(err);
                // setIsLogin(false);
                logout();
            } finally {
                isMounted && setIsLoading(false);
            }
        }
        !isAuth?.accessToken && isLogin ? verifyRefreshToken() : setIsLoading(false);
        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //TODO Esto se repite si le doy mediente el boton de cerrar sesion
        if (!isLogin || typeof isLogin !== "boolean") {
            logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    // useEffect(() => {
    //     console.log("is cargando", isLoading);
    //     console.log("At", JSON.stringify(isAuth?.accessToken));
    // }, [isLoading]);

    return (
        <>
            {!persist
                ? <Outlet />
                : isLoading
                    ? <p>Loading...</p>//TODO Cambiar por un spinner o crear un componente Loading
                    : <Outlet />}
        </>
    );
}
import { FormattedMessage } from "react-intl";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const myStyles = {
    fontSize: "19px"
};

export const QUESTIONS_TYPE_LIST = {
    "Text Area": {
        id: 1,
        name: <><EditNoteIcon /> <FormattedMessage id="question.type.text.area" defaultMessage={"Text Area"} /></>,
        description: <FormattedMessage id="question.type.text.area.description" defaultMessage={"Text Area"} />
    },
    "Check": {
        id: 2,
        name: <><CheckBoxOutlinedIcon style={myStyles} /> <FormattedMessage id="question.type.check" defaultMessage={"Check box"} /></>,
        description: <FormattedMessage id="question.type.check.description" defaultMessage={"Check box"} />
    },
    "Text Box": {
        id: 3,
        name: <><EditOutlinedIcon style={myStyles} /> <FormattedMessage id="question.type.text.box" defaultMessage={"Text Box"} /></>,
        description: <FormattedMessage id="question.type.text.box.description" defaultMessage={"Text Box"} />
    },
    "Radio": {
        id: 4,
        name: <><CheckCircleOutlinedIcon style={myStyles} /> <FormattedMessage id="question.type.radio" defaultMessage={"Radio Button"} /></>,
        description: <FormattedMessage id="question.type.radio.description" defaultMessage={"Radio Button"} />
    },
    "Select Check": {
        id: 5,
        name: <><ChecklistRtlIcon style={myStyles} /> <FormattedMessage id="question.type.select.check" defaultMessage={"Select Check"} /></>,
        description: <FormattedMessage id="question.type.select.check.description" defaultMessage={"Select Check"} />
    },
    "Select Radio": {
        id: 6,
        name: <><RuleOutlinedIcon style={myStyles} /> <FormattedMessage id="question.type.select.radio" defaultMessage={"Select"} /></>,
        description: <FormattedMessage id="question.type.select.radio.description" defaultMessage={"Select"} />
    },
}

 

// import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Footer } from "../../ReuseComponents/Footer";
import { NotFound } from "../../ErrorComponents/NotFound";

export const SurveyPreview = () => {
    document.title = "Survey Preview";
    const location = useLocation();
    //get query params
    const styles = {}
    const queryParams = new URLSearchParams(location.search);

    const validation = (
        !queryParams.has('name')
        || !queryParams.has('title')
        || !queryParams.has('url')
        || !queryParams.has('header')
        || !queryParams.has('body')
        || !queryParams.has('font')
        || !queryParams.has('btnBack')
        || !queryParams.has('btnFont')
    )

    if (validation) {
        return (
            <NotFound />
        )
    }
    let notEmpty = false

    queryParams.forEach((value, key) => {
        if (value === "") {
            notEmpty = true
        }
        styles[key] = value;
    });
    if (notEmpty) {
        return (
            <NotFound />
        )
    } else {
        document.body.style.backgroundColor = `#${styles.body}`;
    }

    const logo = styles.logo ?? localStorage.getItem(`img${styles.name}`)

    return (
        <table border="0" cellPadding="0" cellSpacing="0" width="100%">
            <tbody>
                <tr>
                    <td bgcolor={`#${styles.header}`} align="center">
                        <table className="tableWith">
                            <tbody>
                                <tr>
                                    <td className="td1" align="center" valign="top">
                                        <a target="_blank" rel="noreferrer" href={styles.url}>
                                            <img className="img1" src={logo.includes("http") ? logo + "?number=" + Math.random() : logo} alt="Logo" />
                                            {/* <img className="img1" alt="Logo" src="\images\Logo.png" /> */}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td className="td2" bgcolor={`#${styles.header}`}  >
                        <table className="tableWith" align="center"  >
                            <tbody>
                                <tr>
                                    <td className="tdTitle" bgcolor={`#f4f4f4`} align="center"  >
                                        <h1 className="Title mb-3" style={{ color: `#${styles.font}` }} >{styles.title}</h1>
                                        {/* {idShop=== "" ?  <h3>Cargando...</h3>: idShop !== "No encontrado" && <h3>Por favor ingresa tus datos en la parte inferior</h3>} */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td className="tdBody" bgcolor={`#${styles.body}`} align="center" >
                        <table className="tableWith mb-5 shadow2" >
                            <tbody>
                                <tr>
                                    <td className="tdBody2" bgcolor={`#f4f4f4`} align="left"  >
                                        <div className="row mt-4" >
                                            <div className="col-sm-12">
                                                <div className="card shadow">
                                                    <div className="card-body" >
                                                        <div className="form-group" >
                                                            <label className="form-label mb-3" htmlFor="exampleRadioButton">Example Radio</label>
                                                            <div className="" >
                                                                <div className="col text-center" >
                                                                    <div className="form-check-inline text-center align-top" >
                                                                        <div className="" key={`radioContainer`}>
                                                                            <div className="col text-center" key={`radioCenter`}>
                                                                                <div className="form-check-inline text-center align-top" key={`radio1`}>
                                                                                    <input className="btn-check" type="radio" name={`radio`} id={`option1`} value={"Option 1"} />
                                                                                    <label className="btn btn-outline-dark mt-1" htmlFor={`option1`} >  {"Option 1"}</label>
                                                                                </div>
                                                                                <div className="form-check-inline text-center align-top" key={`radio2`}>
                                                                                    <input className="btn-check" type="radio" name={`radio`} id={`option2`} value={"Option 2"} />
                                                                                    <label className="btn btn-outline-dark mt-1" htmlFor={`option2`} >  {"Option 2"}</label>
                                                                                </div>
                                                                                <div className="form-check-inline text-center align-top" key={`radio3`}>
                                                                                    <input className="btn-check" type="radio" name={`radio`} id={`option3`} value={"Option 3"} />
                                                                                    <label className="btn btn-outline-dark mt-1" htmlFor={`option3`} >  {"Option 3"}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-sm-12" >
                                                <div className="card shadow" >
                                                    <div className="card-body" >
                                                        <div className="form-group" >
                                                            <label className="form-label mb-3">Example check box</label>
                                                            <br />
                                                            <div className="form-check form-check-inline" >
                                                                <input className="form-check-input" type="checkbox" id="exampleCheckButton1" />
                                                                <label className="form-check-label" htmlFor="exampleCheckButton1" >example 1</label>
                                                            </div>
                                                            <div className="form-check form-check-inline" >
                                                                <input className="form-check-input" type="checkbox" id="exampleCheckButton2" />
                                                                <label className="form-check-label" htmlFor="exampleCheckButton2" >example 2</label>
                                                            </div>
                                                            <div className="form-check form-check-inline" >
                                                                <input className="form-check-input" type="checkbox" id="exampleCheckButton3" />
                                                                <label className="form-check-label" htmlFor="exampleCheckButton3" >example 3</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4" >
                                            <div className="col-sm-12">
                                                <div className="card shadow">
                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <label className="form-label mb-3" htmlFor={`text`}>Example text</label>
                                                            <br />
                                                            <input className="form-control" data-hj-allow type="text" maxLength="100" name={`text`} id={`text`} rows="3"></input>
                                                            <p className="mt-2" style={{ color: "#FF0000" }}>Error Example</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-sm-12 ">
                                                <div className="card shadow">
                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <label className="form-label mb-3" htmlFor={`textArea`}>Example text Area</label>
                                                            <br />
                                                            <textarea className="form-control" data-hj-allow maxLength="2000" name={`textArea`} id={`textArea`} rows="3"></textarea>
                                                            <p className="mt-2" style={{ color: "#FF0000" }}>Error Example</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col text-center mt-3">
                                                <button type="submit" style={{ backgroundColor: `#${styles.btnBack}`, color: `#${styles.btnFont}` }} className="btn btn-warning btn-lg shadow">Enviar</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <Footer bodyBackground={`#${styles.body}`} fontColor={`#${styles.font}`} />
                </tr>
            </tbody>
        </table >
    )
}

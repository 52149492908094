import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useDashboardContext } from '../../CustomHooks/useDashboardContext'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { FormattedMessage, useIntl } from 'react-intl'

export const DatesModal = ({ open, handleClose }) => {
    const intl = useIntl();
    const { initDate, endDate, setInitDate, setEndDate } = useDashboardContext();
    const [startDate, setStartDate] = React.useState(dayjs(initDate));
    const [finalDate, setFinalDate] = React.useState(dayjs(endDate));

    const handleClick = () => {
        //convert to ISO format
        const msg = validateDates();
        if (msg !== '') {
            alert(msg);
            return;
        }
        const startDateStr = startDate.toISOString().split('T')[0];
        const finalDateStr = finalDate.toISOString().split('T')[0];
        setInitDate(startDateStr);
        setEndDate(finalDateStr);
        //remove local storage
        localStorage.removeItem('rangeDate');
        handleClose();
    }

    const validateDates = () => {
        const diff = finalDate.diff(startDate, 'day');
        if (startDate > finalDate) {
            return intl.formatMessage({ id: "dashboard.date.range.init.error" });
        }
        else if (finalDate < startDate) {
            return intl.formatMessage({ id: "dashboard.date.range.end.error" });
        }
        else if (diff > 30) {
            return intl.formatMessage({ id: "dashboard.date.range.days.error" });
        }
        return ('');
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <FormattedMessage id="dashboard.date.range" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="dashboard.date.range.msg" />
                </DialogContentText>
                <div className='row'>
                    <div className='col-md-6  mt-3'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                format='LL'
                                label={ <FormattedMessage id="init.date" />}
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='col-md-6  mt-3'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                format='LL'
                                label={ <FormattedMessage id="end.date" />}
                                value={finalDate}
                                onChange={(newValue) => setFinalDate(newValue)}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} style={{ color: "red" }}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button onClick={handleClick}>
                    <FormattedMessage id="send.button" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

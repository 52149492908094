import { Autocomplete, TextField } from '@mui/material';
import { QuestionsMarksInfo } from './QuestionsMarksInfo';

export const SelectRadioQuestion = ({ index, question, itemLabel, optionLabel }) => {
    return (
        <>
            <label className="form-label mb-3" htmlFor={`selectradio${(index + 1)}${(question.id)}`}>
                {`${(index + 1)}. ${question[itemLabel]}`}
                <QuestionsMarksInfo required={question.required} type={question.question_type} />
            </label>
            <br />
            <Autocomplete
                disablePortal
                id={`selectradio${(index + 1)}${(question.id)}`}
                options={question[optionLabel]}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Seleccione una opcion" />}
            />
        </>
    )
}
import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setIsAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('refresh', {
            withCredentials: true
        });
        // console.log(response.data);
        setIsAuth(prev => {
            // console.log(JSON.stringify(prev));
            // console.log(JSON.stringify(response.data.accessToken));
            return {
                ...prev,
                accessToken: response.data.accessToken
            }
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl";
import useAxiosPrivate from "../../CustomHooks/useAxiosPrivate";
import useAuth from '../../CustomHooks/useAuth';

export const ChangePassword = () => {
    const intl = useIntl();
    const axiosPrivate = useAxiosPrivate();

    const initialValues = {
        currentPass: '',
        newPass: '',
        confirmPass: ''
    }
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [values, setValues] = useState(initialValues);
    const { UserInfo } = useAuth();

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setSuccessMsg('');
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleCancel = () => {
        setValues(initialValues)
    }

    useEffect(() => {
        const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
        if (values.newPass && !PWD_REGEX.test(values.newPass)) {
            setErrorMsg(intl.formatMessage({ id: "create.user.error.valid" }));
        }
        else if (values.newPass !== values.confirmPass) {
            setErrorMsg(intl.formatMessage({ id: "create.user.error.match" }));
        } else {
            setErrorMsg('');
        }
    }, [values.newPass, values.confirmPass, intl])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const controller = new AbortController();
            const response = await axiosPrivate.post('changepassword',
                {
                    pwd: values.currentPass,
                    newPwd: values.newPass,
                    email: UserInfo?.email
                }, {
                signal: controller.signal
            });
            if (response.status === 200) {
                setSuccessMsg(intl.formatMessage({ id: "settings.change.pass.success" }));
                setValues(initialValues);
            }
        } catch (err) {
            if (err.response.status === 401) {
                if (err.response.data?.text === 'La contraseña actual no es la correcta') {
                    setErrorMsg(intl.formatMessage({ id: "settings.change.pass.error" }));
                }
            } else {
                setErrorMsg(intl.formatMessage({ id: "settings.server.error.msg" }));
                console.error(err);
            }
        }
    }

    return (
        <div className="card-body pb-2">
            <form className="form-floating" onSubmit={handleSubmit}>
                {errorMsg ?
                    <div className="alert alert-danger" role="alert">
                        {errorMsg}
                    </div>
                    :
                    successMsg && <div className="alert alert-success" role="alert">
                        {successMsg}
                    </div>
                }
                <div className="row my-2">
                    <div className="col-sm-6 my-1">
                        <div className="form-floating mb-3">
                            <input type="password" required className="form-control" id="currentPass" name="currentPass" placeholder="Password" onChange={handleInputChange} value={values.currentPass} />
                            <label htmlFor="floatingPassword">
                                <FormattedMessage
                                    id="settings.current.pass"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-6 my-1">
                        <div className="form-floating mb-3">
                            <input type="password" required className="form-control" id="newPass" name="newPass" placeholder="Password" onChange={handleInputChange} value={values.newPass} />
                            <label htmlFor="floatingPassword">
                                <FormattedMessage
                                    id="settings.new.pass"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-sm-6 my-1">
                        <div className="form-floating">
                            <input type="password" required className="form-control" id="confirmPass" name="confirmPass" placeholder="Password" onChange={handleInputChange} value={values.confirmPass} />
                            <label htmlFor="floatingPassword">
                                <FormattedMessage
                                    id="settings.confirm.pass"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-6 my-1">
                        <div className="text-right mt-3">
                            <button type="submit" className="btn btn-primary">
                                <FormattedMessage
                                    id="settings.change.pass.btn"
                                />
                            </button>&nbsp;&nbsp;&nbsp;
                            <button type="button" className="btn btn-default" onClick={handleCancel}>
                                <FormattedMessage
                                    id="settings.cancel.pass.btn"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
